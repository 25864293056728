import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllSpecialistList({
  offset,
  limit,
  search,
  isActive
}) {
  try {
    let getAllSpecialistResponse = await axios.Get({
      url: `${adminUrl}/specialities?offset=${offset || ""}&limit=${limit ||
        ""}&search=${search || ""}&isActive=${isActive || ""}`
    });

    if (getAllSpecialistResponse.statusCode !== 200) {
      return { error: getAllSpecialistResponse.message };
    }

    return { data: getAllSpecialistResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetByIDSpecialist({ id }) {
  try {
    let getByIDSpecialistResponse = await axios.Get({
      url: `${adminUrl}/specialities/${id}`
    });

    if (getByIDSpecialistResponse.statusCode !== 200) {
      return { error: getByIDSpecialistResponse.message };
    }

    return { data: getByIDSpecialistResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdateSpecialist({ id, isActive }) {
  try {
    let putUpdateSpecialistResponse = await axios.Put({
      url: `${adminUrl}/specialities/${id}/is-active`,
      data: { isActive }
    });

    if (putUpdateSpecialistResponse.statusCode !== 200) {
      return { error: putUpdateSpecialistResponse.message };
    }

    return { data: putUpdateSpecialistResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
