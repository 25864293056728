export default {
    items: [
      {
        name: "Dashboard",
        url: "/dashboard"
      },
      {
        name: "Master Data",
        children: [
          {
            name: "Doctor",
            url: "/siloam-doctors"
          }
        ]
      }
    ]
  };
  