// PROD
// const adminUrl = "https://mysiloam-api.siloamhospitals.com/admin/v1";
// const adminSiloamUrl = "https://mysiloam-api.siloamhospitals.com/api/v2";
// const gtnUploadImageUrl = "https://mysiloam-api.siloamhospitals.com/storage-up/files/uploader";

const environment = {
  development: {
    adminUrl: "https://mysiloam-api-staging.siloamhospitals.com/admin/v1",
    adminSiloamUrl: "https://mysiloam-api-staging.siloamhospitals.com/api/v2",
    gtnUploadImageUrl:
      "https://mysiloam-api-staging.siloamhospitals.com/storage-up/files/uploader",
  },
  staging: {
    adminUrl: "https://mysiloam-api-staging.siloamhospitals.com/admin/v1",
    adminSiloamUrl: "https://mysiloam-api-staging.siloamhospitals.com/api/v2",
    gtnUploadImageUrl:
      "https://mysiloam-api-staging.siloamhospitals.com/storage-up/files/uploader",
  },
  production: {
    adminUrl: "https://mysiloam-api.siloamhospitals.com/admin/v1",
    adminSiloamUrl: "https://mysiloam-api.siloamhospitals.com/api/v2",
    gtnUploadImageUrl:
      "https://mysiloam-api.siloamhospitals.com/storage-up/files/uploader",
  },
};

const { REACT_APP_ENV = "development" } = process.env;

module.exports = environment[REACT_APP_ENV];
