import { GetBloodGlucoseLimit, UpdateBloodGlucoseLimit } from "../services/InputLimitService";
import { observable, decorate } from "mobx";
import commonStore from "./CommonStore";
import swal from "sweetalert2";
import { validateEditedData } from "../helper/functions";

const Joi = require("joi-browser");
Joi.objectId = require("joi-objectid")(Joi);

class InputLimitStore {
  editedData = {};
  isLoading = false;

  setEditedData = (updatedData) => {
    this.editedData = {
      ...this.editedData,
      ...updatedData,
    };
  };

  getBloodGlucoseLimit = async () => {
    this.isLoading = true;

    let { data, error } = await GetBloodGlucoseLimit();

    this.isLoading = false;

    if (data) {
      for (let i = 0; i < data.bloodGlucoseConfig.length; i++) {
        if (data.bloodGlucoseConfig[i].bloodType === "bloodGlucose") {
          this.setEditedData({
            bloodGlucoseId: data.bloodGlucoseConfig[i].id,
            bloodGlucoseMin: data.bloodGlucoseConfig[i].minValue,
            bloodGlucoseMax: data.bloodGlucoseConfig[i].maxValue,
          })
        } else if (data.bloodGlucoseConfig[i].bloodType === "bloodGlucoseHba1c") {
          this.setEditedData({
            bloodGlucoseHba1cId: data.bloodGlucoseConfig[i].id,
            bloodGlucoseHba1cMin: data.bloodGlucoseConfig[i].minValue,
            bloodGlucoseHba1cMax: data.bloodGlucoseConfig[i].maxValue,
          })
        } else if (data.bloodGlucoseConfig[i].bloodType === "bloodPressureSystole") {
          this.setEditedData({
            bloodPressureSystoleId: data.bloodGlucoseConfig[i].id,
            bloodPressureSystoleMin: data.bloodGlucoseConfig[i].minValue,
            bloodPressureSystoleMax: data.bloodGlucoseConfig[i].maxValue,
          })
        } else if (data.bloodGlucoseConfig[i].bloodType === "bloodPressureDiastole") {
          this.setEditedData({
            bloodPressureDiastoleId: data.bloodGlucoseConfig[i].id,
            bloodPressureDiastoleMin: data.bloodGlucoseConfig[i].minValue,
            bloodPressureDiastoleMax: data.bloodGlucoseConfig[i].maxValue,
          })
        }
      }
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  }

  updateBloodGlucoseLimit = async () => {
    this.isLoading = true;

    const tempData = {
      updateArray: [
        {
          id: this.editedData.bloodGlucoseId,
          bloodType: "bloodGlucose",
          minValue: this.editedData.bloodGlucoseMin,
          maxValue: this.editedData.bloodGlucoseMax
        },
        {
          id: this.editedData.bloodGlucoseHba1cId,
          bloodType: "bloodGlucoseHba1c",
          minValue: this.editedData.bloodGlucoseHba1cMin,
          maxValue: this.editedData.bloodGlucoseHba1cMax
        },
        {
          id: this.editedData.bloodPressureSystoleId,
          bloodType: "bloodPressureSystole",
          minValue: this.editedData.bloodPressureSystoleMin,
          maxValue: this.editedData.bloodPressureSystoleMax
        },
        {
          id: this.editedData.bloodPressureDiastoleId,
          bloodType: "bloodPressureDiastole",
          minValue: this.editedData.bloodPressureDiastoleMin,
          maxValue: this.editedData.bloodPressureDiastoleMax
        }
      ]
    }

    let { data, error } = await UpdateBloodGlucoseLimit(tempData);

    this.isLoading = false;

    if (data) {
      swal({
        title: "Success",
        text: "Limit Successfully Updated",
        icon: "success",
        button: "OK",
      }).then((value) => {
      });
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  }
  get joiFormErrors() {
    if (this.editedData) {
      return validateEditedData({
        editedData: this.editedData,
        joiSchema: {
          bloodGlucoseMin: Joi.number()
            .empty('')
            .label('Lower Limit')
            .required(),
          bloodGlucoseMax: Joi.number()
            .empty('')
            .label('Upper Limit')
            .required(),
          bloodGlucoseHba1cMin: Joi.number()
            .empty('')
            .label('Minimum')
            .required(),
          bloodGlucoseHba1cMax: Joi.number()
            .empty('')
            .label('Maximum')
            .required(),
          bloodPressureSystoleMin: Joi.number()
            .empty('')
            .label('Systole Minimum')
            .required(),
          bloodPressureSystoleMax: Joi.number()
            .empty('')
            .label('Systole Maximum')
            .required(),
          bloodPressureDiastoleMin: Joi.number()
            .empty('')
            .label('Diastole Minimum')
            .required(),
          bloodPressureDiastoleMax: Joi.number()
            .empty('')
            .label('Diastole Maximum')
            .required(),
        }
      });
    }
    return { error: true };
  }
}
decorate(InputLimitStore, {
  editedData: observable,
  isLoading: observable,
});

export default new InputLimitStore();