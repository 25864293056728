import {
  getCode,
  getCodeById,
  createCode,
  updateCode,
} from "../services/UniqueCodeService";

import { GetAllHospitalList } from "../services/HospitalListService";

import { observable, action, decorate } from "mobx";
import React from "react";
import commonStore from "./CommonStore";

class UniqueCodeStore {
  dataArray = [];
  isLoading = false;
  pageSize = 10;
  currentPage = 0;
  keyword = "";
  editedData = {};
  valueTransformer = {
    isActive: (data) => {
      if (data.status === "true") {
        return <span className="active-span">ACTIVE</span>;
      } else {
        return <span className="nonactive-span">NONACTIVE</span>;
      }
    },
  };
  columns = [
    {
      name: "codeID",
      title: "Unique Code",
    },
    {
      name: 'hospitalName',
      title: "Hospital"
    },
    {
      name: "name",
      title: "Code Name",
    },
    {
      name: "quota",
      title: "Quota",
    },
    {
      name: "effectiveDate",
      title: "Effective Date",
    },
    {
      name: "expiredDate",
      title: "Expired Date",
    },
    {
      name: "status",
      title: "Status",
      valueTransformer: this.valueTransformer["isActive"],
    },
  ];

  columnWidths = [
    {
      columnName: "codeID",
      width: 170,
    },
    {
      columnName: 'hospitalName',
      width: 250,
    },
    {
      columnName: "name",
      width: 250,
    },
    {
      columnName: "quota",
      width: 130,
    },
    {
      columnName: "effectiveDate",
      width: 150,
    },
    {
      columnName: "expiredDate",
      width: 150,
    },
    {
      columnName: "status",
      width: 150,
    },
  ];

  disabledColumn = [
    {
      columnName: 'hospitalName',
      sortingEnabled: false,
    },
    {
      columnName: "name",
      sortingEnabled: false,
    },
    {
      columnName: "quota",
      sortingEnabled: false,
    },
    {
      columnName: "effectiveDate",
      sortingEnabled: false,
    },
    {
      columnName: "expiredDate",
      sortingEnabled: false,
    },
    {
      columnName: "status",
      sortingEnabled: false,
    },
  ];

  sorting = [{ columnName: "codeID", direction: "asc" }];

  statusArray = [
    { value: "true", name: "Active" },
    { value: "false", name: "Inactive" },
  ];

  listHospital = async () => {
    let list = await GetAllHospitalList({});
    let result = [];
    list.data.hospitals.forEach((row) => {
      result.push({
        title: row.name,
        value: row.hospitalID
      });
    });
    return result;
  };

  setEditedData = (updatedData) => {
    this.editedData = {
      ...this.editedData,
      ...updatedData,
    };
  };

  clearData = () => {
    this.editedData = {};
  };

  setSorting = (sorting) => {
    this.sorting = sorting;
  };

  setPageLimit = (value) => {
    this.pageSize = value;
  };

  setCurrentPage = (currentPage) => {
    this.currentPage = currentPage;
    this.getCode();
  };

  setSearchQuery = (searchValue) => {
    this.currentPage = 0;
    this.keyword = searchValue;
  };

  setStartDate = (value) => {
    this.editedData.effectiveDate = value;
  };

  setEndDate = (value) => {
    this.editedData.expiredDate = value;
  };

  getCode = async () => {
    this.isLoading = true;
    let { data, error } = await getCode({
      hospitalID: this.editedData.hospitalID || "",
      isActive: this.editedData.status || "",
      keyword: this.keyword || "",
      offset: Number(this.currentPage) || "",
      limit: this.pageSize,
    });
    this.isLoading = false;
    if (data) {
      this.dataArray =
        data.diabisaInvitationCode.map((item) => {
          return {
            id: item.id || "",
            codeID: item.code || "",
            hospitalID: item.hospitalID || "",
            hospitalName: item.hospital ? item.hospital.name : "",
            name: item.name || "",
            quota: item.quota || "",
            effectiveDate: item.startDate || "",
            expiredDate: item.endDate || "",
            status: item.isActive === true ? "true" : "false",
          };
        }) || [];
      this.editedData.totalCount = data.count;
    } else {
      if (error) {
        commonStore.showErrorMessage("An error has occurred");
      } else {
        commonStore.showErrorMessage(error);
      }
    }
  };

  getCodeById = async ({ id }) => {
    this.isLoading = true;
    let { data, error } = await getCodeById({
      data: { id },
    });
    this.isLoading = false;
    if (data) {
      this.editedData.id = data.id || "";
      this.editedData.codeID = data.code || "";
      this.editedData.hospitalID = data.hospitalID || "";
      this.editedData.name = data.name || "";
      this.editedData.quota = data.quota || 0;
      this.editedData.effectiveDate = data.startDate || "";
      this.editedData.expiredDate = data.endDate || "";
      this.editedData.status = data.isActive === true ? "true" : "false";
    } else {
      if (error) {
        commonStore.showErrorMessage("An error has occurred");
      } else {
        commonStore.showErrorMessage(error);
      }
    }
  };

  createCode = async () => {
    this.isLoading = true;
    let { data, error } = await createCode({
      data: {
        hospitalID: this.editedData.hospitalID || "",
        name: this.editedData.name || "",
        quota: Number(this.editedData.quota) || "",
        startDate: this.editedData.effectiveDate || "",
        endDate: this.editedData.expiredDate || "",
      },
    });
    this.isLoading = false;
    if (data) {
      commonStore.showSuccessMessage("Unique Code created successfully!");
      setTimeout(() => {
        window.location.href = "/unique-code";
      }, 500);
    } else {
      if (error) {
        commonStore.showErrorMessage("An error has occurred");
      } else {
        commonStore.showErrorMessage(error);
      }
    }
  };

  updateCode = async () => {
    this.isLoading = true;
    let { data, error } = await updateCode({
      data: {
        id: this.editedData.id || "",
        hospitalID: this.editedData.hospitalID || "",
        name: this.editedData.name || "",
        quota: this.editedData.quota || "",
        isActive: this.editedData.status || "false",
        startDate: this.editedData.effectiveDate || "",
        endDate: this.editedData.expiredDate || "",
      },
    });
    this.isLoading = false;
    if (data) {
      commonStore.showSuccessMessage("Unique Code updated successfully!");
      setTimeout(() => {
        window.location.href = "/unique-code";
      }, 500);
    } else {
      if (error) {
        commonStore.showErrorMessage("An error has occurred");
      } else {
        commonStore.showErrorMessage(error);
      }
    }
  };
}

decorate(UniqueCodeStore, {
  isLoading: observable,
  editedData: observable,
  keyword: observable,
  dataArray: observable,
  pageSize: observable,
  currentPage: observable,
  sorting: observable,

  setEditedData: action,
  setSorting: action,
});

export default new UniqueCodeStore();
