import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetGameList(search, offset, limit) {
  try {
    let getGameList = await axios.Get({
      url: `${adminUrl}/game-list?search=${search || ''}&offset=${offset || ''}&limit=${limit || ''}&isDeleted=false`
    });

    if (getGameList.statusCode !== 200) {
      return { error: getGameList.message };
    }

    return { data: getGameList.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetGameListById(gameListId) {
  try {
    let getGameListById = await axios.Get({
      url: `${adminUrl}/game-list/${gameListId}`
    });

    if (getGameListById.statusCode !== 200) {
      return { error: getGameListById.message };
    }

    return { data: getGameListById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function CreateGameList(editedData) {
  try {
    let createGameList = await axios.Post({
      url: `${adminUrl}/game-list`,
      data: editedData,
    });

    if (createGameList.statusCode !== 200) {
      return { error: createGameList.message };
    }

    return { data: createGameList.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function UpdateGameList(gameListId, editedData) {
  try {
    let gameList = await axios.Put({
      url: `${adminUrl}/game-list/${gameListId}`,
      data: editedData,
    });

    if (gameList.statusCode !== 200) {
      return { error: gameList.message };
    }

    return { data: gameList.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function UpdateGameListIsFree(gameListId, isFree) {
  try {
    let gameList = await axios.Put({
      url: `${adminUrl}/game-list/${gameListId}/is-free`,
      data: {
        isFree,
      },
    });

    if (gameList.statusCode !== 200) {
      return { error: gameList.message };
    }

    return { data: gameList.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function DeleteGameList(gameListId) {
  try {
    let getGameListById = await axios.Put({
      url: `${adminUrl}/game-list/${gameListId}/delete`,
    });

    if (getGameListById.statusCode !== 200) {
      return { error: getGameListById.message };
    }

    return { data: getGameListById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetGameQuestion(gameListId, searchQuery, type, offset, limit) {
  try {
    let getGameQuestion = await axios.Get({
      url: `${adminUrl}/games?gameListId=${gameListId || ''}&search=${searchQuery || ''}&type=${type || ''}&offset=${offset}&limit=${limit}&isDeleted=false`
    });

    if (getGameQuestion.statusCode !== 200) {
      return { error: getGameQuestion.message };
    }

    return { data: getGameQuestion.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetGameQuestionById(gameQuestionId) {
  try {
    let getGameQuestionById = await axios.Get({
      url: `${adminUrl}/games/${gameQuestionId}`
    });

    if (getGameQuestionById.statusCode !== 200) {
      return { error: getGameQuestionById.message };
    }

    return { data: getGameQuestionById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function CreateGameQuestion(editedData) {
  try {
    let createGameQuestion = await axios.Post({
      url: `${adminUrl}/games`,
      data: editedData,
    });

    if (createGameQuestion.statusCode !== 200) {
      return { error: createGameQuestion.message };
    }

    return { data: createGameQuestion.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function UpdateGameQuestion(gameListId, editedData) {
  try {
    let createGameQuestion = await axios.Put({
      url: `${adminUrl}/games/${gameListId}`,
      data: editedData,
    });

    if (createGameQuestion.statusCode !== 200) {
      return { error: createGameQuestion.message };
    }

    return { data: createGameQuestion.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function DeleteGameQuestion(gameQuestionId) {
  try {
    let deleteGameQuestion = await axios.Put({
      url: `${adminUrl}/games/${gameQuestionId}/delete`,
    });

    if (deleteGameQuestion.statusCode !== 200) {
      return { error: deleteGameQuestion.message };
    }

    return { data: deleteGameQuestion.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
