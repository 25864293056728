import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllWeightByID(
  id,
  search,
  startDate,
  endDate,
  offset,
  limit,
  order
) {
  try {
    let getAllWeightByID = await axios.Get({
      url: `${adminUrl}/weight-records?userId=${id}&search=${search ||
        ""}&startDate=${startDate}&endDate=${endDate}&offset=${offset}&limit=${limit}&order=${order.toUpperCase()}`
    });
    if (getAllWeightByID.statusCode !== 200) {
      return { error: getAllWeightByID.message };
    }

    return { data: getAllWeightByID.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetWeightByID(id) {
  try {
    let getWeightByID = await axios.Get({
      url: `${adminUrl}/weight-records/` + id
    });
    if (getWeightByID.statusCode !== 200) {
      return { error: getWeightByID.message };
    }

    return { data: getWeightByID.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdateWeight(
  weight,
  notes,
  imageUrl,
  date,
  userId,
  id
) {
  try {
    let putUpdateWeight = await axios.Put({
      url: `${adminUrl}/weight-records/${id}`,
      data: { weight, notes: notes || '', date, imageUrl, userId: userId.toString() }
    });

    if (putUpdateWeight.statusCode !== 200) {
      return { error: putUpdateWeight.message };
    }

    return { data: putUpdateWeight.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
