import {
  GetAllEducation,
  GetEducationById,
  PostEducation,
  PutUpdateEducation,
  PutDeleteEducation,
  NotifyUser,
} from "../services/EducationService";
import { GetAllTag } from "../services/InterestService";
import { observable, decorate } from "mobx";
import swal from "sweetalert2";
import moment from "moment";
import commonStore from "./CommonStore";

class EducationStore {
  editedData = {
    imageUrl: null,
    educationTagArray: [],
  };
  dataArray = [];
  tagArray = [];
  loading = false;
  lang = "ID";
  searchQuery = "";
  currentPage = 0;
  pageSize = 10;
  totalCount = 0;
  educationType = "all";
  sorting = [{ columnName: "educationID", direction: "asc" }];
  columns = [
    { name: "educationID", title: "Education ID" },
    { name: "eduType", title: "Type" },
    { name: "title", title: "Title" },
    { name: "author", title: "Author" },
    { name: "order", title: "Order" },
  ];
  columnWidths = [
    { columnName: "educationID", width: 100 },
    { columnName: "eduType", width: 200 },
    { columnName: "title", width: 400 },
    { columnName: "author", width: 200 },
    { columnName: "order", width: 200 },
  ];
  disabledColumn = [
    { columnName: "eduType", sortingEnabled: false },
    { columnName: "title", sortingEnabled: false },
    { columnName: "author", sortingEnabled: false },
    { columnName: "order", sortingEnabled: false },
  ];
  educationListEnum = [
    { name: "All", value: "all" },
    { name: "Webinar", value: "webinar" },
    { name: "Event", value: "event" },
    { name: "Article", value: "article" },
    { name: "Polling", value: "polling" },
    { name: "Announcement", value: "announcement" },
  ];
  educationEnum = [
    { name: "Webinar", value: "webinar" },
    { name: "Event", value: "event" },
    { name: "Article", value: "article" },
    { name: "Polling", value: "polling" },
    { name: "Announcement", value: "announcement" },
  ];
  featuredEnum = [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ];

  setEditedData = (updatedData) => {
    this.editedData = {
      ...this.editedData,
      ...updatedData,
    };
  };

  setLoading = (value) => {
    this.loading = value;
  };

  setCurrentPage = (page) => {
    this.currentPage = page;
    this.getAllEducation();
  };

  setSorting = (sorting) => {
    this.sorting = sorting;
    this.getAllEducation();
  };

  setSearchQuery = (search) => {
    this.currentPage = 0;
    this.searchQuery = search;
  };

  setEducationType = (type) => {
    this.educationType = type;
  };

  clearEditedData = () => {
    this.editedData = {
      imageUrl: null,
      educationTagArray: [],
    };
  };

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    let temp = [year, month, day].join("-");
    return temp;
  }

  getAllEducation = async () => {
    this.loading = true;

    const { data, error } = await GetAllEducation(
      this.currentPage,
      this.pageSize,
      this.searchQuery,
      this.sorting[0].direction,
      this.educationType !== "all" ? this.educationType : ""
    );

    if (data) {
      this.loading = false;
      this.totalCount = data.count;
      this.dataArray = data.education.map((row) => {
        return {
          ...row,
          educationID: Number(row.educationID),
          order: row.order ? row.order.toString() : "",
          createdAt: this.formatDate(row.createdAt),
        };
      });
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getEducationByID = async (id) => {
    this.loading = true;

    let { data, error } = await GetEducationById(id, this.lang);

    if (data) {
      this.loading = false;
      this.editedData = data.education;
      this.editedData.educationTagArray = data.educationTag.map((e) => {
        return {
          label: e.tag.name,
          value: e.tag.id,
        };
      });
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  postEducation = async (history) => {
    this.loading = true;

    let { data, error } = await PostEducation(this.editedData);

    if (data) {
      this.loading = false;
      swal({
        title: "Success",
        text: "Education Success Added",
        icon: "Success",
        button: "OK",
      }).then((value) => {
        history.push("/educations");
      });
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  putUpdateEducation = async (history, educationId) => {
    this.loading = true;

    let { data, error } = await PutUpdateEducation(
      educationId,
      this.editedData
    );

    if (data) {
      this.loading = false;

      swal({
        title: "Success",
        text: "Education Success Updated",
        icon: "success",
        button: "OK",
      }).then((value) => {
        history.push("/educations");
      });
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  putDeleteEducation = async (history, educationId) => {
    this.loading = true;

    let { data, error } = await PutDeleteEducation(educationId);

    if (data) {
      this.loading = false;
      swal({
        title: "Success",
        text: "Education Success Deleted",
        icon: "success",
        button: "OK",
      }).then((value) => {
        history.push("/educations");
      });
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getAllTag = async () => {
    this.loading = true;

    let { data, error } = await GetAllTag(this.lang, null, null, "");

    if (data) {
      this.loading = false;

      this.tagArray = data.tag.map((row) => {
        return {
          label: row.name,
          value: row.id,
        };
      });
    } else if (error) {
      this.loading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  notifyUser = async (history) => {
    this.loading = true;

    let { data, error } = await NotifyUser(
      `Ketahui lebih lanjut: ${this.editedData.title}!!`,
      moment().format("YYYY-MM-DD HH:mm:ss"),
      this.editedData.educationID
    );

    if (data) {
      this.loading = false;
      commonStore.showSuccessMessage("User Notified!");
      history.push("/educations");
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };
}

decorate(EducationStore, {
  editedData: observable,
  dataArray: observable,
  tagArray: observable,
  loading: observable,
  currentPage: observable,
  pageSize: observable,
  totalCount: observable,
  searchQuery: observable,
  sorting: observable,
  educationType: observable,
});

export default new EducationStore();
