import React, { Component } from "react";
import { Provider } from "mobx-react";
import { Route, Router, Switch, withRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { createGenerateClassName } from "@material-ui/core/styles";
import "./App.scss";
import JssProvider from "react-jss/lib/JssProvider";
import 'react-block-ui/style.css';

// CoreUI Icons Set
import "@coreui/icons/css/coreui-icons.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "./scss/style.css";

import { DefaultLayout } from "./containers";
import { Login, Page404, Page500 } from "./views/Pages";
import AppSnackbar from "./components/AppSnackbar";
import stores from "./stores";

import DateDaysjsUtils from '@date-io/dayjs'; // choose your lib
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

class App extends Component {
  render() {
    const Root = () => (
      <Switch>
        <Route exact path="/login" name="Login Page" component={Login} />
        <Route exact path="/404" name="Page 404" component={Page404} />
        <Route exact path="/500" name="Page 500" component={Page500} />
        <Route path="/" name="Home" component={DefaultLayout} />
      </Switch>
    );

    const generateClassName = createGenerateClassName({
      dangerouslyUseGlobalCSS: true
    });

    const browserHistory = createBrowserHistory();
    const RouterMCU = withRouter(Root);

    return (
      <JssProvider generateClassName={generateClassName}>
        <Provider {...stores}>
          <MuiPickersUtilsProvider utils={DateDaysjsUtils}>
            <React.Fragment>
              <AppSnackbar />
              <Router history={browserHistory}>
                <RouterMCU />
              </Router>
            </React.Fragment>
          </MuiPickersUtilsProvider>
        </Provider>
      </JssProvider>
    );
  }
}

export default App;
