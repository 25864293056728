import {
  GetAllPatient,
  GetByIDPatient,
  PostPatient,
  GetPatientByDoctorID,
  PutUpdatePatient,
  PutChangeUserDoctor
} from "../services/PatientService";
import { GetUserTags } from '../services/UserTagService';
import { observable, decorate, action } from "mobx";
import swal from "sweetalert2";
import commonStore from "./CommonStore";

class PatientStore {
  editedData = {
    userInfo: [
      "Blood Glucose",
      "Weight",
      "Diet",
      "Exercise",
      "Medication",
      "Symptoms",
      "Prescriptions",
      "Body Measurement",
      "Step",
      "Habit Tracker",
      "Mini Games"
    ],
    userInfoProfile: ["Profile"],
    activeTab: new Array(4).fill("1"),
    tabTitle: ["", "/healthtracker", "/userinfo"]
  };
  patientArrayList = [];
  currentPage = 0;
  pageSize = 10;
  totalCount = 0;
  searchValue = "";
  isDiabisaMember = "";
  phone = "";
  typeFilterEnum = [
    { value: true, name: "Member" },
    { value: false, name: "Non-Member" }
  ];
  isLoading = false;
  valueTransformer = {
    isDiabisaMember: data => {
      if (data.isDiabisaMember === true) {
        return "Member";
      } else if (data.isDiabisaMember === false) {
        return "Non-Member";
      }
    },
  };
  columns = [
    {
      name: "name",
      title: "Name",
    },
    {
      name: "phoneNumber",
      title: "Phone Number"
    },
    {
      name: "isDiabisaMember",
      title: "Member Type",
      valueTransformer: this.valueTransformer["isDiabisaMember"]
    }
  ];
  columnWidths = [
    {
      columnName: "name",
      width: 300
    },
    {
      columnName: "phoneNumber",
      width: 300
    },
    {
      columnName: "isDiabisaMember",
      width: 300
    }
  ];
  genderEnum = [
    { name: "Male", value: "Male" },
    { name: "Female", value: "Female" }
  ];
  diabeticTypeEnum = [
    { name: "Type 1", value: "Type 1" },
    { name: "Type 2", value: "Type 2" },
    { name: "Prediabetic", value: "Prediabetic" }
  ];
  userActivityEnum = [
    { name: "Sedentary", value: "Sedentary" },
    { name: "Lightly active", value: "Lightly active" },
    { name: "Moderately active", value: "Moderately active" },
    { name: "Very active", value: "Very active" },
    { name: "Super active", value: "Super active" }
  ];
  chipArray = [];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  clear = () => {
    this.pageSize = 10;
    this.currentPage = 0;
    this.isDiabisaMember = '';
    this.searchValue = '';
    this.phone = '';
    this.getAllPatient();
  }

  setPageLimit = value => {
    this.pageSize = value;
  };

  setCurrentPage = value => {
    this.currentPage = value;
  };

  setTypeFilterValue = value => {
    this.currentPage = 0;
    this.isDiabisaMember = value;
  };

  setSearchValue = value => {
    this.currentPage = 0;
    this.searchValue = value;
  };

  setPhone = value => {
    this.currentPage = 0;
    this.phone = value;
  };

  setLoading = loading => {
    this.isLoading = loading;
  };

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  get reactSelectPatientOptions() {
    if (!this.patientArrayList || this.patientArrayList.length === 0) {
      return [];
    }
    return this.patientArrayList.map(patients => {
      return {
        label: `${patients.name}`,
        value: patients
      };
    });
  }

  getAllPatient = async () => {
    this.isLoading = true;

    const { data, error } = await GetAllPatient({
      isDiabisaMember: this.isDiabisaMember,
      phone: this.phone,
      offset: this.currentPage,
      limit: this.pageSize,
      search: this.searchValue
    });

    if (data) {
      this.totalCount = data.count;
      this.patientArrayList = data.user.map(row => {
        return {
          ...row
        };
      });

      this.isLoading = false;

      return data.user;
    } else {
      this.isLoading = false;
      return { error };
    }
  };

  getPatientByDoctorID = async id => {
    this.authLoading = true;

    let { data, error } = await GetPatientByDoctorID({
      isPremium: this.typeFilterValue,
      offset: this.currentPage,
      limit: this.pageSize,
      search: this.searchValue,
      id: id
    });

    if (data) {
      this.totalCount = data.count;
      this.patientArrayList = data.user.map(row => {
        const doctorName = row["doctor.name"];

        return {
          ...row,
          doctor: doctorName
        };
      });
      this.authLoading = false;
      return { data };
    } else {
      this.authLoading = false;
      return { error };
    }
  };

  putUpdatePatient = async (
    name,
    phoneNumber,
    imageUrl,
    diabeticType,
    gender,
    dob,
    doctorId,
    hospital,
    userActivity,
    id
  ) => {
    this.authLoading = true;
    this.isLoading = true;
    let { data, error } = await PutUpdatePatient(
      name,
      phoneNumber,
      imageUrl === null ? "" : imageUrl,
      diabeticType,
      gender,
      dob,
      doctorId,
      hospital,
      userActivity,
      id
    );
    this.authLoading = false;
    if (data) {
      this.isLoading = false;
      swal({
        title: "Success",
        text: "Patient Success Updated",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = "/patients";
        return { data };
      });
    } else {
      this.isLoading = false;
      return { error };
    }
  };

  putChangeUserDoctor = async (userId, doctorId, startDate) => {
    this.isLoading = true;

    let { data, error } = await PutChangeUserDoctor(
      userId,
      doctorId,
      startDate
    );

    if (data) {
      this.isLoading = false;
      swal({
        title: "Success",
        text: "Doctor Success Updated",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = `/patients/${userId}/userinfo`;
        return { data };
      });
    } else {
      this.isLoading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getByIDPatient = async id => {
    this.authLoading = true;

    let { data, error } = await GetByIDPatient(id);

    if (data) {
      if (data.doctor !== null) {
        this.setEditedData({
          doctorID: data.doctor.doctorID
        });
      }
      this.setEditedData({
        tempImgUrl: data.imageUrl,
        gender: data.gender,
        diabeticType: data.diabeticType,
        hospitalId: data.hospitalHospitalID,
        userCode: data.userCode,
        name: data.name,
        phoneNumber: data.phoneNumber,
        userActivity: data.userActivity,
        dob: this.formatDate(data.DOB),
        userID: data.userID
      });
      this.authLoading = false;

      return { data };
    } else {
      return { error };
    }
  };

  postPatient = async (
    name,
    phoneNumber,
    diabeticType,
    gender,
    DOB,
    isPremium,
    doctorId
  ) => {
    this.authLoading = true;
    this.isLoading = true;
    let { data, error } = await PostPatient(
      name,
      phoneNumber,
      diabeticType,
      gender,
      DOB,
      isPremium,
      doctorId
    );
    this.authLoading = false;
    if (data) {
      this.isLoading = false;
      swal({
        title: "Success",
        text: "Patient Success Added",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = "/patients";
        return { data };
      });
    } else {
      this.isLoading = false;

      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getUserTags = async (itemID) => {
    this.isLoading = true;

    let { data, error } = await GetUserTags(itemID);

    if (data) {
      this.isLoading = false;
      this.chipArray = data.userTag;
    } else {
      this.isLoading = false;

      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };
}

decorate(PatientStore, {
  editedData: observable,
  patientArrayList: observable,
  authLoading: observable,
  currentPage: observable,
  pageSize: observable,
  totalCount: observable,
  searchValue: observable,
  typeFilterEunum: observable,
  isDiabisaMember: observable,
  phone: observable,
  isLoading: observable,
  doctorid: observable,

  formatDate: action,
  getAllPatient: action,
  getByIDPatient: action,
  getPatientByDoctorID: action,
  postPatient: action,
  putUpdatePatient: action,
  setEditedData: action,
  setCurrentPage: action,
  setSearchValue: action,
  setTypeFilterValue: action
});

export default new PatientStore();
