import { observable, decorate } from "mobx";

class ViewImageWidgetStore {
  //observable
  editedData = {};

  //action
  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  showWidget = async () => {
    await this.setEditedData({
      warning: true,
      isActive: true
    });
  };

  hideWidget = async () => {
    await this.setEditedData({
      warning: false,
      isActive: false
    });
  };
}
decorate(ViewImageWidgetStore, {
  //observable
  editedData: observable
});

export default new ViewImageWidgetStore();
