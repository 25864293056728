import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllMedicationRecord(
  id,
  startDate,
  endDate,
  search,
  offset,
  limit,
  order
) {
  try {
    let getAllMedicationRecord = await axios.Get({
      url: `${adminUrl}/medication-records?userId=${id}&startDate=${startDate}&endDate=${endDate}&search=${search ||
        ""}&offset=${offset}&limit=${limit}&order=${order.toUpperCase()}`
    });
    if (getAllMedicationRecord.statusCode !== 200) {
      return { error: getAllMedicationRecord.message };
    }

    return { data: getAllMedicationRecord.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetMedicationRecordById(id) {
  try {
    let getMedicationRecordById = await axios.Get({
      url: `${adminUrl}/medication-records/${id}`
    });
    if (getMedicationRecordById.statusCode !== 200) {
      return { error: getMedicationRecordById.message };
    }

    return { data: getMedicationRecordById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutDeleteMedicationRecordById(id) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/medication-records/${id}/delete`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (e) {
    return { error: e };
  }
}
