const Joi = require("joi-browser");
const joiToForms = require("joi-errors-for-forms").form;
const convertToForms = joiToForms();

export const truncateText = (s, maxLength) => {
  if (!s) {
    return "";
  }

  if (s.length < maxLength) {
    return s;
  }

  return s.substring(0, maxLength) + "...";
};

export const validateEditedData = ({ editedData, joiSchema }) => {
  const joiResult = Joi.validate(editedData, joiSchema, {
    convert: true,
    abortEarly: false,
    stripUnknown: true
  });
  if (!joiResult.error) {
    return null;
  }
  return convertToForms(joiResult.error);
};

export const numberFormatter = n => {
  return new Intl.NumberFormat().format(n);
};

export const reOrderArray = array => {
  const receivedArray = array;
  let arrayLength = array.length;
  let temp;
  let index;

  while (arrayLength > 0) {
    index = Math.floor(Math.random() * arrayLength);
    arrayLength -= 1;
    temp = receivedArray[arrayLength];
    receivedArray[arrayLength] = receivedArray[index];
    receivedArray[index] = temp;
  }
  return receivedArray;
};

export const silviaTriviaValidation = (editedDataQuestion, gameListId) => {
  const tempObject = editedDataQuestion;

  tempObject.gameListId = gameListId;
  if (tempObject.type === 'Sort') {
    const choiceArray = [];
    const answerArray = [];
    let correctWrongText = '';

    for (let i = 0; i < 5; i += 1) {
      if (tempObject[`choice${i + 1}`] && tempObject[`choice${i + 1}`] !== null) {
        choiceArray.push(tempObject[`choice${i + 1}`]);
      }

      if (tempObject[`answer${i + 1}`] && tempObject[`answer${i + 1}`] !== null) {
        answerArray.push(tempObject[`answer${i + 1}`]);
      }
    }

    for (let j = 0; j < answerArray.length; j += 1) {
      if (j === answerArray.length - 1) {
        correctWrongText += `${j + 1}. ${tempObject[`answer${j + 1}`]}`;
      } else {
        correctWrongText += `${j + 1}. ${tempObject[`answer${j + 1}`]}\n`;
      }
    }

    tempObject.choice = choiceArray;
    tempObject.answer = answerArray;
    tempObject.correctText = correctWrongText;
    tempObject.wrongText = correctWrongText;
  } else if (tempObject.type === 'True or false') {
    tempObject.choice = ["TRUE", "FALSE"];
    tempObject.answer = [tempObject.answer];
    tempObject.wrongText = tempObject.correctText ? tempObject.correctText : "";
  } else if (tempObject.type === 'Scramble') {
    let choiceRemoveSpace = tempObject.answer;
    let containSpace = true;

    while (containSpace) {
      if (choiceRemoveSpace.includes(' ')) {
        choiceRemoveSpace = choiceRemoveSpace.replace(' ', '');
        containSpace = true;
      } else {
        containSpace = false;
      }
    }

    const choiceArray = choiceRemoveSpace.split('');
    const tempChoice = reOrderArray(choiceArray);
    tempObject.choice = tempChoice;
    tempObject.answer = [tempObject.answer];
    tempObject.wrongText = tempObject.correctText ? tempObject.correctText : "";
    tempObject.correctText = tempObject.correctText ? tempObject.correctText : "";
  } else if (tempObject.type === 'Category') {
    const tempArray = [];
    let correctWrongText = '';

    for (let i = 0; i < tempObject.answer.length; i++) {
      tempArray.push(tempObject[`answer${i + 1}`]);
      if (i === tempObject.answer.length - 1) {
        correctWrongText += `${i + 1}. ${tempObject[`answer${i + 1}`]}`;
      } else {
        correctWrongText += `${i + 1}. ${tempObject[`answer${i + 1}`]}\n`;
      }
    }

    tempObject.choice = [];
    tempObject.answer = tempArray;
    tempObject.correctText = correctWrongText;
    tempObject.wrongText = correctWrongText;
  } else if (tempObject.type === 'Matching') {
    let leftString = "";
    let rightString = "";
    let rightAnswerString = "";
    let correctWrongText = "";
    for (let i = 0; i < 4; i++) {
      if (i === 3) {
        leftString += tempObject[`left${i + 1}`];
        rightString += tempObject[`right${i + 1}`]
        rightAnswerString += tempObject[`answer${i + 1}`];
        correctWrongText += `${tempObject[`left${i + 1}`]} <--> ${tempObject[`answer${i + 1}`]}`;
      } else {
        leftString += `${tempObject[`left${i + 1}`]},,@`;
        rightString += `${tempObject[`right${i + 1}`]},,@`;
        rightAnswerString += `${tempObject[`answer${i + 1}`]},,@`;
        correctWrongText += `${tempObject[`left${i + 1}`]} <--> ${tempObject[`answer${i + 1}`]}\n`;
      }
    }

    tempObject.choice = [leftString, rightString];
    tempObject.answer = [leftString, rightAnswerString];
    tempObject.correctText = correctWrongText;
    tempObject.wrongText = correctWrongText;
  }

  return tempObject;
};
