import axios from "axios";

export async function PostImg(files, path) {
  try {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("upload_preset", "diabetic"); // Replace the preset name with your own
    formData.append("api_key", "953525937352546"); // Replace API key with your own Cloudinary key
    formData.append("timestamp", (Date.now() / 1000) | 0);
    formData.append("folder", path);

    let uploaders = await axios
      .post("https://api.cloudinary.com/v1_1/siloam/image/upload", formData, {
        headers: { "X-Requested-With": "XMLHttpRequest" }
      })
      .then(response => {
        return response;
      });

    if (uploaders.status !== 200) {
      return { error: uploaders.message };
    }
    return { data: uploaders.data };
  } catch (e) {
    return { error: e };
  }
}

export async function PostJson(files, path) {
  try {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("upload_preset", "diabetic"); // Replace the preset name with your own
    formData.append("api_key", "953525937352546"); // Replace API key with your own Cloudinary key
    formData.append("timestamp", (Date.now() / 1000) | 0);
    formData.append("folder", path);

    let uploaders = await axios
      .post("https://api.cloudinary.com/v1_1/siloam/auto/upload", formData, {
        headers: { "X-Requested-With": "XMLHttpRequest" }
      })
      .then(response => {
        return response;
      });

    if (uploaders.status !== 200) {
      return { error: uploaders.message };
    }
    return { data: uploaders.data };
  } catch (e) {
    return { error: e };
  }
}
