import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetBloodGlucoseTargetDefaultValue(offset, limit) {
  try {
    const getBloodGlucoseTargetDefaultValue = await axios.Get({
      url: `${adminUrl}/blood-glucose-configs?type=targetDefaultValue&offset=${
        offset || ""
      }&limit=${limit || ""}`,
    });

    if (getBloodGlucoseTargetDefaultValue.statusCode !== 200) {
      return { error: getBloodGlucoseTargetDefaultValue.message };
    }

    return { data: getBloodGlucoseTargetDefaultValue.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function UpdateBloodGlucoseTargetDefaultValue(updateArray) {
  try {
    const updateBloodGlucoseAlert = await axios.Put({
      url: `${adminUrl}/blood-glucose-configs/update/target-default-value`,
      data: { updateArray },
    });

    if (updateBloodGlucoseAlert.statusCode !== 200) {
      return { error: updateBloodGlucoseAlert.message };
    }

    return { data: updateBloodGlucoseAlert.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
