import {
  GetAllExercise,
  GetByIdExercise,
  PostExercise,
  PutUpdateExercise,
  PutDeleteExercise
} from "../services/ExerciseService";
import { observable, action, decorate } from "mobx";
import commonStore from "./CommonStore";

class ExerciseStore {
  //OBSERVABLE
  dataArray = [];
  editedData = {
    isLoading: false,
    currentPage: 0,
    totalCount: 0,
    searchQuery: "",
    name: "",
    pageSize: 10,
    caloriesPerMin: ""
  };
  columns = [
    {
      name: "exerciseID",
      title: "Exercise ID"
    },
    {
      name: "name",
      title: "Exercise Name"
    },
    {
      name: "caloriesPerMin",
      title: "Calories Burned per Minute (cal)"
    }
  ];

  columnWidths = [
    {
      columnName: "exerciseID",
      width: 200
    },
    {
      columnName: "name",
      width: 300
    },
    {
      columnName: "caloriesPerMin",
      width: 300
    }
  ];

  disabledColumn = [
    {
      columnName: "name",
      sortingEnabled: false
    },
    {
      columnName: "caloriesPerMin",
      sortingEnabled: false
    }
  ];

  sorting = [{ columnName: "exerciseID", direction: "asc" }];

  //ACTION
  clearEditedData = () => {
    this.editedData = {};
  };

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setPageLimit = value => {
    this.editedData.pageSize = value;
  };

  setCurrentPage = currentPage => {
    this.editedData.currentPage = currentPage;
    this.getAllExercise();
  };

  setSearchQuery = searchValue => {
    this.editedData.currentPage = 0;
    this.editedData.searchQuery = searchValue;
  };

  setSorting = sorting => {
    this.sorting = sorting;
  };

  getAllExercise = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await GetAllExercise({
      offset: Number(this.editedData.currentPage),
      limit: this.editedData.pageSize,
      search: String(this.editedData.searchQuery),
      order: this.sorting[0].direction
    });

    if (data) {
      this.editedData.isLoading = false;

      this.editedData.totalCount = Number(data.count);
      this.dataArray = data.exercise.map(data => {
        return {
          ...data,
          exerciseID: Number(data.exerciseID)
        };
      });
      return { data };
    } else if (error) {
      this.editedData.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  getByIDExercise = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await GetByIdExercise({
      id: this.editedData.exerciseID
    });

    this.editedData.isLoading = false;

    if (data) {
      this.setEditedData({
        name: String(data.name),
        caloriesPerMin: String(data.caloriesPerMin),
        exerciseID: data.exerciseID
      });
      return { data };
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  validateEmptyField() {
    if (this.editedData.name === "" || this.editedData.caloriesPerMin === "") {
      commonStore.showErrorMessage("All fields must be filled!");
      return false;
    } else return true;
  }

  postExercise = async () => {
    this.editedData.isLoading = true;

    if (this.validateEmptyField()) {
      let { data, error } = await PostExercise({
        name: String(this.editedData.name),
        caloriesPerMin: String(this.editedData.caloriesPerMin)
      });

      this.editedData.isLoading = false;

      if (data) {
        commonStore.showSuccessMessage("Exercise added successfully!");
        setTimeout(() => {
          window.location.href = "/exercises";
        }, 500);
      } else if (error) {
        if (error.data) {
          commonStore.showErrorMessage(error.data.message);
        } else {
          commonStore.showErrorMessage("Something went wrong.");
        }
      }
    }
  };

  putUpdateExercise = async () => {
    this.editedData.isLoading = true;

    if (this.validateEmptyField()) {
      let { data, error } = await PutUpdateExercise({
        name: String(this.editedData.name),
        caloriesPerMin: String(this.editedData.caloriesPerMin),
        id: String(this.editedData.exerciseID)
      });

      this.editedData.isLoading = false;

      if (data) {
        commonStore.showSuccessMessage("Exercise updated successfully!");
        setTimeout(() => {
          window.location.href = "/exercises";
        }, 500);
      } else if (error) {
        if (error.data) {
          commonStore.showErrorMessage(error.data.message);
        } else {
          commonStore.showErrorMessage("Something went wrong.");
        }
      }
    }
  };

  putDeleteExercise = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await PutDeleteExercise({
      id: String(this.editedData.exerciseID)
    });

    this.editedData.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Exercise deleted successfully!");
      setTimeout(() => {
        window.location.href = "/exercises";
      }, 500);
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };
}

decorate(ExerciseStore, {
  //OBSERVABLE
  editedData: observable,
  dataArray: observable,
  totalCount: observable,
  searchQuery: observable,
  sorting: observable,
  pageSize: observable,

  //ACTION
  setSearchQuery: action,
  setCurrentPage: action,
  postExercise: action,
  setEditedData: action,
  setSorting: action
});

export default new ExerciseStore();
