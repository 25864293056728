import {
  GetAllExerciseRecord,
  GetExerciseRecordById,
  PutUpdateExerciseRecord,
  PutDeleteExerciseRecord
} from "../services/ExerciseRecordService";
import { observable, decorate } from "mobx";
import moment from "moment";
import swal from "sweetalert2";
import commonStore from "./CommonStore";

class ExerciseRecordStore {
  editedData = {};
  dataArray = [];
  caloriesBurnedTarget = 0;
  searchQuery = "";
  startDate = "";
  endDate = "";
  loading = false;
  pageSize = 10;
  currentPage = 0;
  totalCount = 0;
  columns = [
    { name: "exerciseRecordID", title: "Record ID" },
    { name: "date", title: "Date & Time" },
    { name: "exerciseName", title: "Exercise Name" },
    { name: "elapsedTime", title: "Duration (Minute)" },
    { name: "totalCalories", title: "Calories (Cal)" }
  ];
  columnWidths = [
    { columnName: "exerciseRecordID", width: 220 },
    { columnName: "date", width: 230 },
    { columnName: "exerciseName", width: 230 },
    { columnName: "elapsedTime", width: 230 },
    { columnName: "totalCalories", width: 230 }
  ];
  disabledColumn = [
    { columnName: "date", sortingEnabled: false },
    { columnName: "exerciseName", sortingEnabled: false },
    { columnName: "elapsedTime", sortingEnabled: false },
    { columnName: "totalCalories", sortingEnabled: false }
  ];
  sorting = [{ columnName: "exerciseRecordID", direction: "asc" }];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setStartDate = value => {
    this.currentPage = 0;
    this.startDate = value;
  };

  setEndDate = value => {
    this.currentPage = 0;
    this.endDate = value;
  };
  setTypeFilterValue = value => {
    this.currentPage = 0;
    this.typeFilterValue = value;
  };

  setSorting = updatedSorting => {
    // if (this.order === "DESC") {
    //   this.order = "ASC";
    // } else {
    //   this.order = "DESC";
    // }
    this.sorting = updatedSorting;
  };

  setCurrentPage = page => {
    this.currentPage = page;
  };

  setSearchQuery = searchValue => {
    this.currentPage = 0;
    this.searchQuery = searchValue;
  };

  setCurrentPage = currentPage => {
    this.currentPage = currentPage;
    this.getAllExerciseRecord();
  };

  getAllExerciseRecord = async id => {
    this.authLoading = true;
    this.loading = true;
    let { data, error } = await GetAllExerciseRecord(
      this.editedData.userId,
      this.startDate ? moment(this.startDate).format("YYYY-MM-DDThh:mm") : "",
      this.endDate ? moment(this.endDate).format("YYYY-MM-DDThh:mm") : "",
      this.searchQuery,
      this.currentPage,
      this.pageSize,
      this.sorting[0].direction
    );

    if (data) {
      this.totalCount = data.count;
      this.loading = false;
      this.dataArray = data.data.exerciseRecords.map(data => {
        const exerciseRecordID = data.exerciseRecordID
          ? Number(data.exerciseRecordID)
          : "";
        const date = moment(data.date ? data.date : "").format(
          "DD MMM YYYY hh:mm:ss"
        );
        const exerciseId = data.exercise.exerciseID
          ? data.exercise.exerciseID
          : "";
        const exerciseName = data.exercise.name ? data.exercise.name : data.exerciseName;
        const elapsedTime = data.elapsedTime ? data.elapsedTime : "";
        const calories = data.calories ? data.calories : "";

        return {
          ...data,
          exerciseRecordID,
          date,
          exerciseId,
          exerciseName,
          elapsedTime,
          calories
        };
      });

      this.caloriesBurnedTarget = data.data.target.exerciseTarget
        ? data.data.target.exerciseTarget
        : "";
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  putUpdateExerciseRecord = async (
    exerciseRecordID,
    totalCalories,
    exerciseId,
    elapsedTime,
    date,
    userId,
    id
  ) => {
    this.loading = true;
    let { data, error } = await PutUpdateExerciseRecord(
      exerciseRecordID,
      totalCalories,
      String(exerciseId),
      elapsedTime,
      date,
      userId,
      id
    );
    if (data) {
      this.loading = false;
      swal({
        title: "Success",
        text: "Diet Success Updated",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = `/patients/${userId}/healthtracker`;
        return { data };
      });
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  putDeleteExerciseRecord = async (id, userId) => {
    let { data, error } = await PutDeleteExerciseRecord(id);
    if (data) {
      swal({
        title: "Success",
        text: "Exercise Record Success Deleted",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = `/patients/${userId}/healthtracker`;
        return { data };
      });
    } else {
      return { error };
    }
  };

  getExerciseRecordById = async id => {
    this.authLoading = true;

    let { data, error } = await GetExerciseRecordById(id);

    if (data) {
      this.setEditedData({
        exerciseID: data.exerciseRecordID,
        date: moment(data.date).format("DD MMM YYYY hh:mm:ss"),
        exerciseId: data.exercise.exerciseID,
        elapsedTime: data.elapsedTime,
        totalCalories: data.totalCalories
      });
      return { data };
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };
}

decorate(ExerciseRecordStore, {
  editedData: observable,
  dataArray: observable,
  caloriesBurnedTarget: observable,
  searchQuery: observable,
  startDate: observable,
  endDate: observable,
  currentPage: observable,
  totalCount: observable,
  loading: observable
});

export default new ExerciseRecordStore();
