import { GetAllHabit, GetHabitById, CreateHabit, UpdateHabit, DeleteHabit } from "../services/HabitService";
import { observable, decorate } from "mobx";
import commonStore from "./CommonStore";

class HabitStore {
  editedData = {
    imageUrl: null,
    imgLoading: false,
    isRecord: 'true',
    type: 'template',
    isChecked: false,
  };
  habitId = "";
  dataArray = [];
  searchQuery = "";
  habitNature = "All";
  type = "All";
  loading = false;
  pageSize = 10;
  currentPage = 0;
  totalCount = 0;
  columns = [
    { name: "id", title: "Habit ID" },
    { name: "habitNature", title: "Habit Type" },
    { name: "name", title: "Habit Name" },
    { name: "type", title: "Habit Source" },
  ];
  columnWidths = [
    { columnName: "id", width: 250 },
    { columnName: "habitNature", width: 250 },
    { columnName: "name", width: 250 },
    { columnName: "type", width: 250 },
  ];
  typeEnum = [
    { value: 'good', name: "Good" },
    { value: 'bad', name: "Bad" },
    { value: 'All', name: "All" },
  ];
  typeEnumDetail = [
    { value: 'good', name: "Good" },
    { value: 'bad', name: "Bad" },
  ];
  sourceEnum = [
    { value: 'template', name: "Template" },
    { value: 'custom', name: "Custom" },
    { value: 'All', name: "All" },
  ];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setSearchQuery = searchValue => {
    this.currentPage = 0;
    this.searchQuery = searchValue;
  };

  setCurrentPage = currentPage => {
    this.currentPage = currentPage;
    this.getAllHabit();
  };

  setType = value => {
    this.habitNature = value;
  };

  setSource = value => {
    this.type = value;
  };

  setHabitNature = value => {
    this.editedData.habitNature = value;
  };

  clearEditedData = () => {
    this.editedData = {
      imageUrl: null,
      imgLoading: false,
      isRecord: 'false',
      type: 'template',
      isChecked: false,
    };
  };

  getAllHabit = async () => {
    this.loading = true;

    let { data, error } = await GetAllHabit(
      this.habitNature ? (this.habitNature !== 'All' ? this.habitNature : '') : '',
      this.type ? (this.type !== 'All' ? this.type : '') : '',
      this.searchQuery,
      this.currentPage,
      this.pageSize,
    );

    if (data) {
      this.loading = false;
      this.totalCount = data.count;
      this.dataArray = data.habits.map(h => {
        return {
          ...h,
          isDeleted: h.isDeleted.toString(),
        };
      });
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getHabitById = async (id) => {
    this.loading = true;

    let { data, error } = await GetHabitById(id);

    if (data) {
      this.loading = false;
      this.habitId = data.id;
      this.editedData.imageUrl = data.imageUrl;
      this.editedData.jsonOn = data.jsonOn;
      this.editedData.jsonOff = data.jsonOff;
      this.editedData.nameId = data.nameId;
      this.editedData.nameEn = data.nameEn;
      this.editedData.descriptionId = data.descriptionId;
      this.editedData.descriptionEn = data.descriptionEn;
      this.editedData.questionId = data.questionId;
      this.editedData.questionEn = data.questionEn;
      this.editedData.subTitleId = data.subTitleId;
      this.editedData.subTitleEn = data.subTitleEn;
      this.editedData.habitNature = data.habitNature;
      this.editedData.defaultDailyTarget = data.defaultDailyTarget;

      if (data.defaultDailyTarget && data.defaultDailyTarget !== '') {
        this.editedData.isChecked = true;
      }

    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  createHabit = async (history) => {
    this.loading = true;

    if (this.editedData.defaultDailyTarget && this.editedData.defaultDailyTarget !== '') {
      if ((!this.editedData.jsonOn && this.editedData.jsonOn !== '') || (!this.editedData.jsonOff && this.editedData.jsonOff !== '')) {
        commonStore.showErrorMessage("Json must be filled");
        return;
      }
    }

    let { data, error } = await CreateHabit(this.editedData);

    if (data) {
      this.loading = false;
      history.push("/habit");

    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  updateHabit = async (history) => {
    this.loading = true;

    if (this.editedData.defaultDailyTarget && this.editedData.defaultDailyTarget !== '') {
      if ((!this.editedData.jsonOn && this.editedData.jsonOn !== '') || (!this.editedData.jsonOff && this.editedData.jsonOff !== '')) {
        commonStore.showErrorMessage("Json must be filled");
        return;
      }
    }

    let { data, error } = await UpdateHabit(this.habitId, this.editedData);

    if (data) {
      this.loading = false;
      history.push("/habit");

    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  deleteHabit = async (history) => {
    this.loading = true;

    let { data, error } = await DeleteHabit(this.habitId);

    if (data) {
      this.loading = false;
      history.push("/habit");

    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };
}

decorate(HabitStore, {
  editedData: observable,
  habitId: observable,
  dataArray: observable,
  searchQuery: observable,
  currentPage: observable,
  totalCount: observable,
  loading: observable,
  habitNature: observable,
  type: observable,
});

export default new HabitStore();
