import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllDoctor({ offset, limit, search, order }) {
  try {
    let getAllDoctorResponse = await axios.Get({
      url: `${adminUrl}/doctors?offset=${offset || ""}&limit=${limit ||
        ""}&search=${search || ""}&order=${order.toUpperCase()}`
    });
    if (getAllDoctorResponse.statusCode !== 200) {
      return { error: getAllDoctorResponse.message };
    }

    return { data: getAllDoctorResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
export async function GetByIdDoctor(id) {
  try {
    let getByIDDoctor = await axios.Get({
      url: `${adminUrl}/doctors/${id}`
    });
    if (getByIDDoctor.statusCode !== 200) {
      return { error: getByIDDoctor.message };
    }

    return { data: getByIDDoctor.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdatePassword(id) {
  try {
    let putUpdatePassword = await axios.Put({
      url: `${adminUrl}/doctors/${id}/password`
    });
    if (putUpdatePassword.statusCode !== 200) {
      return { error: putUpdatePassword.message };
    }

    return { data: putUpdatePassword.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PostDoctor(name, imageUrl, specialist, email, password) {
  try {
    let postDoctor = await axios.Post({
      url: `${adminUrl}/doctors`,
      data: { name, imageUrl, specialist, email, password }
    });

    if (postDoctor.statusCode !== 200) {
      return { error: postDoctor.message };
    }

    return { data: postDoctor.data };
  } catch (e) {
    return { error: e };
  }
}

export async function PutUpdateDoctor(
  name,
  imageUrl,
  specialist,
  email,
  password,
  id
) {
  try {
    let putUpdateDoctor = await axios.Put({
      url: `${adminUrl}/doctors/${id}`,
      data: { name, imageUrl, specialist, email, password }
    });

    if (putUpdateDoctor.statusCode !== 200) {
      return { error: putUpdateDoctor.message };
    }

    return { data: putUpdateDoctor.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutChangePasswordDoctor(
  newPassword,
  confirmPassword,
  id
) {
  try {
    let putChangePasswordDoctor = await axios.Put({
      url: `${adminUrl}/doctors/${id}/password`,
      data: { newPassword, confirmPassword }
    });

    if (putChangePasswordDoctor.statusCode !== 200) {
      return { error: putChangePasswordDoctor.message };
    }

    return { data: putChangePasswordDoctor.data };
  } catch (e) {
    return { error: e };
  }
}

export async function PutDeleteDoctor(id) {
  try {
    let putDeleteDoctor = await axios.Put({
      url: `${adminUrl}/doctors/${id}/delete`
    });

    if (putDeleteDoctor.statusCode !== 200) {
      return { error: putDeleteDoctor.message };
    }

    return { data: putDeleteDoctor.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
