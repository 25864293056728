import axios from "axios";
import { getCookie } from "./cookie";

const queryParser = params => {
  let queryParams = "";
  for (let key in params) {
    if (!queryParams) {
      queryParams = queryParams.concat(`?${key}=${params[key]}`);
    } else {
      queryParams = queryParams.concat(`&${key}=${params[key]}`);
    }
  }
  return queryParams;
};

const getToken = () => {
  let token = "";

  if (getCookie("siloam_current_token")) {
    token = getCookie("siloam_current_token");
  }
  return token;
};

const defaultHeaders = {
  "Content-Type": "application/json"
  // 'Accept': 'application/json'
};

const gtnHeaders = {};

const Axios = {
  Get: ({ url, params }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: "GET",
          headers: {
            ...defaultHeaders,
            token: getToken()
          }
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          if (error.response.data ? error.response.data.status : "" === "OK") {
            reject(error.response.data);
          } else {
            reject(error.response.data.message);
          }

        });
    });
  },
  Post: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url,
          method: "POST",
          headers: {
            ...defaultHeaders,
            token: getToken()
          },
          data: data
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data.message);
        });
    });
  },
  PostGTN: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url,
          method: "POST",
          headers: {
            ...gtnHeaders,
            token: getToken()
          },
          data: data
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data.message);
        });
    });
  },
  Put: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: "PUT",
          headers: {
            ...defaultHeaders,
            token: getToken()
          },
          data
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data.message);
        });
    });
  },
  Patch: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: "PATCH",
          headers: {
            ...defaultHeaders,
            token: getToken()
          },
          data
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data.message);
        });
    });
  },
  Delete: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: "DELETE",
          headers: {
            ...defaultHeaders,
            token: getToken()
          }
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error.response.data.message);
        });
    });
  }
};

export default Axios;
