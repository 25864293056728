import { PostImg, PostJson } from "../services/CloudinaryService";
import { action, decorate } from "mobx";

class CloudinaryStore {
  imageLoading = false;

  postImage = async (file, path) => {
    this.imageLoading = true;

    const { data, error } = await PostImg(file, path);

    if (data) {
      this.imageLoading = false;
      return { data };
    } else {
      this.imageLoading = false;
      return { error };
    }
  };

  postJson = async (file, path) => {
    this.imageLoading = true;

    const { data, error } = await PostJson(file, path);

    if (data) {
      this.imageLoading = false;
      return { data };
    } else {
      this.imageLoading = false;
      return { error };
    }
  };
}

decorate(CloudinaryStore, {
  postImage: action
});

export default new CloudinaryStore();
