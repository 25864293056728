import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetBloodGlucoseTargetLimits(offset, limit) {
  try {
    const getBloodGlucoseTargetLimits = await axios.Get({
      url: `${adminUrl}/blood-glucose-configs?type=targetLimit&offset=${
        offset || ""
      }&limit=${limit || ""}`,
    });

    if (getBloodGlucoseTargetLimits.statusCode !== 200) {
      return { error: getBloodGlucoseTargetLimits.message };
    }

    return { data: getBloodGlucoseTargetLimits.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function UpdateBloodGlucoseTargetLimit(bloodGlucoseMinValue, bloodGlucoseMaxValue, hba1cMinValue, hba1cMaxValue) {
  try {
    const updateBloodGlucoseAlert = await axios.Put({
      url: `${adminUrl}/blood-glucose-configs/update/target-limit`,
      data: { bloodGlucoseMinValue, bloodGlucoseMaxValue, hba1cMinValue, hba1cMaxValue },
    });

    if (updateBloodGlucoseAlert.statusCode !== 200) {
      return { error: updateBloodGlucoseAlert.message };
    }

    return { data: updateBloodGlucoseAlert.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
