import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllHospitalList({
  offset,
  limit,
  search,
  isActive,
  area_id
}) {
  try {
    let getAllHospitalResponse = await axios.Get({
      url: `${adminUrl}/hospitals?offset=${offset || ""}&limit=${limit ||
        ""}&search=${search || ""}&isActive=${isActive ||
        ""}&area_id=${area_id || ""}`
    });

    if (getAllHospitalResponse.statusCode !== 200) {
      return { error: getAllHospitalResponse.message };
    }

    return { data: getAllHospitalResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetByIDHospital({ id }) {
  try {
    let getByIDHospitalResponse = await axios.Get({
      url: `${adminUrl}/hospitals/${id}`
    });

    if (getByIDHospitalResponse.statusCode !== 200) {
      return { error: getByIDHospitalResponse.message };
    }

    return { data: getByIDHospitalResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdateHospital({ id, isActive }) {
  try {
    let putUpdateHospitalResponse = await axios.Put({
      url: `${adminUrl}/hospitals/${id}/is-active`,
      data: { isActive }
    });

    if (putUpdateHospitalResponse.statusCode !== 200) {
      return { error: putUpdateHospitalResponse.message };
    }

    return { data: putUpdateHospitalResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
