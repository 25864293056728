import { observable, action, decorate } from "mobx";
import React from "react";
import commonStore from "./CommonStore";
import {
  GetAllSpecialistList,
  PutUpdateSpecialist,
  GetByIDSpecialist
} from "../services/SpecialistListService";

class SpecialistListStore {
  //OBSERVABLE
  dataArray = [];
  editedData = {
    isLoading: false,
    currentPage: 0,
    pageSize: 10,
    totalCount: 0,
    searchQuery: "",
    isActive: "all"
  };

  valueTransformer = {
    isActive: data => {
      if (data.isActive === true) {
        return <span className="active-span">ACTIVE</span>;
      } else if (data.isActive === false) {
        return <span className="nonactive-span">INACTIVE</span>;
      }
    }
  };

  columns = [
    {
      name: "specialty_id",
      title: "Specialty ID"
    },
    {
      name: "name_id",
      title: "Name ID"
    },
    {
      name: "name_en",
      title: "Name EN"
    },
    {
      name: "isActive",
      title: "Status",
      valueTransformer: this.valueTransformer["isActive"]
    }
  ];

  columnWidths = [
    {
      columnName: "specialty_id",
      width: 300
    },
    {
      columnName: "name_id",
      width: 300
    },
    {
      columnName: "name_en",
      width: 300
    },
    {
      columnName: "isActive",
      width: 200
    }
  ];

  specialistStatusEnum = [
    { value: "all", name: "All" },
    { value: "true", name: "Active" },
    { value: "false", name: "Inactive" }
  ];

  specialistStatusUpdate = [
    { value: "true", name: "Active" },
    { value: "false", name: "Inactive" }
  ];

  sorting = [{ columnName: "id", direction: "asc" }];

  //ACTION
  clearEditedData = () => {
    this.editedData = {};
  };

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setCurrentPage = currentPage => {
    this.editedData.currentPage = currentPage;
    this.getAllSpecialistList();
  };

  setIsActive = value => {
    this.editedData.isActive = value;
  };

  setPageLimit = value => {
    this.editedData.pageSize = value;
  };

  setSearchQuery = searchValue => {
    this.editedData.currentPage = 0;
    this.editedData.searchQuery = searchValue;
  };

  setSorting = sorting => {
    this.sorting = sorting;
  };

  getAllSpecialistList = async () => {
    this.editedData.isLoading = true;

    let all = "";
    if (this.editedData.isActive === "all") {
      all = "yes";
    }

    const { data, error } = await GetAllSpecialistList({
      offset: Number(this.editedData.currentPage),
      limit: Number(this.editedData.pageSize),
      search: String(this.editedData.searchQuery),
      isActive: all === "yes" ? "" : String(this.editedData.isActive)
    });

    if (data) {
      this.editedData.isLoading = false;

      this.editedData.totalCount = Number(data.count);
      this.dataArray = data.specialties.map(data => {
        return {
          ...data
        };
      });
    } else if (error) {
      this.editedData.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  getByIDSpecialist = async () => {
    this.editedData.isLoading = true;

    const { data, error } = await GetByIDSpecialist({
      id: String(this.editedData.id)
    });

    this.editedData.isLoading = false;

    if (data) {
      this.setEditedData({
        id: String(data.id),
        specialty_id: String(data.specialty_id),
        name_en: String(data.name_en),
        name_id: String(data.name_id),
        isActive: String(data.isActive)
      });
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  putUpdateSpecialist = async () => {
    this.editedData.isLoading = true;

    const { data, error } = await PutUpdateSpecialist({
      id: String(this.editedData.id),
      isActive: String(this.editedData.isActive)
    });

    this.editedData.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Specialist Status Updated!");
      setTimeout(() => {
        window.location.href = "/hospitals";
      }, 500);
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  //COMPUTED
}

decorate(SpecialistListStore, {
  //OBSERVABLE
  editedData: observable,
  dataArray: observable,

  //ACTION
  setEditedData: action,
  getAllSpecialistList: action,
  setSpecialistStatusValue: action,
  getSearchQueryDataArrayFromServer: action,
  getByIDSpecialist: action,
  putUpdateSpecialist: action

  //COMPUTED
});

export default new SpecialistListStore();
