import { GetAllHabitRecord } from "../services/HabitRecordService";
import { observable, decorate } from "mobx";
import moment from "moment";
import commonStore from "./CommonStore";

class HabitRecordStore {
    dataArray = [];
    searchQuery = "";
    startDate = "";
    endDate = "";
    type = "All";
    isAchieved = 'All';
    loading = false;
    pageSize = 10;
    currentPage = 0;
    totalCount = 0;
    columns = [
        { name: "date", title: "Date" },
        { name: "habitId", title: "Habit ID" },
        { name: "type", title: "Type" },
        { name: "name", title: "Habit Name" },
        { name: "isAchieved", title: "Achieved/Not" },
        { name: "dailyTarget", title: "Target" },
        { name: "dailyTargetValue", title: "Achieved" },
    ];
    columnWidths = [
        { columnName: "date", width: 150 },
        { columnName: "habitId", width: 150 },
        { columnName: "type", width: 150 },
        { columnName: "name", width: 250 },
        { columnName: "isAchieved", width: 150 },
        { columnName: "dailyTarget", width: 150 },
        { columnName: "dailyTargetValue", width: 150 },
    ];
    typeEnum = [
        { value: 'template', name: "Template" },
        { value: 'custom', name: "Custom" },
        { value: 'all', name: "All" },
    ];
    isAchievedEnum = [
        { value: 'true', name: "Achived" },
        { value: 'false', name: "Not Achieved" },
        { value: 'all', name: "All" },
    ];

    setStartDate = value => {
        this.currentPage = 0;
        this.startDate = value;
    };

    setEndDate = value => {
        this.currentPage = 0;
        this.endDate = value;
    };

    setTypeFilterValue = value => {
        this.currentPage = 0;
        this.typeFilterValue = value;
    };

    setSearchQuery = searchValue => {
        this.currentPage = 0;
        this.searchQuery = searchValue;
    };

    setCurrentPage = currentPage => {
        this.currentPage = currentPage;
        this.getAllHabitRecord();
    };

    setHabitType = value => {
        this.type = value;
    };

    setIsAchieved = value => {
        this.isAchieved = value;
    };

    getAllHabitRecord = async userId => {
        this.loading = true;

        let { data, error } = await GetAllHabitRecord(
            userId,
            this.type ? (this.type !== 'all' ? this.type : '') : '',
            this.isAchieved ? (this.isAchieved !== 'all' ? this.isAchieved : '') : '',
            this.startDate ? moment(this.startDate).format("YYYY-MM-DDThh:mm") : "",
            this.endDate ? moment(this.endDate).format("YYYY-MM-DDThh:mm") : "",
            this.searchQuery,
            this.currentPage,
            this.pageSize,
        );

        if (data) {
            this.loading = false;
            this.totalCount = data.count;

            this.dataArray = data.habitRecord.map(data => {
                const type = data.habit.type;
                const name = data.habit.name;
                const isAchieved = data.dailyTarget ? (data.isAchieved ? 'Yes' : 'No') : '-';

                return {
                    ...data,
                    type,
                    name,
                    isAchieved
                }
            });
        } else {
            this.loading = false;
            if (error.data) {
                const errorMessage = error.data.message;
                commonStore.showErrorMessage(errorMessage);
            } else {
                commonStore.showErrorMessage("something went wrong");
            }
        }
    };
}

decorate(HabitRecordStore, {
    dataArray: observable,
    searchQuery: observable,
    startDate: observable,
    endDate: observable,
    currentPage: observable,
    totalCount: observable,
    loading: observable,
    type: observable,
    isAchieved: observable,
});

export default new HabitRecordStore();
