import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetPages(search, isActive, offset, limit) {
  try {
    let getPages = await axios.Get({
      url: `${adminUrl}/pages?search=${search || ''}&offset=${offset || ''}&limit=${limit || ''}&isDeleted=false&isActive=${isActive || ''}`
    });

    if (getPages.statusCode !== 200) {
      return { error: getPages.message };
    }

    return { data: getPages.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetPageById(pageId) {
  try {
    let getPageById = await axios.Get({
      url: `${adminUrl}/pages/${pageId}`
    });

    if (getPageById.statusCode !== 200) {
      return { error: getPageById.message };
    }

    return { data: getPageById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function CreatePage(editedData) {
  try {
    let createPage = await axios.Post({
      url: `${adminUrl}/pages`,
      data: editedData,
    });

    if (createPage.statusCode !== 200) {
      return { error: createPage.message };
    }

    return { data: createPage.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function UpdatePage(editedData) {
  try {
    let updatePage = await axios.Put({
      url: `${adminUrl}/pages/${editedData.id}`,
      data: editedData,
    });

    if (updatePage.statusCode !== 200) {
      return { error: updatePage.message };
    }

    return { data: updatePage.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function DeletePage(pageId) {
  try {
    let deletePage = await axios.Put({
      url: `${adminUrl}/pages/${pageId}/delete`,
    });

    if (deletePage.statusCode !== 200) {
      return { error: deletePage.message };
    }

    return { data: deletePage.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
