import { observable, action, decorate } from "mobx";
import commonStore from "./CommonStore";
import moment from "moment";
import {
  GetAllUserDoctor,
  GetUserDoctorById
} from "../services/UserDoctorService";

class UserDoctorStore {
  dataArray = [];
  editedData = {};
  pageSize = 10;
  currentPage = 0;
  totalCount = 0;
  startDate = "";
  endDate = "";
  searchQuery = "";
  loading = false;
  // hospitalValue
  columns = [
    { name: "doctorName", title: "Doctor Name" },
    { name: "startDate", title: "Start Date" },
    { name: "endDate", title: "End Date" }
  ];
  columnWidths = [
    { columnName: "doctorName", width: 250 },
    { columnName: "startDate", width: 250 },
    { columnName: "endDate", width: 250 }
  ];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setStartDate = value => {
    this.currentPage = 0;
    this.startDate = value;
  };

  setEndDate = value => {
    this.currentPage = 0;
    this.endDate = value;
  };

  setSearchQuery = searchValue => {
    this.currentPage = 0;
    this.searchQuery = searchValue;
  };

  setCurrentPage = currentPage => {
    this.currentPage = currentPage;
    this.getAllUserDoctor();
  };

  getAllUserDoctor = async id => {
    this.loading = true;
    let { data, error } = await GetAllUserDoctor(
      id,
      this.startDate ? moment(this.startDate).format("YYYY-MM-DDThh:mm") : "",
      this.endDate ? moment(this.endDate).format("YYYY-MM-DDThh:mm") : "",
      this.searchQuery,
      this.currentPage,
      this.pageSize
    );
    if (data) {
      this.loading = false;
      this.dataArray = data.userDoctor.map(data => {
        const doctorName =
          data.doctor && data.doctor.name ? data.doctor.name : "";
        const startDate = moment(data.startDate ? data.startDate : null).format(
          "DD MMM YYYY hh:mm:ss"
        );
        const endDate = data.endDate
          ? moment(data.endDate).format("DD MMM YYYY hh:mm:ss")
          : "-";

        return {
          ...data,
          doctorName,
          startDate,
          endDate
        };
      });
      this.totalCount = data.count;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getUserDoctorById = async id => {
    let { data, error } = await GetUserDoctorById(id);

    if (data) {
      this.setEditedData({
        pageTitle: "Detail Doctor",
        doctorId: data.doctorDoctorID,
        startDate: moment(data.startDate).format("DD MMM YYYY hh:mm:ss"),
        endDate: moment(data.endDate).format("DD MMM YYYY hh:mm:ss")
          ? data.endDate
          : "-"
      });
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };
}

decorate(UserDoctorStore, {
  //OBSERVABLE
  editedData: observable,
  dataArray: observable,
  totalCount: observable,
  searchQuery: observable,
  startDate: observable,
  endDate: observable,
  loading: observable,

  //ACTION
  setEditedData: action

  //COMPUTED
});
export default new UserDoctorStore();
