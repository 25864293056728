import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetDashboardInfo() {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/dashboard`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
