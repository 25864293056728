import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function getGlobalSettings(search, isActive, offset, limit) {
  try {
    const response = await axios.Get({
      url: `${adminUrl}/global-settings?search=${search || ''}&offset=${offset || ''}&limit=${limit || ''}&isActive=${isActive || ''}&isDeleted=false`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function getGlobalSettingById(globalSettingId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/global-settings/${globalSettingId}`,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function createGlobalSetting(editedData) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/global-settings/`,
      data: {
        ...editedData,
        version: editedData.version.toString(),
      },
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function updateGlobalSettings(editedData) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/global-settings/${editedData.id}`,
      data: {
        ...editedData,
        version: editedData.version.toString(),
      },
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}