import {
  GetAllDoctor,
  GetByIdDoctor,
  PostDoctor,
  PutUpdateDoctor,
  PutChangePasswordDoctor,
  PutDeleteDoctor
} from "../services/DoctorService";
import { observable, action, decorate } from "mobx";
import swal from "sweetalert2";
import commonStore from "./CommonStore";

class DoctorStore {
  editedData = {
    doctorArray: [],
    activeTab: new Array(4).fill("1"),
    tabTitle: ["", "/profile", "/patients"],
    specialistTemp: ["Bedah", "Klinik"],
    columns: [
      {
        name: "doctorID",
        title: "Doctor ID"
      },
      {
        name: "name",
        title: "Name"
      },
      {
        name: "email",
        title: "Email"
      }
    ],
    columnWidths: [
      { columnName: "doctorID", width: 200 },
      { columnName: "name", width: 200 },
      { columnName: "email", width: 200 }
    ],
    disabledColumn: [
      { columnName: "name", sortingEnabled: false },
      { columnName: "email", sortingEnabled: false }
    ],
    sorting: [{ columnName: "doctorID", direction: "asc" }],
    // sorting: [{ columnName: "doctorID" }]
    imageUrl: ""
  };
  currentPage = 0;
  pageSize = 10;
  searchQuery = "";
  loading = false;

  setSearchQuery = searchValue => {
    this.currentPage = 0;
    this.searchQuery = searchValue;
  };

  setSorting = updatedSorting => {
    this.sorting = updatedSorting;
  };

  resetCurrentPage = () => {
    this.currentPage = 0;
  };

  setLoading = loading => {
    this.loading = loading;
  };

  setSorting = sorting => {
    this.editedData.sorting = sorting;
  };

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setTypeFilterValue = value => {
    this.typeFilterValue = value;
  };

  setCurrentPage = page => {
    this.currentPage = page;
    this.getAllDoctor();
  };

  setSearchValue = search => {
    this.currentPage = 0;
    this.searchValue = search;
  };

  getAllDoctor = async () => {
    this.loading = true;

    let { data, error } = await GetAllDoctor({
      offset: this.currentPage,
      limit: this.pageSize,
      search: this.searchQuery,
      order: this.editedData.sorting[0].direction
    });

    if (data) {
      this.totalCount = data.count;
      this.editedData.doctorArray = data.doctor.map(row => {
        const doctorID = row.doctorID ? Number(row.doctorID) : "";
        return {
          ...row,
          doctorID
        };
      });
      this.loading = false;
      this.editedData.totalCount = data.count;
    } else {
      this.loading = false;

      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };

  getByIDDoctor = async id => {
    let { data, error } = await GetByIdDoctor(id);

    if (data) {
      if (data.imageUrl === "") {
        data.imageUrl = null;
      }
      this.setEditedData({
        name: data.name,
        pageTitle: data.name,
        tempImgUrl: data.imageUrl,
        specialist: data.specialist,
        email: data.email,
        doctorID: data.doctorID
      });
      return { data };
    } else {
      this.loading = false;

      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };

  postDoctor = async (name, imageUrl, specialist, email, password) => {
    this.loading = true;

    if (imageUrl === null) {
      imageUrl = "";
    }

    let { data, error } = await PostDoctor(
      name,
      imageUrl,
      specialist,
      email,
      password
    );

    if (data) {
      this.loading = false;
      swal({
        title: "Success",
        text: "Doctor Success Added",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = "/doctors";
        return { data };
      });
    } else {
      this.loading = false;

      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };
  putUpdateDoctor = async (name, imageUrl, specialist, email, password, id) => {
    this.loading = true;
    if (imageUrl === null) {
      imageUrl = "";
    }
    let { data, error } = await PutUpdateDoctor(
      name,
      imageUrl,
      specialist,
      email,
      password,
      id
    );
    this.loading = false;
    if (data) {
      swal({
        title: "Success",
        text: "Doctor Success Updated",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = "/doctors";
        return { data };
      });
    } else {
      this.loading = false;

      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };

  putChangePasswordDoctor = async (newPassword, confirmPassword, id) => {
    this.loading = true;
    let { data, error } = await PutChangePasswordDoctor(
      newPassword,
      confirmPassword,
      id
    );
    if (data) {
      this.loading = false;
      swal({
        title: "Success",
        text: "Doctor's Password Success Updated",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = "/doctors";
        return { data };
      });
    } else {
      this.loading = false;

      commonStore.showErrorMessage(error);
      return { error };
    }
  };

  putDeleteDoctor = async id => {
    this.loading = true;

    let { data, error } = await PutDeleteDoctor(id);
    this.loading = false;
    if (data) {
      swal({
        title: "Success",
        text: "Doctor Success Deleted",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = "/doctors";
        return { data };
      });
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };
}

decorate(DoctorStore, {
  editedData: observable,
  postDoctor: action,
  setEditedData: action,
  dataArrayDoctor: observable,
  loading: observable,
  currentPage: observable,
  pageSize: observable,
  totalCount: observable,
  typeFilterEunum: observable,
  typeFilterValue: observable,
  searchQuery: observable,

  setSearchQuery: action
});

export default new DoctorStore();
