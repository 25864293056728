import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetGameScore(gameListId, search, startDate, endDate, offset, limit) {
  try {
    let getGameScore = await axios.Get({
      url: `${adminUrl}/scores?gameListId=${gameListId || ''}&search=${search || ''}&startDate=${startDate || ''}&endDate=${endDate || ''}&offset=${offset || ''}&limit=${limit || ''}&isDeleted=false`
    });

    if (getGameScore.statusCode !== 200) {
      return { error: getGameScore.message };
    }

    return { data: getGameScore.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
