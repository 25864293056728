import {
  GetAllPrescriptions,
  GetPrescriptionsById,
  PutDeletePrescription
} from "../services/PrescriptionsService";
import { observable, decorate, action } from "mobx";
import swal from "sweetalert2";
import moment from "moment";
import commonStore from "./CommonStore";

class PrescriptionsStore {
  editedData = {};
  dataArray = [];
  searchQuery = "";
  startDate = "";
  endDate = "";
  pageSize = 10;
  currentPage = 0;
  totalCount = 0;
  loading = false;
  columns = [
    { name: "prescriptionID", title: "Prescription ID" },
    { name: "medicineName", title: "Medicine Name" },
    { name: "dosage", title: "Dosage (mg)" },
    { name: "doctorName", title: "Assign by" },
    { name: "dailyFrequency", title: "Frequence" },
    { name: "createdAt", title: "Start Date" },
    { name: "endDate", title: "End Date" }
  ];
  columnWidths = [
    { columnName: "prescriptionID", width: 150 },
    { columnName: "medicineName", width: 150 },
    { columnName: "dosage", width: 150 },
    { columnName: "doctorName", width: 150 },
    { columnName: "dailyFrequency", width: 150 },
    { columnName: "createdAt", width: 150 },
    { columnName: "endDate", width: 150 }
  ];
  disabledColumn = [
    { columnName: "medicineName", sortingEnabled: false },
    { columnName: "dosage", sortingEnabled: false },
    { columnName: "doctorName", sortingEnabled: false },
    { columnName: "dailyFrequency", sortingEnabled: false },
    { columnName: "createdAt", sortingEnabled: false },
    { columnName: "endDate", sortingEnabled: false }
  ];
  medicationTimeArray = [];
  sorting = [{ columnName: "prescriptionID", direction: "asc" }];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setStartDate = value => {
    this.currentPage = 0;
    this.startDate = value;
  };

  setSorting = updatedSorting => {
    // if (this.order === "DESC") {
    //   this.order = "ASC";
    // } else {
    //   this.order = "DESC";
    // }
    this.sorting = updatedSorting;
  };

  setEndDate = value => {
    this.currentPage = 0;
    this.endDate = value;
  };

  setSearchQuery = searchValue => {
    this.currentPage = 0;
    this.searchQuery = searchValue;
  };

  setCurrentPage = currentPage => {
    this.currentPage = currentPage;
    this.getAllPrescriptions();
  };

  getAllPrescriptions = async id => {
    this.authLoading = true;
    this.loading = true;
    let { data, error } = await GetAllPrescriptions(
      this.editedData.userId,
      this.startDate ? moment(this.startDate).format("YYYY-MM-DDThh:mm") : "",
      this.endDate ? moment(this.endDate).format("YYYY-MM-DDThh:mm") : "",
      this.searchQuery,
      this.currentPage,
      this.pageSize,
      this.sorting[0].direction
    );

    if (data) {
      this.loading = false;
      this.totalCount = data.count;
      this.dataArray = data.prescriptions.map(data => {
        const prescriptionID = data.prescriptionID
          ? Number(data.prescriptionID)
          : "";
        const medicineName =
          data.medicine && data.medicine.name ? data.medicine.name : "";
        const medicineId = data.medicineMedicineID
          ? data.medicineMedicineID
          : "";
        const dosage = data.dosage ? data.dosage : "";
        const doctorName =
          data.doctor && data.doctor.name ? data.doctor.name : "";
        const doctorId = data.doctorDoctorID ? data.doctorDoctorID : "";
        const dailyFrequency = data.dailyFrequency ? data.dailyFrequency : "";
        const createdAt = moment(data.createdAt ? data.createdAt : "").format(
          "DD MMM YYYY hh:mm:ss"
        );
        const endDate = moment(data.endDate ? data.endDate : "").format(
          "DD MMM YYYY hh:mm:ss"
        );

        return {
          ...data,
          prescriptionID,
          medicineName,
          medicineId,
          dosage,
          doctorName,
          doctorId,
          dailyFrequency,
          createdAt,
          endDate
        };
      });
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };
  getPrescriptionsById = async id => {
    this.authLoading = true;

    let { data, error } = await GetPrescriptionsById(id);

    if (data) {
      this.setEditedData({
        prescriptionID: data.prescriptionID,
        medicineId: data.medicineMedicineID,
        doctorId: data.doctorDoctorID,
        dosage: data.dosage,
        assignDate: moment(data.createdAt).format("DD MMM YYYY hh:mm:ss"),
        endDate: moment(data.endDate).format("DD MMM YYYY hh:mm:ss"),
        servingSize: data.servingSize,
        times: data.dailyFrequency,
        notes: data.notes,
        medicationTime: JSON.parse(data.medicationTime),
        userId: data.userUserID
      });
      this.medicationTimeArray = JSON.parse(data.medicationTime.split(","));

      // const tempArray = [];
      // for (let i = 0; i < data.dailyFrequency; i++) {
      //   this.editedData.num = i;
      // tempArray.push(data.dailyFrequency);
      // }
      // this.editedData.temp = tempArray;
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };
  putDeletePrescription = async (id, userId) => {
    this.authLoading = true;

    let { data, error } = await PutDeletePrescription(id);
    this.authLoading = false;
    if (data) {
      swal({
        title: "Success",
        text: "Prescription Success Deleted",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = "/patients/" + userId + "/healthtracker";
        return { data };
      });
    } else {
      return { error };
    }
  };
}

decorate(PrescriptionsStore, {
  editedData: observable,
  dataArray: observable,
  medicationTimeArray: observable,
  searchQuery: observable,
  startDate: observable,
  endDate: observable,
  currentPage: observable,
  totalCount: observable,
  loading: observable,
  sorting: observable,
  getAllPrescriptions: action,
  getPrescriptionsById: action
});

export default new PrescriptionsStore();
