import React from "react";
import { 
  GetDrugReminders,
  CreateDrugRemindersById,
  DeleteDrugRemindersById,
  GetDrugRemindersById,
  UpdateDrugRemindersById,
} from "../services/DrugReminderService";
import { observable, decorate } from "mobx";
import commonStore from "./CommonStore";
import swal from "sweetalert2";
import dayjs from 'dayjs';
import { validateEditedData } from "../helper/functions";

const Joi = require("joi-browser");
Joi.objectId = require("joi-objectid")(Joi);

class DrugReminderStore {
  editedData = {};
  dataArray = [];
  isLoading = false;
  currentPage = 0;
  pageSize = 10;
  totalCount = 0;
  valueTransformer = {
    isActive: (data) => {
      if (data.isActive === true) {
        return <span style={{ color: "#68B983" }}>Active</span>;
      } else if (data.isActive === false) {
        return <span style={{ color: "#E21D35" }}>Inactive</span>;
      }
    },
    days: (data) => {
      if (data.days > 1) {
        return <span>{data.days} Days</span>;
      } else {
        return <span>{data.days} Day</span>;
      }
    },
  };
  columns = [
    {
      name: "days",
      title: "Days Before Medication End",
      valueTransformer: this.valueTransformer["days"],
    },
    {
      name: "reminderTime",
      title: "Reminder Time",
    },
    {
      name: "isActive",
      title: "Status",
      valueTransformer: this.valueTransformer["isActive"],
    },
  ];
  columnWidths = [
    {
      columnName: "days",
      width: 200,
    },
    {
      columnName: "reminderTime",
      width: 200,
    },
    {
      columnName: "isActive",
      width: 200,
    },
  ];

  timeEnum = [
    { name: "01:00", value: "01:00:00" },
    { name: "02:00", value: "02:00:00" },
    { name: "03:00", value: "03:00:00" },
    { name: "04:00", value: "04:00:00" },
    { name: "05:00", value: "05:00:00" },
    { name: "06:00", value: "06:00:00" },
    { name: "07:00", value: "07:00:00" },
    { name: "08:00", value: "08:00:00" },
    { name: "09:00", value: "09:00:00" },
    { name: "10:00", value: "10:00:00" },
    { name: "11:00", value: "11:00:00" },
    { name: "12:00", value: "12:00:00" },
    { name: "13:00", value: "13:00:00" },
    { name: "14:00", value: "14:00:00" },
    { name: "15:00", value: "15:00:00" },
    { name: "16:00", value: "16:00:00" },
    { name: "17:00", value: "17:00:00" },
    { name: "19:00", value: "19:00:00" },
    { name: "20:00", value: "20:00:00" },
    { name: "21:00", value: "21:00:00" },
    { name: "22:00", value: "22:00:00" },
    { name: "23:00", value: "23:00:00" },
    { name: "24:00", value: "24:00:00" },
  ]

  statusEnum = [
    { name: <span style={{ color: "#68B983" }}>Active</span>, value: true },
    { name: <span style={{ color: "#E21D35" }}>Inactive</span>, value: false },
  ]

  daysEnum = () => {
    const nums = [];
    for (let i = 1; i < 8; i++) {
      nums.push({ name: `${i} days`, value: i })
    }

    return nums;
  }

  initialValue = () => {
    this.setEditedData({
      days: 0,
      isActive: false,
      reminderTime: null,
      textEn: "",
      textId: "",
      titleEn: "",
      titleId: "",
    })
  }

  setEditedData = (updatedData) => {
    this.editedData = {
      ...this.editedData,
      ...updatedData,
    };
  };

  setCurrentPage = (currentPage) => {
    this.currentPage = currentPage;
    this.getDrugReminders();
  };

  getDrugReminders = async () => {
    this.isLoading = true;

    let { data, error } = await GetDrugReminders({
      offset: this.currentPage,
      limit: this.pageSize,
    });

    if (data) {
      this.isLoading = false;

      this.totalCount = data.count;
      this.dataArray = data.drugReminder;
    } else if (error) {
      this.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  getDrugRemindersById = async (id) => {
    this.isLoading = true;

    let { data, error } = await GetDrugRemindersById(id);

    if (data) {
      this.isLoading = false;
      this.setEditedData({ 
        id: data.id,
        reminderTime: dayjs(data.reminderTime, "HH:mm"),
        days: Number(data.days),
        isActive: data.isActive,
        textEn: data.textEn,
        textId: data.textId,
        titleEn: data.titleEn,
        titleId: data.titleId
      })
    } else if (error) {
      this.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  updateDrugReminders = async history => {
    this.isLoading = true;

    let { data, error } = await UpdateDrugRemindersById(
      {
        ...this.editedData, 
        reminderTime: dayjs(this.editedData.reminderTime).format("HH:mm")
      }
    );

    if (data) {
      this.isLoading = false;
      swal({
        title: "Success",
        text: "Reminder Successfully Updated",
        icon: "success",
        button: "OK",
      }).then((value) => {
        history.push("/drug-reminder");
      });
    } else if (error) {
      this.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  createDrugReminders = async history => {
    this.isLoading = true;

    let { data, error } = await CreateDrugRemindersById(
      {
        ...this.editedData, 
        reminderTime: dayjs(this.editedData.reminderTime).format("HH:mm")
      }
    );

    if (data) {
      this.isLoading = false;
      swal({
        title: "Success",
        text: "Reminder Successfully Created",
        icon: "success",
        button: "OK",
      }).then((value) => {
        history.push("/drug-reminder");
      });
    } else if (error) {
      this.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  deleteDrugReminder = async ({ id, history }) => {
    this.loading = true;

    let { data, error } = await DeleteDrugRemindersById(id);
    
    if (data) {
      swal({
        title: "Success",
        text: "Reminder Successfully deleted",
        icon: "success",
        button: "OK",
      }).then((value) => {
        history.push("/drug-reminder");
      });

    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  }

  get joiFormErrors() {
    if (this.editedData) {
      return validateEditedData({
        editedData: this.editedData,
        joiSchema: {
          days: Joi.number()
            .label('Days Before Medication End')
            .required(),
          reminderTime: Joi.object()
            .empty(null)
            .label('ReminderTime')
            .required(),
          isActive: Joi.boolean()
            .label('Status')
            .required(),
          titleEn: Joi.string()
            .empty(null)
            .label('English Wording Title')
            .required(),
          titleId: Joi.string()
            .empty(null)
            .label('Indonesian Wording Title')
            .required(),
          textEn: Joi.string()
            .empty(null)
            .label('English Wording')
            .required(),
          textId: Joi.string()
            .empty(null)
            .label('Indonesian Wording')
            .required(),
        }
      });
    }
    return { error: true };
  }
}

decorate(DrugReminderStore, {
  editedData: observable,
  dataArray: observable,
  isLoading: observable,
  currentPage: observable,
});

export default new DrugReminderStore();
