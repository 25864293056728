import authStore from "./AuthStore";
import commonStore from "./CommonStore";
import adminStore from "./AdminStore";
import exerciseStore from "./ExerciseStore";
import symptomsStore from "./SymptomsStore";
import medicineStore from "./MedicineStore";
import mealStore from "./MealStore";
import dietPlanStore from "./DietPlanStore";
import usersStore from "./UsersStore";
import doctorStore from "./DoctorStore";
import patientStore from "./PatientStore";
import diabeticExpertStore from "./DiabeticExpertStore";
import bloodGlucoseStore from "./BloodGlucoseStore";
import bodyMeasurementStore from "./BodyMeasurementStore";
import weightStore from "./WeightStore";
import educationStore from "./EducationStore";
import dietRecordStore from "./DietRecordStore";
import exerciseRecordStore from "./ExerciseRecordStore";
import medicationRecordStore from "./MedicationRecordStore";
import symptomsRecordsStore from "./SymptomsRecordStore";
import prescriptionsStore from "./PrescriptionsStore";
import cloudinaryStore from "./CloudinaryStore";
import deleteWarningStore from "./DeleteWarningStore";
import hospitalStore from "./HospitalStore";
import areaListStore from "./AreaListStore";
import hospitalListStore from "./HospitalListStore";
import specialistListStore from "./SpecialistListStore";
import doctorListStore from "./DoctorListStore";
import appointmentStore from "./AppointmentStore";
import broadcastStore from "./BroadcastStore";
import oneSignalStore from "./OneSignalStore";
import changePasswordWidgetStore from "./ChangePasswordWidgetStore";
import viewImageWidgetStore from "./ViewImageWidgetStore";
import userDoctorStore from "./UserDoctorStore";
import dashboardStore from "./DashboardStore";
import bannerStore from "./BannerStore";
import siloamDoctorStore from "./SiloamDoctorStore";
import globalSettingStore from "./GlobalSettingStore";
import interestStore from "./InterestStore";
import stepStore from "./StepStore";
import habitRecordStore from "./HabitRecordStore";
import habitStore from "./HabitStore";
import miniGameStore from "./MiniGameStore";
import miniGameScoreStore from "./MiniGameScoreStore";
import healthDeclarationFormStore from "./HealthDeclarationFormStore";
import userFormStore from "./UserFormStore";
import formQuestionStore from "./FormQuestionStore";
import formScoringStore from "./FormScoringStore";
import exportToCSVStore from "./ExportToCSVStore";
import pageStore from "./PageStore";
import pageManagementStore from "./PageManagementStore";
import maintenanceStore from "./MaintenanceStore";
import gtnStore from "./GTNStore";
import uniqueCodeStore from "./UniqueCodeStore";
import registFormStore from "./RegistFormStore";
import bloodGlucoseAbnormalitiesStore from "./BloodGlucoseAbnormalitiesStore";
import bloodGlucoseTargetLimitStore from "./BloodGlucoseTargetLimitStore";
import bloodGlucoseTargetDefaultValueStore from "./BloodGlucoseTargetDefaultValueStore";
import bloodGlucoseAlertManagementStore from "./BloodGlucoseAlertManagementStore";
import drugReminderStore from "./DrugReminderStore";
import inputLimitStore from './InputLimitStore';

export default {
  authStore,
  commonStore,
  adminStore,
  exerciseStore,
  symptomsStore,
  medicineStore,
  mealStore,
  dietPlanStore,
  usersStore,
  doctorStore,
  patientStore,
  diabeticExpertStore,
  bloodGlucoseStore,
  weightStore,
  educationStore,
  dietRecordStore,
  exerciseRecordStore,
  medicationRecordStore,
  symptomsRecordsStore,
  prescriptionsStore,
  cloudinaryStore,
  hospitalStore,
  deleteWarningStore,
  changePasswordWidgetStore,
  areaListStore,
  hospitalListStore,
  specialistListStore,
  doctorListStore,
  appointmentStore,
  broadcastStore,
  oneSignalStore,
  viewImageWidgetStore,
  userDoctorStore,
  dashboardStore,
  bannerStore,
  siloamDoctorStore,
  globalSettingStore,
  bodyMeasurementStore,
  interestStore,
  stepStore,
  habitRecordStore,
  habitStore,
  miniGameStore,
  miniGameScoreStore,
  healthDeclarationFormStore,
  userFormStore,
  formQuestionStore,
  formScoringStore,
  exportToCSVStore,
  pageStore,
  pageManagementStore,
  maintenanceStore,
  gtnStore,
  uniqueCodeStore,
  registFormStore,
  bloodGlucoseAbnormalitiesStore,
  bloodGlucoseTargetLimitStore,
  bloodGlucoseTargetDefaultValueStore,
  bloodGlucoseAlertManagementStore,
  drugReminderStore,
  inputLimitStore
};
