import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetPageManagements(search, isActive, offset, limit) {
  try {
    let getPageManagements = await axios.Get({
      url: `${adminUrl}/page-managements?search=${
        search || ""
      }&isDeleted=false&isActive=${isActive || ""}`,
    });

    if (getPageManagements.statusCode !== 200) {
      return { error: getPageManagements.message };
    }

    return { data: getPageManagements.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetPageManagementNews(
  search,
  isActive,
  device,
  offset,
  limit
) {
  try {
    let getPageManagementNews = await axios.Get({
      url: `${adminUrl}/page-managements-new?search=${
        search || ""
      }&isDeleted=false&isActive=${isActive || ""}&deviceType=${
        device || ""
      }&order=orderNumber,ASC`,
    });

    if (getPageManagementNews.statusCode !== 200) {
      return { error: getPageManagementNews.message };
    }

    return { data: getPageManagementNews.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetPageManagementById(pageManagementId) {
  try {
    let getPageManagementById = await axios.Get({
      url: `${adminUrl}/page-managements/${pageManagementId}`,
    });

    if (getPageManagementById.statusCode !== 200) {
      return { error: getPageManagementById.message };
    }

    return { data: getPageManagementById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetPageManagementNewsById(pageManagementId) {
  try {
    let getPageManagementNewsById = await axios.Get({
      url: `${adminUrl}/page-managements-new/${pageManagementId}`,
    });

    if (getPageManagementNewsById.statusCode !== 200) {
      return { error: getPageManagementNewsById.message };
    }

    return { data: getPageManagementNewsById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function CreatePageManagement(editedData) {
  try {
    let createPageManagement = await axios.Post({
      url: `${adminUrl}/page-managements`,
      data: editedData,
    });

    if (createPageManagement.statusCode !== 200) {
      return { error: createPageManagement.message };
    }

    return { data: createPageManagement.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function UpdatePageManagement(editedData) {
  try {
    let updatePageManagement = await axios.Put({
      url: `${adminUrl}/page-managements/${editedData.id}`,
      data: editedData,
    });

    if (updatePageManagement.statusCode !== 200) {
      return { error: updatePageManagement.message };
    }

    return { data: updatePageManagement.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function UpdatePageManagementNews(editedData) {
  try {
    let updatePageManagementNews = await axios.Put({
      url: `${adminUrl}/page-managements-new/${editedData.id}`,
      data: editedData,
    });

    if (updatePageManagementNews.statusCode !== 200) {
      return { error: updatePageManagementNews.message };
    }

    return { data: updatePageManagementNews.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function UpdatePageManagementIsActive(pageManagementId) {
  try {
    let updatePageManagementIsActive = await axios.Put({
      url: `${adminUrl}/page-managements/${pageManagementId}/is-active`,
    });

    if (updatePageManagementIsActive.statusCode !== 200) {
      return { error: updatePageManagementIsActive.message };
    }

    return { data: updatePageManagementIsActive.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function DeletePageManagement(pageManagementId) {
  try {
    let deletePageManagement = await axios.Put({
      url: `${adminUrl}/page-managements/${pageManagementId}/delete`,
    });

    if (deletePageManagement.statusCode !== 200) {
      return { error: deletePageManagement.message };
    }

    return { data: deletePageManagement.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
