import {
  GetAllBroadcast,
  GetByIdBroadcast,
  PostBroadcast,
  DeleteByIDBroadcast
} from "../services/BroadcastService";
import { observable, action, decorate } from "mobx";
import commonStore from "./CommonStore";
import moment from "moment";
import React from "react";

class BroadcastStore {
  //OBSERVABLE
  dataArray = [];
  editedData = {
    isLoading: false,
    currentPage: 0,
    pageSize: 10,
    totalCount: 0,
    startDate: "",
    endDate: "",
    broadcastStatusValue: "",
    searchQuery: "",
    title: "",
    shortDescription: "",
    description: "",
    date: "",
    imageUrl: "",
    recipient: ""
  };

  valueTransformer = {
    date: data => {
      let nowDate = new Date();
      let tempDate = new Date(data.date);
      if (nowDate >= tempDate) {
        return <span className="active-span">POSTED</span>;
      } else {
        return <span className="pending-span">PENDING</span>;
      }
    }
  };

  columnWidths = [
    { columnName: "date", width: 200 },
    { columnName: "title", width: 300 },
    { columnName: "recipient", width: 200 },
    { columnName: "status", width: 200 }
  ];

  columns = [
    {
      name: "date", //yyyy-mm-dd
      title: "Date"
    },
    { name: "title", title: "Title" },
    {
      name: "recipient",
      title: "Recipient"
    },
    {
      name: "status",
      title: "Status",
      valueTransformer: this.valueTransformer["date"]
    }
  ];

  broadcastStatusEnum = [
    { value: "all", name: "All" },
    { value: "posted", name: "Posted" },
    { value: "pending", name: "Pending" }
  ];

  recipientEnum = [
    { value: "Doctor", label: "Doctor" },
    { value: "Patient Free", label: "Patient Free" },
    { value: "Patient Premium", label: "Patient Premium" },
    { value: "Diabetic Expert", label: "Diabetic Expert" }
  ];

  //ACTION
  get reactSelectRecipientOptions() {
    return this.recipientEnum.map(data => {
      return {
        label: `${data.label}`,
        value: data
      };
    });
  }

  clearEditedData = () => {
    this.editedData = {};
  };

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setCurrentPage = currentPage => {
    this.editedData.currentPage = currentPage;
    this.getAllBroadcast();
  };

  validateDate = () => {
    if (this.editedData.endDate !== "") {
      if (this.editedData.startDate > this.editedData.endDate) {
        commonStore.showErrorMessage("End Date must be after Start Date!");
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  validateStatus = () => {
    const date = moment(this.editedData.date).format("YYYY-MM-DD");
    const nowDate = moment(new Date()).format("YYYY-MM-DD");

    if (nowDate >= date) {
      return true;
    } else {
      return false;
    }
  };

  populateData = data => {
    this.editedData.isLoading = false;

    this.dataArray = data.notification.map(data => {
      return {
        ...data,
        date: data.date ? moment(data.date).format("D MMM YYYY") : "-",
        title: data.title ? data.title : "-",
        recipient: data.recipient ? data.recipient : "-"
      };
    });
    this.editedData.totalCount = data.count;
  };

  getAllBroadcast = async () => {
    this.editedData.isLoading = true;

    if (this.validateDate() === true) {
      let isPosted = this.editedData.broadcastStatusValue;
      if (this.editedData.broadcastStatusValue === "all") isPosted = "";
      else if (this.editedData.broadcastStatusValue === true) isPosted = "true";
      else if (this.editedData.broadcastStatusValue === false)
        isPosted = "false";

      const { data, error } = await GetAllBroadcast({
        offset: String(this.editedData.currentPage),
        limit: String(this.editedData.pageSize),
        filter: isPosted,
        startDate: this.editedData.startDate
          ? moment(this.editedData.startDate).format("YYYY-MM-DDThh:mm")
          : "",
        endDate: this.editedData.endDate
          ? moment(this.editedData.endDate).format("YYYY-MM-DDThh:mm")
          : ""
      });

      if (data) {
        this.editedData.isLoading = false;

        this.populateData(data);
      } else if (error) {
        this.editedData.isLoading = false;

        if (error.data) {
          commonStore.showErrorMessage(error.data.message);
        } else {
          commonStore.showErrorMessage("Something went wrong.");
        }
      }
    }
  };

  getByIDBroadcast = async () => {
    let { data, error } = await GetByIdBroadcast({
      id: String(this.editedData.id)
    });

    if (data) {
      this.setEditedData({
        title: String(data.title),
        shortDescription: String(data.shortDescription),
        description: String(data.description),
        date: moment(data.date).format("YYYY-MM-DD hh:mm:ss"),
        imageUrl: String(data.imageUrl),
        recipient: data.recipient
      });

      return { data };
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  validateEmptyField() {
    if (
      this.editedData.title === "" ||
      this.editedData.shortDescription === "" ||
      this.editedData.description === "" ||
      this.editedData.date === "" ||
      this.editedData.imageUrl === ""
    ) {
      commonStore.showErrorMessage("All fields must be filled!");
      return false;
    } else if (this.editedData.shortDescription.length > 150) {
      commonStore.showErrorMessage("Short Description too long!");
      return false;
    } else return true;
  }

  postBroadcast = async () => {
    let tempArray = this.editedData.recipient;
    let tempRecipient = "";
    for (let i = 0; i < tempArray.length; i++) {
      if (i !== tempArray.length - 1) {
        tempRecipient += tempArray[i].value.value + ", ";
      } else {
        tempRecipient += tempArray[i].value.value;
      }
    }

    const newDate = new Date(this.editedData.date).toISOString();

    let { data, error } = await PostBroadcast({
      title: String(this.editedData.title),
      shortDescription: String(this.editedData.shortDescription),
      description: String(this.editedData.description),
      date: moment(newDate).format("YYYY-MM-DD HH:mm:ss"),
      recipient: tempRecipient,
      imageUrl: String(this.editedData.imageUrl)
    });

    if (data) {
      commonStore.showSuccessMessage("Broadcast added successfully!");
      setTimeout(() => {
        window.location.href = "/notifications";
      }, 500);
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  deleteByIDBroadcast = async () => {
    let { data, error } = await DeleteByIDBroadcast({
      id: String(this.editedData.id)
    });

    if (data) {
      commonStore.showSuccessMessage("Broadcast deleted successfully!");
      setTimeout(() => {
        window.location.href = "/notifications";
      }, 500);
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };
}

decorate(BroadcastStore, {
  //observable
  editedData: observable,
  dataArray: observable,
  recipientEnum: observable,

  //action
  getAllBroadcast: action,
  getByIDBroadcast: action,
  setBroadcastStatusValue: action,
  validateStatus: action,
  validateDate: action
});

export default new BroadcastStore();
