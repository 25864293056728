import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function getFormQuestionByFormId(formId, offset, limit) {
  try {
    let getFormQuestionByFormId = await axios.Get({
      url: `${adminUrl}/form-question-news?formId=${formId || ''}&order=ASC&offset=${offset}&limit=${limit}`
    });

    if (getFormQuestionByFormId.statusCode !== 200) {
      return { error: getFormQuestionByFormId.message };
    }

    return { data: getFormQuestionByFormId.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function getFormQuestionById(formQuestionId) {
  try {
    let getFormQuestionById = await axios.Get({
      url: `${adminUrl}/form-questions/${formQuestionId}`
    });

    if (getFormQuestionById.statusCode !== 200) {
      return { error: getFormQuestionById.message };
    }

    return { data: getFormQuestionById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function createFormQuestion(editedData) {
  try {
    let createFormQuestion = await axios.Post({
      url: `${adminUrl}/form-questions`,
      data: editedData
    });

    if (createFormQuestion.statusCode !== 200) {
      return { error: createFormQuestion.message };
    }

    return { data: createFormQuestion.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function updateFormQuestion(formQuestionId, editedData) {
  try {
    let createFormQuestion = await axios.Put({
      url: `${adminUrl}/form-questions/${formQuestionId}`,
      data: editedData
    });

    if (createFormQuestion.statusCode !== 200) {
      return { error: createFormQuestion.message };
    }

    return { data: createFormQuestion.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function deleteFormQuestionById(formQuestionId) {
  try {
    let deleteFormQuestionById = await axios.Put({
      url: `${adminUrl}/form-questions/${formQuestionId}/delete`
    });

    if (deleteFormQuestionById.statusCode !== 200) {
      return { error: deleteFormQuestionById.message };
    }

    return { data: deleteFormQuestionById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
