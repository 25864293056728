import { observable, decorate } from "mobx";
import {
  GetBloodGlucoseTargetLimits,
  UpdateBloodGlucoseTargetLimit,
} from "../services/BloodGlucoseTargetLimitService";
import commonStore from "./CommonStore";

class BloodGlucoseTargetLimitStore {
  editedData = {};
  loading = false;

  setEditedData = (updatedData) => {
    this.editedData = {
      ...this.editedData,
      ...updatedData,
    };
  };

  getBloodGlucoseTargetLimits = async () => {
    this.loading = true;

    let { data, error } = await GetBloodGlucoseTargetLimits();

    if (data) {
      this.loading = false;

      for (let i = 0; i < data.bloodGlucoseConfig.length; i += 1) {
        const curr = data.bloodGlucoseConfig[i];

        if (curr.bloodType === "bloodGlucoseBeforeBreakfast") {
          this.editedData.bloodGlucoseMinValue = curr.minValue;
          this.editedData.bloodGlucoseMaxValue = curr.maxValue;
        } else if (curr.bloodType === "bloodGlucoseHba1c") {
          this.editedData.hba1cMinValue = curr.minValue;
          this.editedData.hba1cMaxValue = curr.maxValue;
        }
      }
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };

  updateBloodGlucoseTargetLimit = async () => {
    this.loading = true;

    const bloodGlucoseMinValue = this.editedData.bloodGlucoseMinValue;
    const bloodGlucoseMaxValue = this.editedData.bloodGlucoseMaxValue;
    const hba1cMinValue = this.editedData.hba1cMinValue;
    const hba1cMaxValue = this.editedData.hba1cMaxValue;

    let { data, error } = await UpdateBloodGlucoseTargetLimit(
      bloodGlucoseMinValue,
      bloodGlucoseMaxValue,
      hba1cMinValue,
      hba1cMaxValue
    );

    if (data) {
      this.loading = false;
      commonStore.showSuccessMessage("Success");
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };
}

decorate(BloodGlucoseTargetLimitStore, {
  editedData: observable,
});
export default new BloodGlucoseTargetLimitStore();
