import {
  getMaintenance,
  updateMaintenance,
} from "../services/MaintenanceService";
import { observable, decorate } from "mobx";
import commonStore from "./CommonStore";

class MaintenanceStore {
  editedData = {};
  loading = false;
  isMaintenanceEnum = [
    { value: true, name: "Active" },
    { value: false, name: "Inactive" },
  ];

  setEditedData = (updatedData) => {
    this.editedData = {
      ...this.editedData,
      ...updatedData,
    };
  };

  getMaintenance = async () => {
    this.loading = true;

    const { data, error } = await getMaintenance();

    if (data) {
      this.loading = false;
      this.editedData = data;
    } else if (error) {
      this.loading = false;
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  updateMaintenance = async () => {
    this.isLoading = true;

    const { data, error } = await updateMaintenance(this.editedData);

    if (data) {
      this.isLoading = false;
      commonStore.showSuccessMessage("Success updateing maintenance");
    } else if (error) {
      this.editedData.isLoading = false;
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };
}

decorate(MaintenanceStore, {
  editedData: observable,
  searchQuery: observable,
  loading: observable,
});
export default new MaintenanceStore();
