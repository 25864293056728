import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import {
  AppAside,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarNav
} from "@coreui/react";
// sidebar nav config
import navigation from "../../_nav";
import navigationSiloamDoctor from "../../_navSilaom";
// routes config
import routes from "../../routes";

import DefaultAside from "./DefaultAside";
import DefaultHeader from "./DefaultHeader";
import { inject, observer } from "mobx-react";

class DefaultLayout extends Component {
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  componentDidMount = async () => {
    const { authStore } = this.props;
    await authStore.getRole();
  };

  render() {
    const { authStore, commonStore } = this.props;

    if (!authStore.isAuthenticated) {
      commonStore.showErrorMessage("Please login first");
      return <Redirect to="/login" />;
    }

    return (
      <div className="app">
        <AppHeader
          fixed
          style={{ background: "rgb(241, 241, 241)", borderBottom: "0px" }}
        >
          <Suspense fallback={this.loading()}>
            <DefaultHeader history={this.props.history} />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
              <AppSidebarNav
                className="shorten-text"
                navConfig={authStore.currentcRole === "siloamAdmin" ? navigationSiloamDoctor : navigation}
                {...this.props}
                style={{
                  background: "#F1F1F1",
                  zIndex: "9999",
                  paddingBottom: "100px"
                }}
              />
            </Suspense>
            <AppSidebarFooter />
          </AppSidebar>
          <main className="main">
            <Container
              fluid
              style={{ background: "white", paddingTop: 16 }}
              className="h-100"
            >
              <Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => <route.component {...props} />}
                      />
                    ) : null;
                  })}
                  <Redirect from="/" to="/dashboard" />
                </Switch>
              </Suspense>
            </Container>
          </main>
          <AppAside fixed>
            <Suspense fallback={this.loading()}>
              <DefaultAside />
            </Suspense>
          </AppAside>
        </div>
      </div>
    );
  }
}

export default inject("authStore", "commonStore")(observer(DefaultLayout));
