import { observable, action, decorate } from "mobx";
import commonStore from "./CommonStore";
import {
  GetAllDiabeticExpertList,
  GetByIDDiabeticExpert,
  PutUpdateDiabeticExpert,
  PutUpdatePasswordDiabeticExpert,
  CreateDiabeticExpert,
  PutDeleteDiabeticExpert
} from "../services/DiabeticExpertService";

class DiabeticExpertStore {
  //OBSERVABLE
  dataArray = [];

  editedData = {
    isLoading: false,
    currentPage: 0,
    pageSize: 10,
    totalCount: 0,
    role: "diabeticExpert",
    searchQuery: "",
    hospitalValue: "",
    hospitalId: "",
    adminID: "",
    name: "",
    email: ""
  };

  columns = [
    {
      name: "adminID",
      title: "Diabetic Educator ID"
    },
    {
      name: "name",
      title: "Name"
    },
    {
      name: "email",
      title: "Email"
    },
    {
      name: "hospitalName",
      title: "Hospital"
    }
  ];

  columnWidths = [
    {
      columnName: "adminID",
      width: 200
    },
    {
      columnName: "name",
      width: 300
    },
    {
      columnName: "email",
      width: 300
    },
    {
      columnName: "hospitalName",
      width: 300
    }
  ];

  disabledColumn = [
    {
      columnName: "name",
      sortingEnabled: false
    },
    {
      columnName: "email",
      sortingEnabled: false
    },
    {
      columnName: "hospitalName",
      sortingEnabled: false
    }
  ];

  sorting = [{ columnName: "adminID", direction: "asc" }];

  //ACTION
  clearEditedData = () => {
    this.editedData = {};
  };

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setCurrentPage = currentPage => {
    this.editedData.currentPage = currentPage;
    this.getAllDiabeticExpertList();
  };

  setSearchQuery = searchValue => {
    this.editedData.currentPage = 0;
    this.editedData.searchQuery = searchValue;
  };

  setSorting = sorting => {
    this.sorting = sorting;
  };

  getAllDiabeticExpertList = async () => {
    this.editedData.isLoading = true;

    const { data, error } = await GetAllDiabeticExpertList({
      offset: String(this.editedData.currentPage),
      limit: String(this.editedData.pageSize),
      search: String(this.editedData.searchQuery),
      role: String(this.editedData.role),
      hospitalId:
        this.editedData.hospitalValue === ""
          ? ""
          : String(this.editedData.hospitalId),
      order: this.sorting[0].direction
    });

    this.editedData.isLoading = false;

    if (data) {
      this.editedData.totalCount = Number(data.count);
      this.dataArray = data.admin.map(data => {
        return {
          ...data,
          adminID: Number(data.adminID),
          hospitalName:
            data.hospital && data.hospital.name
              ? String(data.hospital.name)
              : "-"
        };
      });
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  getByIDDiabeticExpert = async () => {
    this.editedData.isLoading = true;

    const { data, error } = await GetByIDDiabeticExpert({
      id: String(this.editedData.adminID)
    });

    this.editedData.isLoading = false;

    if (data) {
      this.setEditedData({
        ...data,
        adminID: String(data.adminID),
        name: String(data.name),
        email: String(data.email),
        hospitalId: String(data.hospitalHospitalID)
      });
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  validateEmptyField() {
    if (
      this.editedData.name === "" ||
      this.editedData.email === "" ||
      this.editedData.password === "" ||
      this.editedData.hospitalValue === ""
    ) {
      commonStore.showErrorMessage("All fields must be filled!");
      return false;
    } else return true;
  }

  createDiabeticExpert = async () => {
    this.editedData.isLoading = true;

    if (this.validateEmptyField()) {
      const { data, error } = await CreateDiabeticExpert({
        name: String(this.editedData.name),
        email: String(this.editedData.email),
        password: String(this.editedData.password),
        hospitalId: String(this.editedData.hospitalId),
        role: String(this.editedData.role)
      });

      this.editedData.isLoading = false;

      if (data) {
        commonStore.showSuccessMessage("Diabetic Expert created!");
        setTimeout(() => {
          window.location.href = "/diabetic-experts";
        }, 500);
      } else if (error) {
        if (error.data) {
          commonStore.showErrorMessage(error.data.message);
        } else {
          commonStore.showErrorMessage("Something went wrong.");
        }
      }
    }
  };

  validateEmptyFieldUpdate() {
    if (
      this.editedData.name === "" ||
      this.editedData.email === "" ||
      this.editedData.hospitalValue === ""
    ) {
      commonStore.showErrorMessage("All fields must be filled!");
      return false;
    } else return true;
  }

  putUpdateDiabeticExpert = async () => {
    this.editedData.isLoading = true;

    if (this.validateEmptyFieldUpdate()) {
      const { data, error } = await PutUpdateDiabeticExpert({
        id: String(this.editedData.adminID),
        name: String(this.editedData.name),
        email: String(this.editedData.email),
        hospitalId: String(this.editedData.hospitalId)
      });

      this.editedData.isLoading = false;

      if (data) {
        commonStore.showSuccessMessage("Diabetic Expert data updated!");
        setTimeout(() => {
          window.location.href = "/diabetic-experts";
        }, 500);
      } else if (error) {
        if (error.data) {
          commonStore.showErrorMessage(error.data.message);
        } else {
          commonStore.showErrorMessage("Something went wrong.");
        }
      }
    }
  };

  putUpdatePasswordDiabeticExpert = async ({
    newPassword,
    confirmNewPassword
  }) => {
    this.editedData.isLoading = true;

    const { data, error } = await PutUpdatePasswordDiabeticExpert({
      id: String(this.editedData.adminID),
      newPassword: String(newPassword),
      confirmNewPassword: String(confirmNewPassword)
    });

    this.editedData.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Password updated!");
      setTimeout(() => {
        window.location.href = "/diabetic-experts";
      }, 500);
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  putDeleteDiabeticExpert = async () => {
    this.editedData.isLoading = true;

    const { data, error } = await PutDeleteDiabeticExpert({
      id: String(this.editedData.adminID)
    });

    this.editedData.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Diabetic Expert successfully deleted!");
      setTimeout(() => {
        window.location.href = "/diabetic-experts";
      }, 500);
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  //COMPUTED
}

decorate(DiabeticExpertStore, {
  //OBSERVABLE
  editedData: observable,
  dataArray: observable,
  sorting: observable,

  //ACTION
  setEditedData: action,
  setHospitalValue: action,
  setSearchQuery: action

  //COMPUTED
});

export default new DiabeticExpertStore();
