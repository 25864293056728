import { observable, action, decorate } from "mobx";
import React from "react";
import commonStore from "./CommonStore";
import {
  GetAllDoctorList,
  GetByIDDoctor,
  PutUpdateDoctor
} from "../services/DoctorListService";

class DoctorListStore {
  //OBSERVABLE
  dataArray = [];
  editedData = {
    isLoading: false,
    currentPage: 0,
    pageSize: 10,
    totalCount: 0,
    searchQuery: "",
    isActive: "all",
    is_doctor_appointment: "all"
  };

  valueTransformer = {
    isActive: data => {
      if (data.isActive === true) {
        return <span className="active-span">ACTIVE</span>;
      } else if (data.isActive === false) {
        return <span className="nonactive-span">INACTIVE</span>;
      }
    },
    is_doctor_appointment: data => {
      if (data.is_doctor_appointment === true) {
        return <span>True</span>;
      } else if (data.is_doctor_appointment === false) {
        return <span>False</span>;
      }
    }
  };

  columns = [
    {
      name: "doctor_id",
      title: "Doctor ID"
    },
    {
      name: "doctor_name",
      title: "Doctor Name"
    },
    {
      name: "doctor_specialist",
      title: "Specialist"
    },
    {
      name: "is_doctor_appointment",
      title: "Doctor Appointment",
      valueTransformer: this.valueTransformer["is_doctor_appointment"]
    },
    {
      name: "isActive",
      title: "Status",
      valueTransformer: this.valueTransformer["isActive"]
    }
  ];

  columnWidths = [
    {
      columnName: "doctor_id",
      width: 200
    },
    {
      columnName: "doctor_name",
      width: 200
    },
    {
      columnName: "doctor_specialist",
      width: 200
    },
    {
      columnName: "is_doctor_appointment",
      width: 200
    },
    {
      columnName: "isActive",
      width: 200
    }
  ];

  doctorStatusEnum = [
    { value: "all", name: "All" },
    { value: "true", name: "Active" },
    { value: "false", name: "Inactive" }
  ];

  doctorStatusUpdate = [
    { value: "true", name: "Active" },
    { value: "false", name: "Inactive" }
  ];

  doctorAppointmentEnum = [
    { value: "all", name: "All" },
    { value: "true", name: "True" },
    { value: "false", name: "False" }
  ];

  sorting = [{ columnName: "doctor_id", direction: "asc" }];

  //ACTION
  clearEditedData = () => {
    this.editedData = {};
  };

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setCurrentPage = currentPage => {
    this.editedData.currentPage = currentPage;
    this.getAllDoctorList();
  };

  setIsActive = value => {
    this.editedData.isActive = value;
  };

  setAppointment = value => {
    this.editedData.is_doctor_appointment = value;
  };

  setSearchQuery = searchValue => {
    this.editedData.currentPage = 0;
    this.editedData.searchQuery = searchValue;
  };

  setSorting = sorting => {
    this.sorting = sorting;
  };

  getAllDoctorList = async specialist => {
    this.editedData.isLoading = true;

    let allStatus = "";
    if (this.editedData.isActive === "all") {
      allStatus = "yes";
    }

    let allAppointment = "";
    if (this.editedData.is_doctor_appointment === "all") {
      allAppointment = "yes";
    }

    const { data, error } = await GetAllDoctorList({
      offset: Number(this.editedData.currentPage),
      limit: Number(this.editedData.pageSize),
      search: String(this.editedData.searchQuery),
      isActive: allStatus === "yes" ? "" : String(this.editedData.isActive),
      appointment:
        allAppointment === "yes"
          ? ""
          : String(this.editedData.is_doctor_appointment),
      specialist: specialist
    });

    if (data) {
      this.editedData.isLoading = false;

      this.editedData.totalCount = Number(data.count);
      this.dataArray = data.doctors.map(data => {
        return {
          ...data
        };
      });
    } else if (error) {
      this.editedData.isLoading = false;

      commonStore.showErrorMessage(error.data.message);
    }
  };

  getByIDDoctor = async () => {
    this.editedData.isLoading = true;

    const { data, error } = await GetByIDDoctor({
      id: String(this.editedData.id)
    });

    this.editedData.isLoading = false;

    if (data) {
      this.setEditedData({
        ...data,
        id: String(data.id),
        doctor_name: String(data.doctor_name),
        doctor_specialist: String(data.doctor_specialist),
        is_doctor_appointment: String(data.is_doctor_appointment),
        isActive: String(data.isActive)
      });
    } else if (error) {
      commonStore.showErrorMessage(error.response.data.message);
    }
  };

  putUpdateDoctor = async () => {
    this.editedData.isLoading = true;

    const { data, error } = await PutUpdateDoctor({
      id: String(this.editedData.id),
      isActive: String(this.editedData.isActive)
    });

    this.editedData.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Doctor Status Updated!");
      setTimeout(() => {
        window.location.href = "/hospitals";
      }, 500);
    } else if (error) {
      commonStore.showErrorMessage(error.response.data.message);
    }
  };

  //COMPUTED
}

decorate(DoctorListStore, {
  //OBSERVABLE
  editedData: observable,
  dataArray: observable,

  //ACTION
  setEditedData: action,
  getAllDoctorList: action,
  setDoctorStatusValue: action,
  setDoctorAppointmentValue: action,
  getByIDDoctor: action,
  putUpdateDoctor: action

  //COMPUTED
});

export default new DoctorListStore();
