import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function getHealthDeclarationForm(search, offset, limit) {
  try {
    let getHealthDeclarationForm = await axios.Get({
      url: `${adminUrl}/forms?search=${search || ''}&offset=${offset || ''}&limit=${limit || ''}&isDeleted=false&lang=EN`
    });

    if (getHealthDeclarationForm.statusCode !== 200) {
      return { error: getHealthDeclarationForm.message };
    }

    return { data: getHealthDeclarationForm.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function getHealthDeclarationFormById(formId) {
  try {
    let getHealthDeclarationFormById = await axios.Get({
      url: `${adminUrl}/forms/${formId}`
    });

    if (getHealthDeclarationFormById.statusCode !== 200) {
      return { error: getHealthDeclarationFormById.message };
    }

    return { data: getHealthDeclarationFormById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function createHealthDeclarationForm(editedData) {
  try {
    let createHealthDeclarationForm = await axios.Post({
      url: `${adminUrl}/forms`,
      data: editedData,
    });

    if (createHealthDeclarationForm.statusCode !== 200) {
      return { error: createHealthDeclarationForm.message };
    }

    return { data: createHealthDeclarationForm.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function updateHealthDeclarationForm(formId, editedData) {
  try {
    let updateHealthDeclarationForm = await axios.Put({
      url: `${adminUrl}/forms/${formId}`,
      data: editedData,
    });

    if (updateHealthDeclarationForm.statusCode !== 200) {
      return { error: updateHealthDeclarationForm.message };
    }

    return { data: updateHealthDeclarationForm.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}