import { observable, decorate } from "mobx";
import hospitalListStore from "./HospitalListStore";
import doctorListStore from "./DoctorListStore";

class AppointmentStore {
  editedData = {};
  dataArray = [];
  hospitalValue = "";
  areaValue = "";
  area = "";
  hospital = "";
  startDate = "";
  endDate = "";
  columns = [
    { name: "appointmentID", title: "Appointment ID" },
    { name: "name", title: "Patient Name" },
    { name: "date", title: "Date & Time" },
    { name: "hospitalName", title: "Hospital" },
    { name: "area", title: "Area" },
    { name: "doctorName", title: "Doctor Name" },
    { name: "status", title: "Status" }
  ];
  columnWidths = [
    { columnName: "appointmentID", width: 175 },
    { columnName: "name", width: 175 },
    { columnName: "date", width: 175 },
    { columnName: "hospitalName", width: 165 },
    { columnName: "area", width: 165 },
    { columnName: "doctorName", width: 170 },
    { columnName: "status", width: 170 }
  ];

  dropdownValueArea = value => {
    hospitalListStore.getAllHospitalList(value);
  };

  setStartDate = value => {
    this.startDate = value;
  };

  setEndDate = value => {
    this.endDate = value;
  };

  dropdownValueSpecialist = value => {
    doctorListStore.setIsActive(true);
    doctorListStore.setAppointment(true);
    doctorListStore.getAllDoctorList(value);
  };

  setHospitalValue = value => {
    this.hospitalValue = value;
  };

  setArea = value => {
    this.area = value;
  };

  setAreaValue = value => {
    this.areaValue = value;
  };

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };
}
decorate(AppointmentStore, {
  editedData: observable,
  hospitalValue: observable,
  areaValue: observable,
  area: observable,
  startDate: observable,
  endDate: observable
});
export default new AppointmentStore();
