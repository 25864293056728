import { observable, action, decorate } from "mobx";
import commonStore from "./CommonStore";

class ChangePasswordWidgetStore {
  //observable
  editedData = {};

  //action
  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  validateEmptyField() {
    if (
      this.editedData.password === "" ||
      this.editedData.confirmNewPassword === ""
    ) {
      commonStore.showErrorMessage("All fields must be filled!");
      return false;
    } else return true;
  }

  showWidget = async () => {
    await this.setEditedData({
      warning: true,
      isActive: true
    });
  };

  hideWidget = async () => {
    await this.setEditedData({
      warning: false,
      isActive: false,
      newPassword: "",
      confirmNewPassword: ""
    });
  };
}

decorate(ChangePasswordWidgetStore, {
  //observable
  editedData: observable,

  //action
  setEditedData: action,
  showWidget: action,
  hideWidget: action
});

export default new ChangePasswordWidgetStore();
