import { observable, decorate } from "mobx";

class ExportToCSVStore {
  editedData = {};
  loading = false;
  startDate = '';
  endDate = '';
  version = '';

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setStartDate = value => {
    this.startDate = value;
  };

  setEndDate = value => {
    this.endDate = value;
  };

  showModal = async (version) => {
    this.setEditedData({
      isOpen: true,
    });

    this.version = version;
  };

  hideModal = () => {
    this.setEditedData({
      isOpen: false
    });
  };
}

decorate(ExportToCSVStore, {
  editedData: observable,
  loading: observable,
});

export default new ExportToCSVStore();
