import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllSteps(id, startDate, endDate, search, offset, limit) {
    try {
        let getAllSteps = await axios.Get({
            url: `${adminUrl}/steps?userId=${id}&startDate=${startDate ||
                ''}&endDate=${endDate || ''}&search=${search ||
                ''}&offset=${offset || 0}&limit=${limit || 0}`
        });

        if (getAllSteps.statusCode !== 200) {
            return { error: getAllSteps.message };
        }

        return { data: getAllSteps.data };
    } catch (e) {
        if (e.response) {
            return { error: e.response };
        } else {
            return { error: e };
        }
    }
}
