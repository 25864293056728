import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetBloodGlucoseAlerts({type, order}) {
  try {
    const getBloodGlucoseAlerts = await axios.Get({
      url: `${adminUrl}/blood-glucose-alert-configs?type=${type || ''}&order=${order || ''}`
    });

    if (getBloodGlucoseAlerts.statusCode !== 200) {
      return { error: getBloodGlucoseAlerts.message };
    }

    return { data: getBloodGlucoseAlerts.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function UpdateBloodGlucoseAlert(updateArray) {
  try {
    const updateBloodGlucoseAlert = await axios.Put({
      url: `${adminUrl}/blood-glucose-alert-configs`,
      data: { updateArray },
    });

    if (updateBloodGlucoseAlert.statusCode !== 200) {
      return { error: updateBloodGlucoseAlert.message };
    }

    return { data: updateBloodGlucoseAlert.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetBloodGlucoseAlertMessage(type) {
  try {
    const getBloodGlucoseAlertMessage = await axios.Get({
      url: `${adminUrl}/blood-glucose-alert-messages?type=${type}`
    });

    if (getBloodGlucoseAlertMessage.statusCode !== 200) {
      return { error: getBloodGlucoseAlertMessage.message };
    }

    return { data: getBloodGlucoseAlertMessage.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function CreateBloodGlucoseAlertConfig(data) {
  try {
    const createBloodGlucoseAlertConfig = await axios.Post({
      url: `${adminUrl}/blood-glucose-alert-configs`,
      data: data,
    });

    if (createBloodGlucoseAlertConfig.statusCode !== 200) {
      return { error: createBloodGlucoseAlertConfig.message };
    }

    return { data: createBloodGlucoseAlertConfig.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function CreateBloodGlucoseAlertMessage(data) {
  try {
    const createBloodGlucoseAlertMessage = await axios.Post({
      url: `${adminUrl}/blood-glucose-alert-messages`,
      data: { dataArray: data },
    });

    if (createBloodGlucoseAlertMessage.statusCode !== 200) {
      return { error: createBloodGlucoseAlertMessage.message };
    }

    return { data: createBloodGlucoseAlertMessage.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function UpdateBloodGlucoseAlertById({data, id}) {
  try {
    const updateBloodGlucoseAlert = await axios.Put({
      url: `${adminUrl}/blood-glucose-alert-configs/id`,
      data: data,
    });

    if (updateBloodGlucoseAlert.statusCode !== 200) {
      return { error: updateBloodGlucoseAlert.message };
    }

    return { data: updateBloodGlucoseAlert.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function DeleteBloodGlucoseAlertMessage(data) {
  try {
    const deleteBloodGlucoseAlert = await axios.Put({
      url: `${adminUrl}/blood-glucose-alert-messages/delete`,
      data: data,
    });

    if (deleteBloodGlucoseAlert.statusCode !== 200) {
      return { error: deleteBloodGlucoseAlert.message };
    }

    return { data: deleteBloodGlucoseAlert.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}