import React from "react";
import {
  GetGameList, GetGameListById, CreateGameList, UpdateGameList,
  UpdateGameListIsFree, DeleteGameList, GetGameQuestion, GetGameQuestionById,
  CreateGameQuestion, UpdateGameQuestion, DeleteGameQuestion
} from "../services/MiniGameService";
import Switch from '@material-ui/core/Switch';
import { silviaTriviaValidation } from "../helper/functions";
import { observable, decorate } from "mobx";
import commonStore from "./CommonStore";

class MiniGameStore {
  valueTransformer = {
    id: data => {
      return <div onClick={(e) => this.goToMiniGameDetail(data.id)}>{data.id}</div>;
    },
    name: data => {
      return <div onClick={(e) => this.goToMiniGameDetail(data.id)}>{data.name}</div>;
    },
    isFree: data => {
      return <div onClick={(e) => this.setIsFreeSwitch(data.id, !data.isFree)}>
        <Switch
          checked={data.isFree}
          name="isFree"
          value={data.isFree}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </div>
    }
  };

  editedData = {
    imageUrl: null,
    imgLoading: false,
  };
  editedDataQuestion = {
    answer1: "choice1",
    answer2: "choice2",
    answer3: "choice3",
    answer4: "choice4",
    answer5: "choice5",
  };
  history = "";
  miniGameId = "";
  miniGameQuestionId = "";
  dataArray = [];
  dataArrayQuestion = [];
  type = "All";
  searchQuery = "";
  loading = false;
  pageSize = 10;
  currentPage = 0;
  currentPageQuestion = 0;
  totalCount = 0;
  totalCountQuestion = 0;
  columns = [
    { name: "id", title: "Mini Game ID", valueTransformer: this.valueTransformer["id"] },
    { name: "name", title: "Mini Game Name", valueTransformer: this.valueTransformer["name"] },
    { name: "isFree", title: "Is Featured", valueTransformer: this.valueTransformer["isFree"] },
  ];
  columnQuestions = [
    { name: "id", title: "Trivia ID" },
    { name: "type", title: "Type" },
    { name: "question", title: "Trivia Question" },
  ];
  columnWidths = [
    { columnName: "id", width: 250 },
    { columnName: "name", width: 250 },
    { columnName: "isFree", width: 250 },
  ];
  columnWidthQuestions = [
    { columnName: "id", width: 250 },
    { columnName: "type", width: 250 },
    { columnName: "question", width: 250 },
  ];
  typeEnum = [
    { value: "All", name: "All" },
    { value: "True or false", name: "True or false" },
    { value: "Sort", name: "Sort" },
    { value: "Scramble", name: "Scramble" },
    { value: "Matching", name: "Matching" },
    { value: "Category", name: "Category" },
  ];

  setIsFreeSwitch = async (gameListId, isFree) => {
    let { error } = await UpdateGameListIsFree(gameListId, isFree);

    if (!error) {
      this.getGameList();
    }
  };

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setEditedDataQuestion = updatedData => {
    this.editedDataQuestion = {
      ...this.editedDataQuestion,
      ...updatedData
    };
  };

  setSearchQuery = searchValue => {
    this.currentPage = 0;
    this.searchQuery = searchValue;
  };

  setCurrentPage = currentPage => {
    this.currentPage = currentPage;
    this.getGameList();
  };

  setCurrentPageQuestion = currentPageQuestion => {
    this.currentPageQuestion = currentPageQuestion;
    this.getGameQuestions();
  };

  setType = (type) => {
    this.type = type;
  }

  setHistory = (history) => {
    this.history = history;
  }

  goToMiniGameDetail = (id) => {
    this.history.push(`/mini-game/${id}`);
  }

  addCategoryAnswer = () => {
    if (this.editedDataQuestion.answer) {
      if (this.editedDataQuestion.answer.length > 0) {
        this.editedDataQuestion.answer.push('');
      } else {
        this.editedDataQuestion.answer = [''];
      }
    } else {
      this.editedDataQuestion.answer = [''];
    }

    this.setEditedDataQuestion(this.editedDataQuestion);
  }

  clearEditedData = () => {
    this.editedData = {
      imageUrl: null,
      imgLoading: false,
    };
  };

  clearEditedDataQuestion = () => {
    this.editedDataQuestion = {
      type: "All"
    };
  };

  getGameList = async () => {
    this.loading = true;

    let { data, error } = await GetGameList(
      this.searchQuery,
      this.currentPage,
      this.pageSize,
    );

    if (data) {
      this.loading = false;
      this.totalCount = data.count;
      this.dataArray = data.gameList;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getGameListById = async (id) => {
    this.loading = true;

    let { data, error } = await GetGameListById(id);

    if (data) {
      this.loading = false;
      this.miniGameId = data.id;
      this.editedData.name = data.name;
      this.editedData.url = data.url;
      this.editedData.imageUrl = data.imageUrl;
      this.editedData.bannerColor = data.bannerColor;
      this.editedData.descriptionId = data.descriptionId;
      this.editedData.descriptionEn = data.descriptionEn;

    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  createGameList = async (history) => {
    this.loading = true;

    let { data, error } = await CreateGameList(this.editedData);

    if (data) {
      this.loading = false;
      history.push("/mini-game");

    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  updateGameList = async (history) => {
    this.loading = true;

    let { data, error } = await UpdateGameList(this.miniGameId, this.editedData);

    if (data) {
      this.loading = false;
      history.push("/mini-game");

    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  deleteGameList = async (history) => {
    this.loading = true;

    let { data, error } = await DeleteGameList(this.habitId);

    if (data) {
      this.loading = false;
      history.push("/habit");

    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getGameQuestions = async (gameListId) => {
    this.loading = true;

    let { data, error } = await GetGameQuestion(
      gameListId,
      this.searchQuery,
      this.type !== 'All' ? this.type : '',
      this.currentPageQuestion,
      this.pageSize,
    );

    if (data) {
      this.loading = false;
      this.totalCountQuestion = data.count;
      this.dataArrayQuestion = data.game;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getGameQuestionById = async (gameQuestionId) => {
    this.loading = true;

    let { data, error } = await GetGameQuestionById(gameQuestionId);

    if (data) {
      this.loading = false;
      this.miniGameQuestionId = data.id;
      this.editedDataQuestion = data;

      if (data.type === 'Sort') {
        for (let i = 0; i < data.choice.length; i += 1) {
          this.editedDataQuestion[`choice${i + 1}`] = data.choice[i];
          this.editedDataQuestion[`answer${i + 1}`] = data.answer[i];
        }
        this.setEditedDataQuestion(this.editedDataQuestion);
      } else if (data.type === 'True or false') {
        this.editedDataQuestion.answer = data.answer[0];
      } else if (data.type === 'Scramble') {
        this.editedDataQuestion.answer = data.answer[0];
      } else if (data.type === 'Category') {
        for (let i = 0; i < data.answer.length; i += 1) {
          this.editedDataQuestion[`answer${i + 1}`] = data.answer[i];
        }
        this.setEditedDataQuestion(this.editedDataQuestion);
      } else if (data.type === 'Matching') {
        const leftString = data.choice[0].split(',,@');
        const rightString = data.choice[1].split(',,@');
        const rightAnswer = data.answer[1].split(',,@');

        for (let i = 0; i < 4; i++) {
          this.editedDataQuestion[`left${i + 1}`] = leftString[i];
          this.editedDataQuestion[`right${i + 1}`] = rightString[i];
          this.editedDataQuestion[`answer${i + 1}`] = rightAnswer[i];
        }

        this.setEditedDataQuestion(this.editedDataQuestion);
      }
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  }

  createGameQuestion = async (history, gameListId) => {
    this.loading = true;

    const result = silviaTriviaValidation(this.editedDataQuestion, gameListId);
    this.editedDataQuestion = result;

    let { data, error } = await CreateGameQuestion(this.editedDataQuestion);

    if (data) {
      this.loading = false;
      history.push(`/mini-game/${gameListId}`);

    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  updateGameQuestion = async (history, gameListId) => {
    this.loading = true;

    const result = silviaTriviaValidation(this.editedDataQuestion, gameListId);
    this.editedDataQuestion = result;

    let { data, error } = await UpdateGameQuestion(this.miniGameQuestionId, this.editedDataQuestion);

    if (data) {
      this.loading = false;
      history.push(`/mini-game/${gameListId}`);

    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  deleteGameQuestion = async (history) => {
    this.loading = true;

    let { data, error } = await DeleteGameQuestion(this.miniGameQuestionId);

    if (data) {
      this.loading = false;
      history.push("/mini-game");

    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };
}

decorate(MiniGameStore, {
  editedData: observable,
  editedDataQuestion: observable,
  miniGameId: observable,
  miniGameQuestionId: observable,
  dataArray: observable,
  dataArrayQuestion: observable,
  type: observable,
  searchQuery: observable,
  currentPage: observable,
  totalCount: observable,
  loading: observable,
});

export default new MiniGameStore();
