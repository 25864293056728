import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllPromotionBanner({ offset, limit, type, isActive }) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/promotion-banners?offset=${offset}&limit=${limit}&type=${type === "All" ? "" : type}&isActive=${isActive}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetPromotionBannerById({ id }) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/promotion-banners/${id}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function CreatePromotionBanner({ type, order, startDate, endDate, title, description, url, imageUrl, isActive, pageId }) {
  try {
    if (!startDate) {
      startDate = "";
    }

    if (!endDate) {
      endDate = "";
    }

    let response = await axios.Post({
      url: `${adminUrl}/promotion-banners`,
      data: { type, order, startDate, endDate, title, description, url, imageUrl, isActive, pageId }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function UpdatePromotionBanner({ id, type, order, startDate, endDate, title, description, url, imageUrl, isActive, pageId }) {
  try {
    if (!startDate) {
      startDate = "";
    }

    if (!endDate) {
      endDate = "";
    }

    let response = await axios.Put({
      url: `${adminUrl}/promotion-banners/${id}`,
      data: { type, order, startDate, endDate, title, description, url, imageUrl, isActive, pageId }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutDeleteExercise({ id }) {
  try {
    let putDeleteExerciseResponse = await axios.Put({
      url: `${adminUrl}/exercises/${id}/delete`
    });

    if (putDeleteExerciseResponse.statusCode !== 200) {
      return { error: putDeleteExerciseResponse.message };
    }

    return { data: putDeleteExerciseResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
