import { observable, decorate } from "mobx";

class DeleteWarningStore {
  editedData = {};
  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  showWarning = async (title, text) => {
    await this.setEditedData({
      warning: true,
      isActive: true
    });
  };

  hideWarning = () => {
    this.setEditedData({
      warning: "",
      isActive: false
    });
  };
}
decorate(DeleteWarningStore, {
  editedData: observable
});

export default new DeleteWarningStore();
