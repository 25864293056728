import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function getFormScoringByFormId(formId) {
  try {
    let getFormScoringByFormId = await axios.Get({
      url: `${adminUrl}/form-scorings?formId=${formId || ''}`
    });

    if (getFormScoringByFormId.statusCode !== 200) {
      return { error: getFormScoringByFormId.message };
    }

    return { data: getFormScoringByFormId.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function getFormScoringById(id) {
  try {
    let getFormScoringById = await axios.Get({
      url: `${adminUrl}/form-scorings/${id}`
    });

    if (getFormScoringById.statusCode !== 200) {
      return { error: getFormScoringById.message };
    }

    return { data: getFormScoringById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function createFormScoring(editedData) {
  try {
    let createFormScoring = await axios.Post({
      url: `${adminUrl}/form-scorings`,
      data: editedData,
    });

    if (createFormScoring.statusCode !== 200) {
      return { error: createFormScoring.message };
    }

    return { data: createFormScoring.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}