import { GetAllTag, GetAllSpecialist, GetAllSpecializationTag, CreateSpecializationTag } from "../services/InterestService";
import { observable, action, decorate } from "mobx";
import commonStore from "./CommonStore";

class InterestStore {
  editedData = {
    isLoading: false,
    currentPage: 0,
    pageSize: 10,
    totalCount: 0,
    searchQuery: "",
    lang: "ID",
    tagArray: [],
    specialistArray: [],
    specializationTagArray: [],
    tagId: ""
  };
  columns = [
    {
      name: "name",
      title: "Interest Name"
    }
  ];
  columnWidths = [
    {
      columnName: "name",
      width: 150
    },
  ];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setCurrentPage = currentPage => {
    this.editedData.currentPage = currentPage;
    this.getAllTag();
  };

  getAllTag = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await GetAllTag(
      this.editedData.lang,
      this.editedData.currentPage,
      this.editedData.pageSize,
      this.editedData.searchQuery
    );

    if (data) {
      this.editedData.isLoading = false;
      this.editedData.tagArray = data.tag;
      this.editedData.totalCount = data.count;
    } else if (error) {
      this.editedData.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  getAllSpecialist = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await GetAllSpecialist();

    if (data) {
      this.editedData.isLoading = false;
      this.editedData.specialistArray = data.map(row => {
        const label = this.editedData.lang === "ID" ? row.speciality_name : row.speciality_name_en;
        const value = row.speciality_id;

        return {
          ...row,
          label,
          value
        };
      });
    } else if (error) {
      this.editedData.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  getSpecializationTagByTagId = async (tagId) => {
    this.editedData.isLoading = true;

    let { data, error } = await GetAllSpecializationTag(tagId);

    if (data) {
      this.editedData.isLoading = false;

      this.editedData.tagId = tagId;
      this.editedData.tagName = this.editedData.lang === "ID" ? data.tag.nameIdn : data.tag.nameEn;

      let tempArray = [];
      for (let i = 0; i < this.editedData.specialistArray.length; i++) {
        const currentSpecialist = this.editedData.specialistArray[i];
        for (let j = 0; j < data.specilizationTag.length; j++) {
          if (currentSpecialist.speciality_id === data.specilizationTag[j].specializationId) {
            // tempArray.push(currentSpecialist);
            tempArray.push({
              label: currentSpecialist.speciality_name,
              value: currentSpecialist.speciality_id,
            });
          }
        }
      }

      this.editedData.specializationTagArray = tempArray;
    } else if (error) {
      this.editedData.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  getAllSpecializationTag = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await GetAllSpecializationTag();

    if (data) {
      this.editedData.isLoading = false;
      this.editedData.specializationTagArray = data.map(data => {
        const name = this.editedData.lang === "ID" ? data.tag.nameIdn : data.tag.nameEn;

        return {
          ...data,
          name,
        };
      });
    } else if (error) {
      this.editedData.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  createSpecializationTag = async (history) => {
    this.editedData.isLoading = true;
    let { data, error } = await CreateSpecializationTag(this.editedData.tagId, this.editedData.specializationTagArray);

    if (data) {
      this.editedData.isLoading = false;
      history.push("/interest");
    } else if (error) {
      this.editedData.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  updateSpecializationTag = async (tagId, history) => {
    this.editedData.isLoading = true;
    let { data, error } = await CreateSpecializationTag(tagId, this.editedData.specializationTagArray);

    if (data) {
      this.editedData.isLoading = false;
      history.push("/interest");
    } else if (error) {
      this.editedData.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };
}

decorate(InterestStore, {
  editedData: observable,

  setEditedData: action,
  getAllTag: action,
  getAllSpecialist: action,
  getAllSpecializationTag: action,
  createSpecializationTag: action
});

export default new InterestStore();
