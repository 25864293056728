import { observable, action, decorate } from "mobx";
import React from "react";
import commonStore from "./CommonStore";
import {
  GetAllHospitalList,
  GetByIDHospital,
  PutUpdateHospital
} from "../services/HospitalListService";

class HospitalListStore {
  //OBSERVABLE
  dataArray = [];
  editedData = {
    isLoading: false,
    currentPage: 0,
    pageSize: 10,
    totalCount: 0,
    searchQuery: "",
    isActive: "all"
  };

  valueTransformer = {
    isActive: data => {
      if (data.isActive === true) {
        return <span className="active-span">ACTIVE</span>;
      } else if (data.isActive === false) {
        return <span className="nonactive-span">INACTIVE</span>;
      }
    }
  };

  columns = [
    {
      name: "hospital_id",
      title: "Hospital ID"
    },
    {
      name: "area_id",
      title: "Area ID"
    },
    {
      name: "name",
      title: "Hospital Name"
    },
    {
      name: "isActive",
      title: "Status",
      valueTransformer: this.valueTransformer["isActive"]
    }
  ];

  columnWidths = [
    {
      columnName: "hospital_id",
      width: 200
    },
    {
      columnName: "area_id",
      width: 200
    },
    {
      columnName: "name",
      width: 200
    },
    {
      columnName: "isActive",
      width: 200
    }
  ];

  hospitalStatusEnum = [
    { value: "all", name: "All" },
    { value: "true", name: "Active" },
    { value: "false", name: "Inactive" }
  ];

  hospitalStatusUpdate = [
    { value: "true", name: "Active" },
    { value: "false", name: "Inactive" }
  ];

  sorting = [{ columnName: "hospital_id", direction: "asc" }];

  //ACTION
  clearEditedData = () => {
    this.editedData = {};
  };

  setPageLimit = value => {
    this.pageSize = value;
  };

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setIsActive = value => {
    this.editedData.isActive = value;
  };

  setCurrentPage = currentPage => {
    this.editedData.currentPage = currentPage;
    this.getAllHospitalList();
  };

  setSearchQuery = searchValue => {
    this.editedData.currentPage = 0;
    this.editedData.searchQuery = searchValue;
  };

  setSorting = sorting => {
    this.sorting = sorting;
  };

  getAllHospitalList = async area_id => {
    this.editedData.isLoading = true;

    let all = "";
    if (this.editedData.isActive === "all") {
      all = "yes";
    }

    const { data, error } = await GetAllHospitalList({
      offset: Number(this.editedData.currentPage),
      limit: Number(this.editedData.pageSize),
      search: String(this.editedData.searchQuery),
      isActive: all === "yes" ? "" : String(this.editedData.isActive),
      area_id: area_id
    });

    if (data) {
      this.editedData.isLoading = false;

      this.editedData.totalCount = Number(data.count);
      this.dataArray = data.hospitals.map(data => {
        return {
          ...data
        };
      });
      return data.hospitals;
    } else if (error) {
      this.editedData.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  getByIDHospital = async () => {
    this.editedData.isLoadng = true;

    const { data, error } = await GetByIDHospital({
      id: String(this.editedData.hospitalID)
    });

    this.editedData.isLoadng = false;

    if (data) {
      this.setEditedData({
        hospitalID: String(data.hospitalID),
        hospital_id: String(data.hospital_id),
        area_id: String(data.area_id),
        name: String(data.name),
        isActive: String(data.isActive)
      });

      return data;
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  putUpdateHospital = async () => {
    this.editedData.isLoadng = true;

    const { data, error } = await PutUpdateHospital({
      id: String(this.editedData.hospitalID),
      isActive: String(this.editedData.isActive)
    });

    this.editedData.isLoadng = false;

    if (data) {
      commonStore.showSuccessMessage("Hospital Status Updated!");
      setTimeout(() => {
        window.location.href = "/hospitals";
      }, 500);
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  //COMPUTED
}

decorate(HospitalListStore, {
  //OBSERVABLE
  editedData: observable,
  dataArray: observable,

  //ACTION
  setEditedData: action,
  getAllHospitalList: action,
  setHospitalStatusValue: action,
  getByIDHospital: action,
  putUpdateHospital: action

  //COMPUTED
});

export default new HospitalListStore();
