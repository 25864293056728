import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetBodyMeasurements(userId) {
    try {
        let getBodyMeasurement = await axios.Get({
            url: `${adminUrl}/body-measurement-records?userId=${userId}&isDeleted=false&order=DESC&limit=1`
        });

        if (getBodyMeasurement.statusCode !== 200) {
            return { error: getBodyMeasurement.message };
        }

        return { data: getBodyMeasurement.data };
    } catch (e) {
        if (e.response) {
            return { error: e.response };
        } else {
            return { error: e };
        }
    }
}

export async function GetBodyData(userId) {
    try {
        let getBodyData = await axios.Get({
            url: `${adminUrl}/body-datas?userId=${userId}&isDeleted=false&order=DESC&limit=1`
        });

        if (getBodyData.statusCode !== 200) {
            return { error: getBodyData.message };
        }

        return { data: getBodyData.data };
    } catch (e) {
        if (e.response) {
            return { error: e.response };
        } else {
            return { error: e };
        }
    }
}

export async function GetHeightRecord(userId) {
    try {
        let getBodyData = await axios.Get({
            url: `${adminUrl}/height-records?userId=${userId}&isDeleted=false&order=DESC&limit=1`
        });

        if (getBodyData.statusCode !== 200) {
            return { error: getBodyData.message };
        }

        return { data: getBodyData.data };
    } catch (e) {
        if (e.response) {
            return { error: e.response };
        } else {
            return { error: e };
        }
    }
}