import axios from "../helper/Axios";
import { adminUrl } from "../config/config.js";

export async function GetAllDiabeticExpertList({
  offset,
  limit,
  search,
  role,
  hospitalId,
  order
}) {
  try {
    let getAllDiabeticExpertResponse = await axios.Get({
      url: `${adminUrl}/admins?offset=${offset || ""}&limit=${limit ||
        ""}&order=${order}&search=${search || ""}&role=${role ||
        ""}&hospitalId=${hospitalId || ""}`
    });

    if (getAllDiabeticExpertResponse.statusCode !== 200) {
      return { error: getAllDiabeticExpertResponse.message };
    }

    return { data: getAllDiabeticExpertResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function CreateDiabeticExpert({
  email,
  password,
  name,
  role,
  hospitalId
}) {
  try {
    let postDiabeticExpert = await axios.Post({
      url: `${adminUrl}/admins`,
      data: { email, password, name, role, hospitalId }
    });

    if (postDiabeticExpert.statusCode !== 200) {
      return { error: postDiabeticExpert.message };
    }

    return { data: postDiabeticExpert };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetByIDDiabeticExpert({ id }) {
  try {
    let getByIDDiabeticExpertResponse = await axios.Get({
      url: `${adminUrl}/admins/${id}`
    });

    if (getByIDDiabeticExpertResponse.statusCode !== 200) {
      return { error: getByIDDiabeticExpertResponse.message };
    }

    return { data: getByIDDiabeticExpertResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdateDiabeticExpert({ id, name, email, hospitalId }) {
  try {
    let putUpdateDiabeticExpertResponse = await axios.Put({
      url: `${adminUrl}/admins/${id}`,
      data: { name, email, hospitalId }
    });

    if (putUpdateDiabeticExpertResponse.statusCode !== 200) {
      return { error: putUpdateDiabeticExpertResponse.message };
    }

    return { data: putUpdateDiabeticExpertResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdatePasswordDiabeticExpert({
  id,
  newPassword,
  confirmNewPassword
}) {
  try {
    let putUpdatePasswordDiabeticExpertResponse = await axios.Put({
      url: `${adminUrl}/admins/${id}/password`,
      data: { newPassword, confirmNewPassword }
    });

    if (putUpdatePasswordDiabeticExpertResponse.statusCode !== 200) {
      return { error: putUpdatePasswordDiabeticExpertResponse.message };
    }

    return { data: putUpdatePasswordDiabeticExpertResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutDeleteDiabeticExpert({ id }) {
  try {
    let putDeleteAdmin = await axios.Put({
      url: `${adminUrl}/admins/${id}/delete`
    });

    if (putDeleteAdmin.statusCode !== 200) {
      return { error: putDeleteAdmin.message };
    }

    return { data: putDeleteAdmin.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
