import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllAreaList({ offset, limit, search, isActive }) {
  try {
    let getAllAreaResponse = await axios.Get({
      url: `${adminUrl}/areas?offset=${offset || ""}&limit=${limit ||
        ""}&search=${search || ""}&isActive=${isActive || ""}`
    });

    if (getAllAreaResponse.statusCode !== 200) {
      return { error: getAllAreaResponse.message };
    }

    return { data: getAllAreaResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetByIDArea({ id }) {
  try {
    let getByIDAreaResponse = await axios.Get({
      url: `${adminUrl}/areas/${id}`
    });

    if (getByIDAreaResponse.statusCode !== 200) {
      return { error: getByIDAreaResponse.message };
    }

    return { data: getByIDAreaResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdateArea({ id, isActive }) {
  try {
    let putUpdateAreaResponse = await axios.Put({
      url: `${adminUrl}/areas/${id}/is-active`,
      data: { isActive }
    });

    if (putUpdateAreaResponse.statusCode !== 200) {
      return { error: putUpdateAreaResponse.message };
    }

    return { data: putUpdateAreaResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
