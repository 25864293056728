import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function getUserForms(formId, offset, limit) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/user-forms`,
      data: {
        formId,
        offset: offset || '',
        limit: limit || '',
      },
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function exportUserForm(formId, startDate, endDate) {
  try {
    let getUserFormByFormId = await axios.Get({
      url: `${adminUrl}/user-forms/${formId}?startDate=${startDate || ''}&endDate=${endDate || ''}`
    });

    if (getUserFormByFormId.statusCode !== 200) {
      return { error: getUserFormByFormId.message };
    }

    return { data: getUserFormByFormId.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
