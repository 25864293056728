import {
  GetAllMedicationRecord,
  GetMedicationRecordById,
  PutDeleteMedicationRecordById
} from "../services/MedicationRecordService";
import { observable, decorate, action } from "mobx";
import moment from "moment";
import swal from "sweetalert2";
import commonStore from "./CommonStore";

class MedicationRecordStore {
  editedData = {};
  dataArray = [];
  searchQuery = "";
  startDate = "";
  endDate = "";
  loading = false;
  pageSize = 10;
  currentPage = 0;
  totalCount = 0;
  columns = [
    { name: "medicationRecordID", title: "Record ID" },
    { name: "name", title: "Medicine Name" },
    { name: "forDay", title: "Date & Time" }
  ];
  columnWidths = [
    { columnName: "medicationRecordID", width: 200 },
    { columnName: "name", width: 200 },
    { columnName: "forDay", width: 200 }
  ];
  disabledColumn = [
    { columnName: "name", sortingEnabled: false },
    { columnName: "forDay", sortingEnabled: false }
  ];
  sorting = [{ columnName: "medicationRecordID", direction: "asc" }];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setSorting = updatedSorting => {
    // if (this.order === "DESC") {
    //   this.order = "ASC";
    // } else {
    //   this.order = "DESC";
    // }
    this.sorting = updatedSorting;
  };

  setStartDate = value => {
    this.currentPage = 0;

    this.startDate = value;
  };

  setEndDate = value => {
    this.currentPage = 0;

    this.endDate = value;
  };

  setSearchQuery = searchValue => {
    this.currentPage = 0;
    this.searchQuery = searchValue;
  };

  setCurrentPage = currentPage => {
    this.currentPage = currentPage;
    this.getAllMedicationRecord();
  };

  getAllMedicationRecord = async id => {
    this.authLoading = true;
    this.loading = true;
    let { data, error } = await GetAllMedicationRecord(
      this.editedData.userId,
      this.startDate ? moment(this.startDate).format("YYYY-MM-DDThh:mm") : "",
      this.endDate ? moment(this.endDate).format("YYYY-MM-DDThh:mm") : "",
      this.searchQuery,
      this.currentPage,
      this.pageSize,
      this.sorting[0].direction
    );

    if (data) {
      this.loading = false;
      this.totalCount = data.count;
      this.dataArray = data.data.medicationRecords.map(data => {
        const medicationRecordID = data.medicationRecordID
          ? Number(data.medicationRecordID)
          : "";
        const name = data.name ? data.name : "-";
        const medicineId =
          data.prescription && data.prescription.medicineMedicineID
            ? data.prescription.medicineMedicineID
            : "";

        const forDay = moment(data.forDay ? data.forDay : "-").format(
          "DD MMM YYYY hh:mm:ss"
        );

        return {
          ...data,
          medicationRecordID,
          name,
          medicineId,
          forDay
        };
      });
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getMedicationRecordById = async id => {
    this.authLoading = true;

    let { data, error } = await GetMedicationRecordById(id);

    if (data) {
      this.setEditedData({
        medicationRecordID: data.medicationRecordID,
        medicineId: data.prescription && data.prescription.medicineMedicineID,
        date: moment(data.forDay).format("DD MMM YYYY hh:mm:ss")
      });

      return { data };
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  putDeleteMedicationRecordById = async (userId, id) => {
    let { data, error } = await PutDeleteMedicationRecordById(id);

    if (data) {
      swal({
        title: "Success",
        text: "Medication Success Deleted",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = "/patients/" + userId + "/healthtracker";
        return { data };
      });
    } else {
      return { error };
    }
  };
}

decorate(MedicationRecordStore, {
  editedData: observable,
  dataArray: observable,
  startDate: observable,
  endDate: observable,
  totalCount: observable,
  searchQuery: observable,
  currentPage: observable,
  loading: observable,

  getAllMedicationRecord: action,
  getMedicationRecordById: action
});

export default new MedicationRecordStore();
