import React from "react";
import {
  GetPages, GetPageById, CreatePage, UpdatePage, DeletePage
} from "../services/PageService";
import { observable, decorate } from "mobx";
import commonStore from "./CommonStore";

class PageStore {
  valueTransformer = {
    isActive: data => {
      if (data.isActive === true) {
        return <div>True</div>;
      } else {
        return <div>False</div>;
      }
    },
  };

  editedData = {};
  dataArray = [];
  searchQuery = "";
  isActive = "all";
  loading = false;
  pageSize = 10;
  currentPage = 0;
  totalCount = 0;
  columns = [
    { name: "id", title: "Page ID" },
    { name: "name", title: "Name" },
    { name: "isActive", title: "Is Active", valueTransformer: this.valueTransformer["isActive"] },
  ];
  columnWidths = [
    { columnName: "id", width: 250 },
    { columnName: "name", width: 250 },
    { columnName: "isActive", width: 250 },
  ];
  isActiveEnum = [
    { value: 'all', name: "All" },
    { value: 'true', name: "Active" },
    { value: 'false', name: "Inactive" },
  ];
  isActiveEnumDetail = [
    { value: 'true', name: "Active" },
    { value: 'false', name: "Inactive" },
  ];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setSearchQuery = searchValue => {
    this.currentPage = 0;
    this.searchQuery = searchValue;
  };

  setCurrentPage = currentPage => {
    this.currentPage = currentPage;
    this.getPages();
  };

  setIsActive = value => {
    this.isActive = value;
  };

  goToDetail = (id) => {
    this.history.push(`/page/${id}`);
  }

  clearEditedData = () => {
    this.editedData = {};
  };

  getPages = async () => {
    this.loading = true;

    let { data, error } = await GetPages(
      this.searchQuery,
      this.isActive === "all" ? '' : this.isActive,
      this.currentPage,
      this.pageSize,
    );

    if (data) {
      this.loading = false;
      this.totalCount = data.count;
      this.dataArray = data.page;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getPageById = async (id) => {
    this.loading = true;

    let { data, error } = await GetPageById(id);

    if (data) {
      this.loading = false;
      this.editedData = data;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  createPage = async (history) => {
    this.loading = true;

    let { data, error } = await CreatePage(this.editedData);

    if (data) {
      this.loading = false;
      history.push("/page");
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  updatePage = async (history) => {
    this.loading = true;

    let { data, error } = await UpdatePage(this.editedData);

    if (data) {
      this.loading = false;
      history.push("/page");
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  deletePage = async (history) => {
    this.loading = true;

    let { data, error } = await DeletePage(this.editedData.id);

    if (data) {
      this.loading = false;
      history.push("/page");
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };
}

decorate(PageStore, {
  editedData: observable,
  dataArray: observable,
  isActive: observable,
  searchQuery: observable,
  currentPage: observable,
  totalCount: observable,
  loading: observable,
});

export default new PageStore();
