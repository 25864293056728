import { uploadImage } from "../services/GTNService";
import { observable, decorate } from "mobx";
import commonStore from "./CommonStore";

class GTNStore {
    isLoading = false;

    uploadImage = async (files, uploader) => {
        this.isLoading = true;
        let { data, error } = await uploadImage(files[0], uploader);

        if (data) {
            this.isLoading = false;
            return data.uri_ext ? data.uri_ext : data.uri;
        } else if (error) {
            this.isLoading = false;
            if (error.data) {
                commonStore.showErrorMessage(error.data.message);
            } else {
                commonStore.showErrorMessage("Something went wrong.");
            }
        }
    };
}

decorate(GTNStore, {
    //OBSERVABLE
    editedData: observable,
    isLoading: observable,
});

export default new GTNStore();
