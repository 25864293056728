import {
  GetAllSymptom,
  GetByIDSymptom,
  PostSymptom,
  PutUpdateSymptom,
  PutDeleteSymptom
} from "../services/SymptomsService";
import { observable, action, decorate } from "mobx";
import commonStore from "./CommonStore";

class SymptomsStore {
  //OBSERVABLE
  dataArray = [];
  editedData = {
    isLoading: false,
    currentPage: 0,
    totalCount: 0,
    searchQuery: "",
    name: "",
    pageSize: 10
  };

  columns = [
    {
      name: "symptomsID",
      title: "Symptom ID"
    },
    {
      name: "name",
      title: "Symptom Name"
    }
  ];

  columnWidths = [
    {
      columnName: "symptomsID",
      width: 200
    },
    {
      columnName: "name",
      width: 400
    }
  ];

  disabledColumn = [
    {
      columnName: "name",
      sortingEnabled: false
    }
  ];

  sorting = [{ columnName: "symptomsID", direction: "asc" }];

  //ACTION
  clearEditedData = () => {
    this.editedData = {};
  };

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setPageLimit = value => {
    this.editedData.pageSize = value;
  };

  setCurrentPage = currentPage => {
    this.editedData.currentPage = currentPage;
    this.getAllSymptom();
  };

  setSearchQuery = searchValue => {
    this.editedData.currentPage = 0;
    this.editedData.searchQuery = searchValue;
  };

  setSorting = sorting => {
    this.sorting = sorting;
  };

  getAllSymptom = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await GetAllSymptom({
      offset: Number(this.editedData.currentPage),
      limit: this.editedData.pageSize,
      search: String(this.editedData.searchQuery),
      order: this.sorting[0].direction
    });

    if (data) {
      this.editedData.isLoading = false;

      this.editedData.totalCount = Number(data.count);
      this.dataArray = data.symptoms.map(data => {
        return {
          ...data,
          symptomsID: Number(data.symptomsID)
        };
      });

      return { data };
    } else if (error) {
      this.editedData.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  getByIDSymptom = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await GetByIDSymptom({
      id: this.editedData.symptomsID
    });

    this.editedData.isLoading = false;

    if (data) {
      this.setEditedData({
        name: String(data.name),
        symptomsID: String(data.symptomsID)
      });
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  validateEmptyField() {
    if (this.editedData.name === "") {
      commonStore.showErrorMessage("All fields must be filled!");
      return false;
    } else return true;
  }

  postSymptom = async () => {
    this.editedData.isLoading = true;

    if (this.validateEmptyField()) {
      let { data, error } = await PostSymptom({
        name: String(this.editedData.name)
      });

      this.editedData.isLoading = false;

      if (data) {
        commonStore.showSuccessMessage("Symptom added successfully!");
        setTimeout(() => {
          window.location.href = "/symptom";
        }, 500);
      } else if (error) {
        if (error.data) {
          commonStore.showErrorMessage(error.data.message);
        } else {
          commonStore.showErrorMessage("Something went wrong.");
        }
      }
    }
  };

  putUpdateSymptom = async () => {
    this.editedData.isLoading = true;

    if (this.validateEmptyField()) {
      let { data, error } = await PutUpdateSymptom({
        name: String(this.editedData.name),
        id: String(this.editedData.symptomsID)
      });

      this.editedData.isLoading = false;

      if (data) {
        commonStore.showSuccessMessage("Symptom updated successfully!");
        setTimeout(() => {
          window.location.href = "/symptom";
        }, 500);
      } else if (error) {
        if (error.data) {
          commonStore.showErrorMessage(error.data.message);
        } else {
          commonStore.showErrorMessage("Something went wrong.");
        }
      }
    }
  };

  putDeleteSymptom = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await PutDeleteSymptom({
      id: String(this.editedData.symptomsID)
    });

    this.editedData.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Symptom deleted successfully!");
      setTimeout(() => {
        window.location.href = "/symptom";
      }, 500);
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  //COMPUTED
}

decorate(SymptomsStore, {
  //OBSERVABLE
  editedData: observable,
  dataArray: observable,
  pageSize: observable,
  currentPage: observable,
  totalCount: observable,
  searchQuery: observable,
  sorting: observable,

  //ACTION
  postSymptom: action,
  setEditedData: action,
  getAllSymptom: action,
  setSorting: action

  //COMPUTED
});

export default new SymptomsStore();
