import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllBroadcast({
  offset,
  limit,
  filter,
  startDate,
  endDate
}) {
  try {
    let getAllBroadcastResponse = await axios.Get({
      url: `${adminUrl}/notifications?offset=${offset || ""}&limit=${limit ||
        ""}&filter=${filter || ""}&startDate=${startDate ||
        ""}&endDate=${endDate || ""}`
    });
    if (getAllBroadcastResponse.statusCode !== 200) {
      return { error: getAllBroadcastResponse.message };
    }

    return { data: getAllBroadcastResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetByIdBroadcast({ id }) {
  try {
    let getByIdBroadcastResponse = await axios.Get({
      url: `${adminUrl}/notifications/${id}`
    });
    if (getByIdBroadcastResponse.statusCode !== 200) {
      return { error: getByIdBroadcastResponse.message };
    }

    return { data: getByIdBroadcastResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PostBroadcast({
  title,
  shortDescription,
  description,
  date,
  recipient,
  imageUrl
}) {
  try {
    let postBroadcastResponse = await axios.Post({
      url: `${adminUrl}/onesignal/broadcast-all`,
      data: { title, shortDescription, description, date, recipient, imageUrl }
    });

    if (postBroadcastResponse.statusCode !== 200) {
      return { error: postBroadcastResponse.message };
    }

    return { data: postBroadcastResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function DeleteByIDBroadcast({ id }) {
  try {
    let deleteByIDBroadcastResponse = await axios.Delete({
      url: `${adminUrl}/onesignal/notification/${id}`
    });
    if (deleteByIDBroadcastResponse.statusCode !== 200) {
      return { error: deleteByIDBroadcastResponse.message };
    }

    return { data: deleteByIDBroadcastResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
