import {
  CreateAdmin,
  GetAllAdmin,
  GetByIDAdmin,
  PutUpdateAdmin,
  PutDeleteAdmin,
  PutUpdatePasswordAdmin
} from "../services/AdminService";
import { observable, action, decorate } from "mobx";
import commonStore from "./CommonStore";

class AdminStore {
  //OBSERVABLE
  dataArray = [];
  editedData = {
    isLoading: false,
    currentPage: 0,
    pageSize: 10,
    totalCount: 0,
    role: "admin",
    searchQuery: "",
    adminID: "",
    name: "",
    email: ""
  };
  roleEnum = [
    "admin", "siloamAdmin"
  ];

  columns = [
    {
      name: "adminID",
      title: "Admin ID"
    },
    {
      name: "name",
      title: "Name"
    },
    {
      name: "email",
      title: "Email"
    }
  ];

  columnWidths = [
    {
      columnName: "adminID",
      width: 200
    },
    {
      columnName: "name",
      width: 300
    },
    {
      columnName: "email",
      width: 300
    }
  ];

  disabledColumn = [
    {
      columnName: "name",
      sortingEnabled: false
    },
    {
      columnName: "email",
      sortingEnabled: false
    }
  ];

  sorting = [{ columnName: "adminID", direction: "asc" }];

  //ACTION
  clearEditedData = () => {
    this.editedData = {};
  };

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setCurrentPage = currentPage => {
    this.editedData.currentPage = currentPage;
    this.getAllDiabeticExpertList();
  };

  setSearchQuery = searchValue => {
    this.editedData.currentPage = 0;
    this.editedData.searchQuery = searchValue;
  };

  setSorting = sorting => {
    this.sorting = sorting;
  };

  getAllAdmin = async () => {
    this.editedData.isLoading = true;

    const { data, error } = await GetAllAdmin({
      offset: String(this.editedData.currentPage),
      limit: String(this.editedData.pageSize),
      search: String(this.editedData.searchQuery),
      role: String(this.editedData.role),
      order: this.sorting[0].direction
    });

    this.editedData.isLoading = false;

    if (data) {
      this.editedData.totalCount = Number(data.count);

      this.dataArray = data.admin.map(data => {
        return {
          ...data,
          adminID: Number(data.adminID)
        };
      });
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  getByIDAdmin = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await GetByIDAdmin({
      id: String(this.editedData.adminID)
    });

    if (data) {
      this.setEditedData({
        ...data,
        name: String(data.name),
        email: String(data.email),
        adminID: String(data.adminID),
        role: String(data.role)
      });

      return { data };
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  validateEmptyField() {
    if (
      this.editedData.name === "" ||
      this.editedData.email === "" ||
      this.editedData.password === ""
    ) {
      commonStore.showErrorMessage("All fields must be filled!");
      return false;
    } else return true;
  }

  createAdmin = async () => {
    this.editedData.isLoading = true;

    const { data, error } = await CreateAdmin({
      name: String(this.editedData.name),
      email: String(this.editedData.email),
      password: String(this.editedData.password),
      role: String(this.editedData.role)
    });

    this.editedData.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Admin created!");
      setTimeout(() => {
        window.location.href = "/admins";
      }, 500);
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  validateEmptyFieldUpdate() {
    if (this.editedData.name === "" || this.editedData.email === "") {
      commonStore.showErrorMessage("All fields must be filled!");
      return false;
    } else return true;
  }

  putUpdateAdmin = async () => {
    this.editedData.isLoading = true;

    const { data, error } = await PutUpdateAdmin({
      id: String(this.editedData.adminID),
      name: String(this.editedData.name),
      email: String(this.editedData.email)
    });

    this.editedData.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Admin data updated!");
      setTimeout(() => {
        window.location.href = "/admins";
      }, 500);
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  putUpdatePasswordAdmin = async ({ newPassword, confirmNewPassword }) => {
    this.editedData.isLoading = true;

    const { data, error } = await PutUpdatePasswordAdmin({
      id: String(this.editedData.adminID),
      newPassword: String(newPassword),
      confirmNewPassword: String(confirmNewPassword)
    });

    this.editedData.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Password updated!");
      setTimeout(() => {
        window.location.href = "/admins";
      }, 500);
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        if (error) {
          commonStore.showErrorMessage("Password must be at least 8 characters long.");
        }
        else {
          commonStore.showErrorMessage("Something went wrong.");
        }
      }
    }
  };

  putDeleteAdmin = async () => {
    this.editedData.isLoading = true;

    const { data, error } = await PutDeleteAdmin({
      id: String(this.editedData.adminID)
    });

    this.editedData.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Admin successfully deleted!");
      setTimeout(() => {
        window.location.href = "/admins";
      }, 500);
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };
}

decorate(AdminStore, {
  //observable
  editedData: observable,
  dataArray: observable,
  columnWidths: observable,
  columns: observable,
  sorting: observable,

  //action
  setEditedData: action,
  getAllAdmin: action,
  clearEditedData: action,
  createAdmin: action,
  getByIDAdmin: action,
  putDeleteAdmin: action,
  putUpdateAdmin: action,
  putUpdatePasswordAdmin: action,
  setSearchQuery: action,
  setCurrentPage: action,
  setSorting: action
});
export default new AdminStore();
