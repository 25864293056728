import {
  GetAllSymptomsRecord,
  GetSymptomsRecordById,
  PutUpdateSymptomsRecord,
  PutDeleteSymptomsRecord
} from "../services/SymptomsRecordService";
import { observable, decorate, action } from "mobx";
import moment from "moment";
import swal from "sweetalert2";
import commonStore from "./CommonStore";

class SymptomsRecordStore {
  editedData = {};
  dataArray = [];
  searchQuery = "";
  startDate = "";
  endDate = "";
  pageSize = 10;
  currentPage = 0;
  totalCount = 0;
  loading = false;
  columns = [
    { name: "symptomsRecordID", title: "Record ID" },
    { name: "date", title: "Date & Time" },
    { name: "symptompsName", title: "Symptomps Name" }
  ];
  columnWidths = [
    { columnName: "symptomsRecordID", width: 250 },
    { columnName: "date", width: 250 },
    { columnName: "symptompsName", width: 250 }
  ];
  disabledColumn = [
    { columnName: "date", sortingEnabled: false },
    { columnName: "symptompsName", sortingEnabled: false }
  ];
  sorting = [{ columnName: "symptomsRecordID", direction: "asc" }];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setStartDate = value => {
    this.currentPage = 0;
    this.startDate = value;
  };

  setSorting = updatedSorting => {
    // if (this.order === "DESC") {
    //   this.order = "ASC";
    // } else {
    //   this.order = "DESC";
    // }
    this.sorting = updatedSorting;
  };

  setEndDate = value => {
    this.currentPage = 0;
    this.endDate = value;
  };

  setSearchQuery = searchValue => {
    this.currentPage = 0;
    this.searchQuery = searchValue;
  };

  setCurrentPage = currentPage => {
    this.currentPage = currentPage;
    this.getAllSymptomsRecord();
  };

  getAllSymptomsRecord = async id => {
    this.authLoading = true;
    this.loading = true;
    let { data, error } = await GetAllSymptomsRecord(
      this.editedData.userId,
      this.startDate ? moment(this.startDate).format("YYYY-MM-DDThh:mm") : "",
      this.endDate ? moment(this.endDate).format("YYYY-MM-DDThh:mm") : "",
      this.searchQuery,
      this.currentPage,
      this.pageSize,
      this.sorting[0].direction
    );

    if (data) {
      this.loading = false;
      this.totalCount = data.count;
      this.dataArray = data.data.symptomsRecords.map(data => {
        const symptomsRecordID = data.symptomsRecordID
          ? Number(data.symptomsRecordID)
          : "-";
        const date = moment(data.date ? data.date : "").format(
          "DD MMM YYYY hh:mm:ss"
        );
        const symptompsName = data.symptom.name ? data.symptom.name : data.symptomsName;
        const symptomsId = data.symptom.symptomsID
          ? data.symptom.symptomsID
          : "";

        return {
          ...data,
          symptomsRecordID,
          date,
          symptompsName,
          symptomsId
        };
      });
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getSymptomsRecordById = async id => {
    this.authLoading = true;

    let { data, error } = await GetSymptomsRecordById(id);

    if (data) {
      this.setEditedData({
        symptomsRecordID: data.symptomsRecordID,
        symptomsId: data.symptom.symptomsID,
        date: moment(data.date).format("DD MMM YYYY hh:mm:ss"),
        description: data.description
      });
      return { data };
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  putUpdateSymptomsRecord = async (
    symptomsId,
    description,
    date,
    id,
    userId
  ) => {
    this.loading = true;
    let { data, error } = await PutUpdateSymptomsRecord(
      String(symptomsId),
      description,
      date,
      id
    );

    if (data) {
      this.loading = false;
      swal({
        title: "Success",
        text: "Symptoms Success Updated",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = `/patients/${userId}/healthtracker`;
        return { data };
      });
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  putDeleteSymptomsRecord = async (id, userId) => {
    let { data, error } = await PutDeleteSymptomsRecord(id);
    if (data) {
      swal({
        title: "Success",
        text: "Symptoms Record Success Deleted",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = `/patients/${userId}/healthtracker`;
        return { data };
      });
    } else {
      return { error };
    }
  };
}

decorate(SymptomsRecordStore, {
  editedData: observable,
  dataArray: observable,
  startDate: observable,
  endDate: observable,
  pageSize: observable,
  searchQuery: observable,
  currentPage: observable,
  totalCount: observable,
  loading: observable,

  getAllSymptomsRecord: action
});

export default new SymptomsRecordStore();
