import {
  getHealthDeclarationForm, getHealthDeclarationFormById,
  createHealthDeclarationForm, updateHealthDeclarationForm
} from "../services/HealthDeclarationFormService";
import { observable, decorate } from "mobx";
import commonStore from "./CommonStore";

class HealthDeclarationFormStore {
  editedData = {
    imageUrl: "",
    imgLoading: false,
    type: "optional",
  };
  dataArray = [];
  searchQuery = "";
  loading = false;
  pageSize = 10;
  currentPage = 0;
  totalCount = 0;
  columns = [
    { name: "id", title: "Form ID" },
    { name: "title", title: "Form Name" },
  ];
  columnWidths = [
    { columnName: "id", width: 250 },
    { columnName: "title", width: 450 },
  ];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setSearchQuery = searchValue => {
    this.currentPage = 0;
    this.searchQuery = searchValue;
  };

  setCurrentPage = currentPage => {
    this.currentPage = currentPage;
    this.getHealthDeclarationForm();
  };

  clearEditedData = () => {
    this.editedData = {
      imageUrl: "",
      imgLoading: false,
      type: "optional",
    };
  };

  getHealthDeclarationForm = async () => {
    this.loading = true;

    let { data, error } = await getHealthDeclarationForm(
      this.searchQuery,
      this.currentPage,
      this.pageSize,
    );

    if (data) {
      this.loading = false;
      this.totalCount = data.count;
      this.dataArray = data.form;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getHealthDeclarationFormById = async (formId) => {
    this.loading = true;

    let { data, error } = await getHealthDeclarationFormById(formId);

    if (data) {
      this.loading = false;
      this.editedData = data;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  createHealthDeclarationForm = async (history) => {
    this.loading = true;

    let { data, error } = await createHealthDeclarationForm(this.editedData);

    if (data) {
      this.loading = false;
      history.push("/health-declaration-forms");
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  updateHealthDeclarationForm = async (formId, history) => {
    this.loading = true;

    let { data, error } = await updateHealthDeclarationForm(formId, this.editedData);

    if (data) {
      this.loading = false;
      history.push("/health-declaration-forms");
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };
}

decorate(HealthDeclarationFormStore, {
  editedData: observable,
  dataArray: observable,
  searchQuery: observable,
  currentPage: observable,
  totalCount: observable,
  loading: observable,
});

export default new HealthDeclarationFormStore();
