import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllEducation(offset, limit, search, order, type) {
  try {
    let getAllEducation = await axios.Get({
      url: `${adminUrl}/educations?offset=${offset || ""}&limit=${
        limit || ""
      }&search=${search || ""}&order=${order.toUpperCase()}&type=${type || ""}`,
    });
    if (getAllEducation.statusCode !== 200) {
      return { error: getAllEducation.message };
    }

    return { data: getAllEducation.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetEducationById(id, lang) {
  try {
    let GetEducationById = await axios.Get({
      url: `${adminUrl}/educations/${id}?lang=${lang || ""}`,
    });
    if (GetEducationById.statusCode !== 200) {
      return { error: GetEducationById.message };
    }

    return { data: GetEducationById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PostEducation(editedData) {
  try {
    let postEducation = await axios.Post({
      url: `${adminUrl}/educations`,
      data: {
        ...editedData,
        tags: editedData.educationTagArray,
      },
    });

    if (postEducation.statusCode !== 200) {
      return { error: postEducation.message };
    }

    return { data: postEducation.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdateEducation(educationId, editedData) {
  try {
    let putUpdateEducation = await axios.Put({
      url: `${adminUrl}/educations/${educationId}`,
      data: {
        ...editedData,
        tags: editedData.educationTagArray,
      },
    });

    if (putUpdateEducation.statusCode !== 200) {
      return { error: putUpdateEducation.message };
    }

    return { data: putUpdateEducation.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutDeleteEducation(id) {
  try {
    let putDeleteEducation = await axios.Put({
      url: `${adminUrl}/educations/${id}/delete`,
    });

    if (putDeleteEducation.statusCode !== 200) {
      return { error: putDeleteEducation.message };
    }

    return { data: putDeleteEducation.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function NotifyUser(shortDescription, date, contentId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/onesignal/broadcast-all`,
      data: {
        title: "Ada artikel baru untuk Anda",
        shortDescription,
        description: shortDescription,
        date,
        recipient: "Patient Free, Patient Premium",
        type: "new_article_reminder",
        contentId,
      },
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
