import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllPatient({
  isDiabisaMember,
  phone,
  offset,
  limit,
  search,
}) {
  try {
    let getAllPatient = await axios.Get({
      url: `${adminUrl}/users?isDiabisaMember=${isDiabisaMember}&phone=${phone.replace('+', '')}&offset=${offset}&limit=${limit}&search=${search || ""}`,
    });
    if (getAllPatient.statusCode !== 200) {
      return { error: getAllPatient.message };
    }

    return { data: getAllPatient.data };
  } catch (e) {
    return { error: e };
  }
}
export async function GetByIDPatient(id) {
  try {
    let getByIDPatient = await axios.Get({
      url: `${adminUrl}/users/${id}`
    });
    if (getByIDPatient.statusCode !== 200) {
      return { error: getByIDPatient.message };
    }

    return { data: getByIDPatient.data };
  } catch (e) {
    return { error: e };
  }
}
export async function PutUpdatePatient(
  name,
  phoneNumber,
  imageUrl,
  diabeticType,
  gender,
  DOB,
  doctorId,
  isPremium,
  hospital,
  userActivity,
  id
) {
  try {
    let putUpdatePatient = await axios.Put({
      url: `${adminUrl}/users/${id}`,
      data: {
        name,
        phoneNumber,
        imageUrl,
        diabeticType,
        gender,
        DOB,
        doctorId,
        isPremium,
        hospital,
        userActivity,
      }
    });

    if (putUpdatePatient.statusCode !== 200) {
      return { error: putUpdatePatient.message };
    }

    return { data: putUpdatePatient.data };
  } catch (e) {
    return { error: e };
  }
}

export async function PutChangeUserDoctor(id, doctorId, startDate) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/users/${id}/doctor`,
      data: { doctorId, startDate }
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetPatientByDoctorID({
  offset,
  limit,
  search,
  isPremium,
  id
}) {
  try {
    let getPatientByDoctorID = await axios.Get({
      url: `${adminUrl}/users?doctorId=${id}&isPremium=${isPremium}&offset=${offset}&limit=${limit}&search=${search}`
    });
    if (getPatientByDoctorID.statusCode !== 200) {
      return { error: getPatientByDoctorID.message };
    }

    return { data: getPatientByDoctorID.data };
  } catch (e) {
    return { error: e };
  }
}

export async function PostPatient(
  name,
  phoneNumber,
  diabeticType,
  gender,
  DOB,
  isPremium,
  doctorId
) {
  try {
    let postPatient = await axios.Post({
      url: `${adminUrl}/users`,
      data: {
        name,
        phoneNumber,
        diabeticType,
        gender,
        DOB,
        isPremium,
        doctorId
      }
    });

    if (postPatient.statusCode !== 200) {
      return { error: postPatient.message };
    }

    return { data: postPatient.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
