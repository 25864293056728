import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllHabit(habitNature, type, search, offset, limit) {
  try {
    let getAllHabit = await axios.Get({
      url: `${adminUrl}/habits?habitNature=${habitNature || ''}&type=${type || ''}&search=${search || ''}&offset=${offset}&limit=${limit}&lang=ID&isDeleted=false`
    });

    if (getAllHabit.statusCode !== 200) {
      return { error: getAllHabit.message };
    }

    return { data: getAllHabit.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetHabitById(id) {
  try {
    let getHabitById = await axios.Get({
      url: `${adminUrl}/habits/${id}`
    });

    if (getHabitById.statusCode !== 200) {
      return { error: getHabitById.message };
    }

    return { data: getHabitById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function CreateHabit(editedData) {
  try {
    let createHabit = await axios.Post({
      url: `${adminUrl}/habits`,
      data: editedData,
    });

    if (createHabit.statusCode !== 200) {
      return { error: createHabit.message };
    }

    return { data: createHabit.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function UpdateHabit(id, editedData) {
  try {
    let updateHabit = await axios.Put({
      url: `${adminUrl}/habits/${id}`,
      data: editedData,
    });

    if (updateHabit.statusCode !== 200) {
      return { error: updateHabit.message };
    }

    return { data: updateHabit.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function DeleteHabit(id) {
  try {
    let deleteHabit = await axios.Put({
      url: `${adminUrl}/habits/${id}/delete`,
    });

    if (deleteHabit.statusCode !== 200) {
      return { error: deleteHabit.message };
    }

    return { data: deleteHabit.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
