import { GetUsers } from "../services/UserService";
import { decorate, observable } from "mobx";
class UsersStore{
  editedData = {};
  setEditedData = (updatedData) => {
      this.editedData = {
          ...this.editedData,
          ...updatedData
      };
  };
  getAllUsers = async()=>{
    this.authLoading= true;

    let { data, error } = await GetUsers();

    if (data) {
      this.setEditedData({
        listUser : data
      });
      return { data };
    }
    else {
      return { error }
    }
  };
}

decorate(UsersStore,{
  editedData: observable,
})
export default new UsersStore();
