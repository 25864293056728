import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllExercise({ offset, limit, search, order }) {
  try {
    let getAllExerciseResponse = await axios.Get({
      url: `${adminUrl}/exercises?offset=${offset}&limit=${limit}&search=${search ||
        ""}&order=${order.toUpperCase()}`
    });

    if (getAllExerciseResponse.statusCode !== 200) {
      return { error: getAllExerciseResponse.message };
    }

    return { data: getAllExerciseResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetByIdExercise({ id }) {
  try {
    let getByIDExerciseResponse = await axios.Get({
      url: `${adminUrl}/exercises/${id}`
    });

    if (getByIDExerciseResponse.statusCode !== 200) {
      return { error: getByIDExerciseResponse.message };
    }

    return { data: getByIDExerciseResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PostExercise({ name, caloriesPerMin }) {
  try {
    let postExerciseResponse = await axios.Post({
      url: `${adminUrl}/exercises`,
      data: { name, caloriesPerMin }
    });

    if (postExerciseResponse.statusCode !== 200) {
      return { error: postExerciseResponse.message };
    }

    return { data: postExerciseResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdateExercise({ name, caloriesPerMin, id }) {
  try {
    let putUpdateExerciseResponse = await axios.Put({
      url: `${adminUrl}/exercises/${id}`,
      data: { name, caloriesPerMin }
    });

    if (putUpdateExerciseResponse.statusCode !== 200) {
      return { error: putUpdateExerciseResponse.message };
    }

    return { data: putUpdateExerciseResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutDeleteExercise({ id }) {
  try {
    let putDeleteExerciseResponse = await axios.Put({
      url: `${adminUrl}/exercises/${id}/delete`
    });

    if (putDeleteExerciseResponse.statusCode !== 200) {
      return { error: putDeleteExerciseResponse.message };
    }

    return { data: putDeleteExerciseResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
