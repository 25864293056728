import React, { Component } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown } from "reactstrap";
import PropTypes from "prop-types";
import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler
} from "@coreui/react";
import { inject, observer } from "mobx-react";
import logo from "../../assets/img/logo.svg";

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class DefaultHeader extends Component {
  logout = () => {
    this.props.authStore.signOut();
  };

  render() {
    // const { children, ...attributes } = this.props;
    const currentUser = this.props.authStore.currentUser;
    let username = "admin@admin.com";
    if (currentUser) {
      username = currentUser.name;
    }

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand full={{ src: logo, alt: "Diabetic Logo" }} />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto" navbar style={{ marginRight: "35px" }}>
          <AppHeaderDropdown className="sm-min-width-0" direction="down">
          <UncontrolledDropdown>
            <DropdownToggle nav className="d-flex align-items-center">
              <span
                className="fa fa-user btn-icon-user"
                style={{ marginRight: "10px", marginTop: "3px" }}
              />
              <label className="d-sm-down-none m-md-auto cursor-point color-white">
                {username}&nbsp;
              </label>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem className="align-items-center">
                <div onClick={this.logout}>
                  <i className="fa fa-sign-out color-primary" /> Logout
                </div>
              </DropdownItem>
            </DropdownMenu>
            </UncontrolledDropdown>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default inject("authStore")(observer(DefaultHeader));
