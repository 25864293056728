// import axios from "axios";
import axios from "../helper/Axios.js";
import { gtnUploadImageUrl } from "../config/config.js";

export async function uploadImage(files, uploader) {
  try {
    const formData = new FormData();
    formData.append("files[]", files);
    formData.append("uploader", uploader);

    const response = await axios.PostGTN({
      url: `${gtnUploadImageUrl}`,
      data: formData,
    });

    if (response.status !== 200) {
      return { error: response.message };
    }

    return { data: response.data[0] };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
