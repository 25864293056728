import Moment from "moment";
import React from "react";
import { observable, decorate, action } from "mobx";
import swal from "sweetalert2";
import {
  GetAllBloodGlucose,
  GetBloodGlucoseByID,
  PutUpdateBloodGlucose,
} from "../services/BloodGlucoseService";
import commonStore from "./CommonStore";

class BloodGlucoseStore {
  editedData = {};
  dataArray = [];
  typeValue = "";
  periodValue = "";
  statusValue = "";
  searchQuery = "";
  startDate = "";
  endDate = "";
  pageSize = 10;
  totalCount = 0;
  currentPage = 0;
  loading = false;
  bloodGlucoseBeforeBreakfastMinTarget = 0;
  bloodGlucoseBeforeBreakfastMaxTarget = 0;
  bloodGlucoseBeforeLunchDinnerMinTarget = 0;
  bloodGlucoseBeforeLunchDinnerMaxTarget = 0;
  bloodGlucoseAfterMealMinTarget = 0;
  bloodGlucoseAfterMealMaxTarget = 0;
  bloodGlucoseBedTimeMinTarget = 0;
  bloodGlucoseBedTimeMaxTarget = 0;
  bloodGlucoseHba1cMinTarget = 0;
  bloodGlucoseHba1cMaxTarget = 0;
  valueTransformer = {
    status: (data) => {
      if (data.status === "hypo") {
        return <span className="hypo-span">Hypo</span>;
      } else if (data.status === "good") {
        return <span />;
      } else if (data.status === "hyper") {
        return <span className="nonactive-span">Hyper</span>;
      } else if (data.status === "below") {
        return <span className="nonactive-span">Below</span>;
      } else {
        return <span className="nonactive-span">Above</span>;
      }
    },
    period: (data) => {
      if (data.period === "beforeBreakfast") {
        return <span>Before Breakfast</span>;
      } else if (data.period === "afterBreakfast") {
        return <span>After Breakfast</span>;
      } else if (data.period === "beforeLunch") {
        return <span>Before Lunch</span>;
      } else if (data.period === "afterLunch") {
        return <span>After Lunch</span>;
      } else if (data.period === "beforeDinner") {
        return <span>Before Dinner</span>;
      } else if (data.period === "afterDinner") {
        return <span>After Dinner</span>;
      } else if (data.period === "midnight") {
        return <span>Midnight</span>;
      } else if (data.period === "bedtime") {
        return <span>Bedtime</span>;
      } else if (data.period === "morning") {
        return <span>Morning</span>;
      }
    },
    type: (data) => {
      if (data.type === "bloodGlucose") {
        return <span>Blood Glucose</span>;
      } else if (data.type === "hba1c") {
        return <span>HbA1C</span>;
      }
    },
  };
  categoryEnum = [
    { name: "Before Meal", value: "beforeMeal" },
    { name: "After Meal", value: "afterMeal" },
    { name: "Bedtime", value: "bedtime" },
    { name: "hba1c", value: "HbA1C" },
  ];
  statusEnum = [
    { name: "Hypo", value: "hypo" },
    { name: "Good", value: "good" },
    { name: "Hyper", value: "hyper" },
  ];
  typeEnum = [
    { name: "Blood Glucose", value: "bloodGlucose" },
    { name: "HbA1C", value: "hba1c" },
  ];
  periodEnum = [
    { name: "Before Breakfast", value: "beforeBreakfast" },
    { name: "After Breakfast", value: "afterBreakfast" },
    { name: "Before Lunch", value: "beforeLunch" },
    { name: "After Lunch", value: "afterLunch" },
    { name: "Before Dinner", value: "beforeDinner" },
    { name: "After Dinner", value: "afterDinner" },
    { name: "Midnight", value: "midnight" },
    { name: "Bedtime", value: "bedtime" },
    { name: "Morning", value: "morning" },
  ];
  columns = [
    { name: "bloodGlucoseRecordID", title: "Record ID" },
    { name: "date", title: "Date and Time" },
    { name: "value", title: "Value" },
    {
      name: "type",
      title: "Type",
      valueTransformer: this.valueTransformer["type"],
    },
    {
      name: "period",
      title: "Period",
      valueTransformer: this.valueTransformer["period"],
    },
    {
      name: "status",
      title: "Status",
      valueTransformer: this.valueTransformer["status"],
    },
  ];
  columnWidths = [
    { columnName: "bloodGlucoseRecordID", width: 200 },
    { columnName: "date", width: 200 },
    { columnName: "value", width: 100 },
    { columnName: "type", width: 200 },
    { columnName: "period", width: 200 },
    { columnName: "status", width: 200 },
  ];
  disabledColumn = [
    { columnName: "date", sortingEnabled: false },
    { columnName: "value", sortingEnabled: false },
    { columnName: "type", sortingEnabled: false },
    { columnName: "period", sortingEnabled: false },
    { columnName: "status", sortingEnabled: false },
  ];
  sorting = [{ columnName: "bloodGlucoseRecordID", direction: "asc" }];

  setStartDate = (value) => {
    this.currentPage = 0;
    this.startDate = value;
  };

  setEndDate = (value) => {
    this.currentPage = 0;
    this.endDate = value;
  };

  setTypeValue = (value) => {
    this.currentPage = 0;
    this.typeValue = value;
  };

  setPeriodValue = (value) => {
    this.currentPage = 0;
    this.periodValue = value;
  };

  setStatusValue = (value) => {
    this.currentPage = 0;
    this.statusValue = value;
  };

  setEditedData = (updatedData) => {
    this.editedData = {
      ...this.editedData,
      ...updatedData,
    };
  };

  setSearchQuery = (searchValue) => {
    this.currentPage = 0;
    this.searchQuery = searchValue;
  };

  setSorting = (updatedSorting) => {
    this.sorting = updatedSorting;
  };

  setCurrentPage = (currentPage) => {
    this.currentPage = currentPage;
    this.getAllBloodGlucoseByUserId();
  };

  getAllBloodGlucoseByUserId = async () => {
    this.authLoading = true;
    this.loading = true;
    let { data, error } = await GetAllBloodGlucose(
      this.editedData.userId,
      this.typeValue,
      this.periodValue,
      this.statusValue,
      this.searchQuery,
      this.startDate ? Moment(this.startDate).format("YYYY-MM-DDTHH:mm") : "",
      this.endDate ? Moment(this.endDate).format("YYYY-MM-DDTHH:mm") : "",
      this.currentPage,
      this.pageSize,
      this.sorting[0].direction
    );

    if (data) {
      this.loading = false;
      this.totalCount = data.count;
      this.dataArray = data.data.bloodGlucoseRecord.map((data) => {
        const bloodGlucoseRecordID = data.bloodGlucoseRecordID
          ? Number(data.bloodGlucoseRecordID)
          : "";
        const date =
          data && data.date
            ? Moment(data.date).format("DD MMM YYYY hh:mm:ss")
            : "";
        const value = data.value ? data.value : "";
        const type = data.type ? data.type : "";
        const period = data.period ? data.period : "";
        const status = data.status ? data.status : "";

        return {
          ...data,
          bloodGlucoseRecordID,
          date,
          value,
          type,
          period,
          status,
        };
      });
      this.bloodGlucoseBeforeBreakfastMinTarget = data.data.target
        .bloodGlucoseBeforeBreakfastMinTarget
        ? data.data.target.bloodGlucoseBeforeBreakfastMinTarget
        : 0;
      this.bloodGlucoseBeforeBreakfastMaxTarget = data.data.target
        .bloodGlucoseBeforeBreakfastMaxTarget
        ? data.data.target.bloodGlucoseBeforeBreakfastMaxTarget
        : 0;
      this.bloodGlucoseBeforeLunchDinnerMinTarget = data.data.target
        .bloodGlucoseBeforeLunchDinnerMinTarget
        ? data.data.target.bloodGlucoseBeforeLunchDinnerMinTarget
        : 0;
      this.bloodGlucoseBeforeLunchDinnerMaxTarget = data.data.target
        .bloodGlucoseBeforeLunchDinnerMaxTarget
        ? data.data.target.bloodGlucoseBeforeLunchDinnerMaxTarget
        : 0;
      this.bloodGlucoseAfterMealMinTarget = data.data.target
        .bloodGlucoseAfterMealMinTarget
        ? data.data.target.bloodGlucoseAfterMealMinTarget
        : 0;
      this.bloodGlucoseAfterMealMaxTarget = data.data.target
        .bloodGlucoseAfterMealMaxTarget
        ? data.data.target.bloodGlucoseAfterMealMaxTarget
        : 0;
      this.bloodGlucoseBedTimeMinTarget = data.data.target
        .bloodGlucoseBedTimeMinTarget
        ? data.data.target.bloodGlucoseBedTimeMinTarget
        : 0;
      this.bloodGlucoseBedTimeMaxTarget = data.data.target
        .bloodGlucoseBedTimeMaxTarget
        ? data.data.target.bloodGlucoseBedTimeMaxTarget
        : 0;
      this.bloodGlucoseHba1cMinTarget = data.data.target
        .bloodGlucoseHba1cMinTarget
        ? data.data.target.bloodGlucoseHba1cMinTarget
        : 0;
      this.bloodGlucoseHba1cMaxTarget = data.data.target
        .bloodGlucoseHba1cMaxTarget
        ? data.data.target.bloodGlucoseHba1cMaxTarget
        : 0;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };

  getBloodGlucoseByID = async (id) => {
    this.authLoading = true;

    let { data, error } = await GetBloodGlucoseByID(id);

    if (data) {
      this.setEditedData({
        bloodGlucoseRecordId: data.bloodGlucoseRecordID,
        date: Moment(data.date).format("DD MMM YYYY hh:mm:ss"),
        value: data.value,
        periode: data.period,
        category: data.category,
        status: data.status,
        notes: data.notes,
        pageTitle: "Record " + data.bloodGlucoseRecordID,
        userUserID: data.userUserID,
      });
      return { data };
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };

  putUpdateBloodGlucose = async (
    value,
    date,
    category,
    periode,
    notes,
    status,
    userId,
    id
  ) => {
    this.authLoading = true;
    this.loading = true;
    let { data, error } = await PutUpdateBloodGlucose(
      value,
      date,
      category,
      periode,
      notes,
      status,
      userId,
      id
    );
    this.authLoading = false;
    if (data) {
      this.loading = false;
      swal({
        title: "Success",
        text: "Blood Glucose Success Updated",
        icon: "success",
        button: "OK",
      }).then((value) => {
        window.location.href = "/patients/" + userId + "/healthtracker";
        return { data };
      });
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };

  // putDeleteBloodGlucose = async(id) => {
  //     this.authLoading= true;
  //
  //     let { data, error } = await PutDeleteBloodGlucose(
  //         id
  //     );
  //     this.authLoading = false;
  //     if(data){
  //         swal({
  //           title: "Success",
  //           text: "BloodGlucose Success Deleted",
  //           icon: "success",
  //           button: "OK",
  //       }).then((value)=>{
  //           window.location.href = "/blood-glucose-list";
  //           return { data };
  //         }
  //       );
  //
  //     }
  //     else{
  //       return { error };
  //     }
  // }
}

decorate(BloodGlucoseStore, {
  editedData: observable,
  dataArray: observable,
  bloodGlucoseBeforeBreakfastMinTarget: observable,
  bloodGlucoseBeforeBreakfastMaxTarget: observable,
  bloodGlucoseAfterMealMinTarget: observable,
  bloodGlucoseAfterMealMaxTarget: observable,
  bloodGlucoseBedTimeMinTarget: observable,
  bloodGlucoseBedTimeMaxTarget: observable,
  bloodGlucoseHba1cMinTarget: observable,
  bloodGlucoseHba1cMaxTarget: observable,
  typeValue: observable,
  periodValue: observable,
  statusValue: observable,
  startDate: observable,
  endDate: observable,
  totalCount: observable,
  searchQuery: observable,
  loading: observable,
  sorting: observable,

  getAllBloodGlucoseByUserId: action,
});
export default new BloodGlucoseStore();
