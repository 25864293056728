import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetSubscribe(deviceId, deviceType) {
  try {
    let getSubscribe = await axios.Get({
      url: `${adminUrl}/onesignal/subscribe/device/${deviceId}/type/${deviceType}`
    });

    if (getSubscribe.statusCode !== 200) {
      return { error: getSubscribe.message };
    }

    return { data: getSubscribe.data };
  } catch (e) {
    return { error: e };
  }
}

export async function GetUnsubscribe(deviceId, deviceType) {
  try {
    let getUnsubscribe = await axios.Get({
      url: `${adminUrl}/onesignal/unsubscribe/device/${deviceId}/type/${deviceType}`
    });

    if (getUnsubscribe.statusCode !== 200) {
      return { error: getUnsubscribe.message };
    }

    return { data: getUnsubscribe.data };
  } catch (e) {
    return { error: e };
  }
}
