import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllSymptom({ offset, limit, search, order }) {
  try {
    let getAllSymptomResponse = await axios.Get({
      url: `${adminUrl}/symptoms?offset=${offset || ""}&limit=${limit ||
        ""}&search=${search || ""}&order=${order.toUpperCase()}`
    });

    if (getAllSymptomResponse.statusCode !== 200) {
      return { error: getAllSymptomResponse.message };
    }

    return { data: getAllSymptomResponse.data };
  } catch (e) {
    return { error: e };
  }
}

export async function GetByIDSymptom({ id }) {
  try {
    let getByIDSymptomResponse = await axios.Get({
      url: `${adminUrl}/symptoms/${id}`
    });

    if (getByIDSymptomResponse.statusCode !== 200) {
      return { error: getByIDSymptomResponse.message };
    }

    return { data: getByIDSymptomResponse.data };
  } catch (e) {
    return { error: e };
  }
}

export async function PostSymptom({ name }) {
  try {
    let postSymptomResponse = await axios.Post({
      url: `${adminUrl}/symptoms`,
      data: { name }
    });

    if (postSymptomResponse.statusCode !== 200) {
      return { error: postSymptomResponse.message };
    }

    return { data: postSymptomResponse.data };
  } catch (e) {
    return { error: e };
  }
}

export async function PutUpdateSymptom({ name, id }) {
  try {
    let putUpdateSymptomResponse = await axios.Put({
      url: `${adminUrl}/symptoms/${id}`,
      data: { name }
    });

    if (putUpdateSymptomResponse.statusCode !== 200) {
      return { error: putUpdateSymptomResponse.message };
    }

    return { data: putUpdateSymptomResponse.data };
  } catch (e) {
    return { error: e };
  }
}

export async function PutDeleteSymptom({ id }) {
  try {
    let putDeleteSymptomResponse = await axios.Put({
      url: `${adminUrl}/symptoms/${id}/delete`
    });

    if (putDeleteSymptomResponse.statusCode !== 200) {
      return { error: putDeleteSymptomResponse.message };
    }

    return { data: putDeleteSymptomResponse.data };
  } catch (e) {
    return { error: e };
  }
}
