import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllBloodGlucose(
  id,
  type,
  period,
  status,
  search,
  startDate,
  endDate,
  offset,
  limit,
  order
) {
  try {
    let getAllBloodGlucoseByID = await axios.Get({
      url: `${adminUrl}/blood-glucose-records?userId=${id}&type=${type}&period=${period}&status=${status}&search=${search ||
        ""}&startDate=${startDate}&endDate=${endDate}&offset=${offset}&limit=${limit}&order=${order.toUpperCase()}`
    });
    if (getAllBloodGlucoseByID.statusCode !== 200) {
      return { error: getAllBloodGlucoseByID.message };
    }

    return { data: getAllBloodGlucoseByID.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetBloodGlucoseByID(id) {
  try {
    let getBloodGlucoseByID = await axios.Get({
      url: `${adminUrl}/blood-glucose-records/` + id
    });
    if (getBloodGlucoseByID.statusCode !== 200) {
      return { error: getBloodGlucoseByID.message };
    }

    return { data: getBloodGlucoseByID.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdateBloodGlucose(
  value,
  date,
  category,
  period,
  notes,
  status,
  userId,
  id
) {
  try {
    let putUpdateBloodGlucose = await axios.Put({
      url: `${adminUrl}/blood-glucose-records/${id}`,
      data: {
        value,
        date,
        category,
        period,
        notes,
        status,
        userId
      }
    });

    if (putUpdateBloodGlucose.statusCode !== 200) {
      return { error: putUpdateBloodGlucose.message };
    }

    return { data: putUpdateBloodGlucose.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutDeleteBloodGlucose(id) {
  try {
    let putDeleteBloodGlucose = await axios.Put({
      url: `${adminUrl}/blood-glucose-records/delete/${id}`
    });

    if (putDeleteBloodGlucose.statusCode !== 200) {
      return { error: putDeleteBloodGlucose.message };
    }

    return { data: putDeleteBloodGlucose.data };
  } catch (e) {
    return { error: e };
  }
}
