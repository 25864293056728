import {
  GetAllMeal,
  GetByIDMeal,
  PostMeal,
  PutUpdateMeal,
  PutDeleteMeal
} from "../services/MealService";
import { observable, action, decorate } from "mobx";
import commonStore from "./CommonStore";

class MealStore {
  //OBSERVABLE
  dataArray = [];
  editedData = {
    isLoading: false,
    currentPage: 0,
    totalCount: 0,
    name: "",
    calories: "",
    servingSize: "",
    gram: "",
    carb: "",
    protein: "",
    fat: "",
    searchQuery: "",
    pageSize: 10
  };
  columns = [
    {
      name: "mealID",
      title: "Meal ID"
    },
    {
      name: "name",
      title: "Meal Name"
    },
    {
      name: "calories",
      title: "Calories (cal)"
    },
    {
      name: "servingSize",
      title: "Serving Size"
    }
  ];

  columnWidths = [
    {
      columnName: "mealID",
      width: 150
    },
    {
      columnName: "name",
      width: 300
    },
    {
      columnName: "calories",
      width: 200
    },
    {
      columnName: "servingSize",
      width: 200
    }
  ];

  disabledColumn = [
    {
      columnName: "name",
      sortingEnabled: false
    },
    {
      columnName: "calories",
      sortingEnabled: false
    },
    {
      columnName: "servingSize",
      sortingEnabled: false
    }
  ];

  sorting = [{ columnName: "mealID", direction: "asc" }];

  //ACTION
  clearEditedData = () => {
    this.editedData = {};
  };

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setPageLimit = value => {
    this.editedData.pageSize = value;
  };

  setCurrentPage = currentPage => {
    this.editedData.currentPage = currentPage;
    this.getAllMeal();
  };

  setSearchQuery = searchValue => {
    this.editedData.currentPage = 0;
    this.editedData.searchQuery = searchValue;
  };

  setSorting = sorting => {
    this.sorting = sorting;
  };

  getAllMeal = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await GetAllMeal({
      offset: Number(this.editedData.currentPage),
      limit: this.editedData.pageSize,
      search: String(this.editedData.searchQuery),
      order: this.sorting[0].direction
    });

    if (data) {
      this.editedData.isLoading = false;

      this.editedData.totalCount = Number(data.count);
      this.dataArray = data.meal.map(data => {
        return {
          ...data,
          mealID: Number(data.mealID)
        };
      });
    } else if (error) {
      this.editedData.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  getByIDMeal = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await GetByIDMeal({
      id: String(this.editedData.mealID)
    });

    this.editedData.isLoading = false;

    if (data) {
      this.setEditedData({
        mealID: Number(data.mealID),
        name: String(data.name),
        calories: String(data.calories),
        servingSize: String(data.servingSize),
        gram: String(data.gram),
        carb: String(data.carb),
        protein: String(data.protein),
        fat: String(data.fat)
      });
      return data;
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  validateEmptyField() {
    if (
      this.editedData.name === "" ||
      this.editedData.calories === "" ||
      this.editedData.servingSize === "" ||
      this.editedData.gram === "" ||
      this.editedData.carb === "" ||
      this.editedData.protein === "" ||
      this.editedData.fat === ""
    ) {
      commonStore.showErrorMessage("All fields must be filled!");
      return false;
    } else return true;
  }

  postMeal = async () => {
    this.editedData.isLoading = true;

    if (this.validateEmptyField() === true) {
      let { data, error } = await PostMeal({
        name: String(this.editedData.name),
        calories: Number(this.editedData.calories),
        servingSize: String(this.editedData.servingSize),
        gram: String(this.editedData.gram),
        carb: Number(this.editedData.carb),
        protein: Number(this.editedData.protein),
        fat: Number(this.editedData.fat)
      });

      this.editedData.isLoading = false;

      if (data) {
        commonStore.showSuccessMessage("Meal added successfully!");
        setTimeout(() => {
          window.location.href = "/meals";
        }, 500);
      } else if (error) {
        if (error.data) {
          commonStore.showErrorMessage(error.data.message);
        } else {
          commonStore.showErrorMessage("Something went wrong.");
        }
      }
    }
  };

  putUpdateMeal = async () => {
    this.editedData.isLoading = true;

    if (this.validateEmptyField()) {
      let { data, error } = await PutUpdateMeal({
        name: String(this.editedData.name),
        calories: Number(this.editedData.calories),
        servingSize: String(this.editedData.servingSize),
        gram: String(this.editedData.gram),
        carb: Number(this.editedData.carb),
        protein: Number(this.editedData.protein),
        fat: Number(this.editedData.fat),
        id: String(this.editedData.mealID)
      });

      this.editedData.isLoading = false;

      if (data) {
        commonStore.showSuccessMessage("Meal updated successfully!");
        setTimeout(() => {
          window.location.href = "/meals";
        }, 500);
      } else if (error) {
        if (error.data) {
          commonStore.showErrorMessage(error.data.message);
        } else {
          commonStore.showErrorMessage("Something went wrong.");
        }
      }
    }
  };

  putDeleteMeal = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await PutDeleteMeal({
      id: String(this.editedData.mealID)
    });

    this.editedData.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Meal deleted successfully!");
      setTimeout(() => {
        window.location.href = "/meals";
      }, 500);
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  //COMPUTED
}

decorate(MealStore, {
  //OBSERVABLE
  editedData: observable,
  dataArray: observable,
  pageSize: observable,
  currentPage: observable,
  totalCount: observable,
  searchQuery: observable,
  sorting: observable,

  //ACTION
  getAllMeal: action,
  getByIDMeal: action,
  postMeal: action,
  setEditedData: action,
  setSorting: action,
  setPageLimit: action

  //COMPUTED
});

export default new MealStore();
