import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllMedicine({ offset, limit, search, order }) {
  try {
    let getAllMedicineResponse = await axios.Get({
      url: `${adminUrl}/medicines?offset=${offset}&limit=${limit}&search=${search ||
        ""}&order=${order.toUpperCase()}`
    });

    if (getAllMedicineResponse.statusCode !== 200) {
      return { error: getAllMedicineResponse.message };
    }

    return { data: getAllMedicineResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetByIDMedicine({ id }) {
  try {
    let getByIDMedicineResponse = await axios.Get({
      url: `${adminUrl}/medicines/${id}`
    });

    if (getByIDMedicineResponse.statusCode !== 200) {
      return { error: getByIDMedicineResponse.message };
    }

    return { data: getByIDMedicineResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PostMedicine({ name, isAntiDiabetic }) {
  try {
    let postMedicineResponse = await axios.Post({
      url: `${adminUrl}/medicines`,
      data: { name, isAntiDiabetic }
    });

    if (postMedicineResponse.statusCode !== 200) {
      return { error: postMedicineResponse.message };
    }

    return { data: postMedicineResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdateMedicine({ name, isAntiDiabetic, id }) {
  try {
    let putUpdateMedicineResponse = await axios.Put({
      url: `${adminUrl}/medicines/${id}`,
      data: { name, isAntiDiabetic }
    });

    if (putUpdateMedicineResponse.statusCode !== 200) {
      return { error: putUpdateMedicineResponse.message };
    }

    return { data: putUpdateMedicineResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutDeleteMedicine({ id }) {
  try {
    let putDeleteMedicineResponse = await axios.Put({
      url: `${adminUrl}/medicines/${id}/delete/`
    });

    if (putDeleteMedicineResponse.statusCode !== 200) {
      return { error: putDeleteMedicineResponse.message };
    }

    return { data: putDeleteMedicineResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
