import {
  GetAllPromotionBanner,
  GetPromotionBannerById,
  CreatePromotionBanner,
  UpdatePromotionBanner
} from "../services/BannerService";
import { observable, action, decorate } from "mobx";
import commonStore from "./CommonStore";

class BannerStore {
  editedData = {
    // LIST
    dataArray: [],
    isLoading: false,
    currentPage: 0,
    pageSize: 10,
    totalCount: 0,
    type: "All",
    isActive: "",
    description: "",
    isActiveEnum: [
      {
        value: "true",
        label: "Active"
      },
      {
        value: "false",
        label: "Inactive"
      },
    ],
    typeEnum: [
      {
        value: "All",
        label: "All"
      },
      {
        value: "popUpBanner",
        label: "Pop Up Banner"
      },
    ],

    // DETAIL
    typeEnumAdd: [
      {
        value: "popUpBanner",
        label: "Pop Up Banner"
      },
    ],
    isActiveAdd: "true",
    promotionBannerId: "",
    title: "",
    link: "",
    typeAdd: "promotionBanner",
    imgLoading: false,
    tempImgUrl: null,
    imageUrl: "",
    startDate: "",
    endDate: ""
  };
  columns = [
    {
      name: "id",
      title: "Banner ID"
    },
    {
      name: "title",
      title: "Banner Title / Link"
    },
    {
      name: "type",
      title: "Banner Type"
    },
    {
      name: "isActive",
      title: "Is Active"
    },
  ];
  columnWidths = [
    {
      columnName: "id",
      width: 200
    },
    {
      columnName: "title",
      width: 300
    },
    {
      columnName: "type",
      width: 300
    },
    {
      columnName: "isActive",
      width: 300
    },
  ];
  disabledColumn = [
    {
      columnName: "title",
      sortingEnabled: false
    },
    {
      columnName: "type",
      sortingEnabled: false
    }
  ];
  sorting = [
    { columnName: "id", direction: "asc" }
  ];
  redirectType = "url";
  redirectTypeEnum = [
    { value: 'url', name: "With URL" },
    { value: 'page', name: "With Page" },
  ];

  //ACTION
  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setCurrentPage = currentPage => {
    this.editedData.currentPage = currentPage;
    this.getAllPromotionBanner();
  };

  setSorting = sorting => {
    this.sorting = sorting;
  };

  setRedirectType = value => {
    if (value === 'url') {
      this.editedData.pageId = null;
    } else if (value === 'page') {
      this.editedData.link = null;
    }
    this.redirectType = value;
  };

  clearQuery = async () => {
    this.editedData.type = "";
    this.editedData.isActive = "All";
  }

  getAllPromotionBanner = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await GetAllPromotionBanner({
      offset: Number(this.editedData.currentPage),
      limit: this.editedData.pageSize,
      type: this.editedData.type,
      isActive: this.editedData.isActive
    });

    if (data) {
      this.editedData.isLoading = false;

      this.editedData.totalCount = Number(data.count);

      this.editedData.dataArray = data.promotionBanner.map(tempData => {
        return {
          ...tempData,
          title: tempData.title ? tempData.title : tempData.url
        };
      });
    } else if (error) {
      this.editedData.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  getPromotionBannerById = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await GetPromotionBannerById({
      id: this.editedData.promotionBannerId
    });

    if (data) {
      this.editedData.isLoading = false;

      if (data.url && data.url !== '') {
        this.redirectType = 'url';
      } else {
        this.redirectType = 'page';
      }

      this.setEditedData({
        title: String(data.title),
        order: String(data.order),
        link: data.url,
        isActiveAdd: String(data.isActive),
        typeAdd: String(data.type),
        tempImgUrl: String(data.imageUrl),
        imageUrl: String(data.imageUrl),
        startDate: data.startDate,
        endDate: data.endDate,
        description: data.description ? data.description : "",
        pageId: data.pageId,
      });
    } else if (error) {
      this.editedData.isLoading = false;
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  createPromotionBanner = async () => {
    this.editedData.isLoading = true;
    const { typeAdd, order, startDate, endDate, title, description, link, imageUrl, isActiveAdd, pageId } = this.editedData;

    let { data, error } = await CreatePromotionBanner({
      type: typeAdd, order, startDate, endDate, title, description, url: link, imageUrl, isActive: isActiveAdd, pageId
    });

    if (data) {
      this.editedData.isLoading = false;

      commonStore.showSuccessMessage("Promotion Banner added successfully!");
      setTimeout(() => {
        window.location.href = "/banner";
      }, 500);
    } else if (error) {
      this.editedData.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  updatePromotionBanner = async () => {
    this.editedData.isLoading = true;
    const { promotionBannerId, typeAdd, order, startDate, endDate, title, description, link, imageUrl, isActiveAdd, pageId } = this.editedData;

    let { data, error } = await UpdatePromotionBanner({
      id: promotionBannerId, type: typeAdd, order, startDate, endDate, title, description, url: link, imageUrl, isActive: isActiveAdd, pageId
    });

    if (data) {
      this.editedData.isLoading = false;

      commonStore.showSuccessMessage("Promotion Banner added successfully!");
      setTimeout(() => {
        window.location.href = "/banner";
      }, 500);
    } else if (error) {
      this.editedData.isLoading = false;
      if (error.data) {
        commonStore.showErrorMessage(error.data);
      }
      else {
        commonStore.showErrorMessage(error);
      }
    }
  };
}

decorate(BannerStore, {
  //OBSERVABLE
  editedData: observable,
  sorting: observable,
  redirectType: observable,

  //ACTION
  setCurrentPage: action,
  setEditedData: action,
  setSorting: action
});

export default new BannerStore();
