import {
  GetAllDietPlan,
  GetByIDDietPlan,
  PostDietPlan,
  PutUpdateDietPlan,
  PutDeleteDietPlan,
  GetAllMealDietPlan,
  PostMealDietPlan,
  GetByIDMealDietPlan,
  PutUpdateMealDietPlan,
  PutDeleteMealDietPlan,
  PostDuplicateDietPlan
} from "../services/DietPlanService";
import { observable, action, decorate } from "mobx";
import swal from "sweetalert2";

class DietPlanStore {
  editedData = {};
  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  getAllDietPlan = async () => {
    this.authLoading = true;

    let { data, error } = await GetAllDietPlan();

    if (data) {
      return { data };
    } else {
      return { error };
    }
  };

  getByIDDietPlan = async id => {
    this.authLoading = true;

    let { data, error } = await GetByIDDietPlan(id);

    if (data) {
      this.setEditedData({
        name: data.name,
        pageTitle: data.name,
        author: data.author,
        numberOfDays: data.numberOfDays,
        description: data.description,
        calories: data.calories,
        tempImgUrl: data.imageUrl,
        type: data.type,
        dietPlanDietPlanID: data.dietPlanID
      });
      if (data.user) {
        this.setEditedData({ asignee: data.user.userID });
      }
      return { data };
    } else {
      return { error };
    }
  };

  postDietPlan = async (
    name,
    description,
    calories,
    author,
    imageUrl,
    type,
    userId,
    numberOfDays
  ) => {
    this.authLoading = true;
    let { data, error } = await PostDietPlan(
      name,
      description,
      calories,
      author,
      imageUrl,
      type,
      userId,
      numberOfDays
    );
    this.authLoading = false;
    if (data) {
      swal({
        title: "Success",
        text: "DietPlan Success Added",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = "/dietplans/" + data.dietPlanID + "/detail";
        return { data };
      });
    } else {
      return { error };
    }
  };
  putUpdateDietPlan = async (
    name,
    description,
    calories,
    author,
    imageUrl,
    type,
    userId,
    numberOfDays,
    id
  ) => {
    this.authLoading = true;
    let { data, error } = await PutUpdateDietPlan(
      name,
      description,
      calories,
      author,
      imageUrl,
      type,
      userId,
      numberOfDays,
      id
    );
    this.authLoading = false;
    if (data) {
      swal({
        title: "Success",
        text: "Diet Plan Success Updated",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = "/dietplans";
        return { data };
      });
    } else {
      return { error };
    }
  };

  putDeleteDietPlan = async id => {
    this.authLoading = true;

    let { data, error } = await PutDeleteDietPlan(id);
    this.authLoading = false;
    if (data) {
      swal({
        title: "Success",
        text: "Diet Plan Success Deleted",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = "/dietplans";
        return { data };
      });
    } else {
      return { error };
    }
  };

  //-------------------customized---------------------
  getAllMealDietPlan = async id => {
    this.authLoading = true;

    let { data, error } = await GetAllMealDietPlan(id);

    if (data) {
      return { data };
    } else {
      return { error };
    }
  };

  getByIDMealDietPlan = async id => {
    this.authLoading = true;

    let { data, error } = await GetByIDMealDietPlan(id);

    if (data) {
      this.setEditedData({
        numberOfDays: data.day,
        pageTitle: data.meal.name,
        mealType: data.type,
        mealOption: data.meal.mealID,
        servingSize: data.servingSize
      });
      return { data };
    } else {
      return { error };
    }
  };

  postMealDietPlan = async (type, day, mealId, dietPlanId, servingSize) => {
    this.authLoading = true;

    let { data, error } = await PostMealDietPlan(
      type,
      day,
      mealId,
      dietPlanId,
      servingSize
    );
    this.authLoading = false;
    if (data) {
      swal({
        title: "Success",
        text: "Meal Diet Plan Success Added",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = "/dietplans/" + dietPlanId + "/meal";
        return { data };
      });
    } else {
      return { error };
    }
  };

  putUpdateMealDietPlan = async (
    type,
    day,
    mealId,
    dietPlanId,
    servingSize,
    id
  ) => {
    this.authLoading = true;

    let { data, error } = await PutUpdateMealDietPlan(
      type,
      day,
      mealId,
      dietPlanId,
      servingSize,
      id
    );
    this.authLoading = false;
    if (data) {
      swal({
        title: "Success",
        text: "Diet Plan Success Updated",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = "/dietplans/" + dietPlanId + "/detail";
        return { data };
      });
    } else {
      return { error };
    }
  };
  putDeleteMealDietPlan = async (id, dietPlanId) => {
    this.authLoading = true;

    let { data, error } = await PutDeleteMealDietPlan(id);
    this.authLoading = false;
    if (data) {
      swal({
        title: "Success",
        text: "Diet Plan Success Deleted",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = "/dietplans/" + dietPlanId + "/detail";
        return { data };
      });
    } else {
      return { error };
    }
  };

  //-------------------Duplicate Diet Plan--------------------------------
  postDuplicateDietPlan = async planId => {
    this.authLoading = true;

    let { data, error } = await PostDuplicateDietPlan(planId);
    this.authLoading = false;
    if (data) {
      return { data };
    } else {
      return { error };
    }
  };
}
decorate(DietPlanStore, {
  editedData: observable,
  postDietPlan: action,
  setEditedData: action
});

export default new DietPlanStore();
