import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllDoctorList({
  offset,
  limit,
  search,
  isActive,
  appointment,
  specialist
}) {
  try {
    let getAllAreaResponse = await axios.Get({
      url: `${adminUrl}/siloam-doctor?offset=${offset}&limit=${limit ||
        ""}&search=${search || ""}&isActive=${isActive ||
        ""}&appointment=${appointment || ""}&specialist=${specialist || ""}`
    });

    if (getAllAreaResponse.statusCode !== 200) {
      return { error: getAllAreaResponse.message };
    }

    return { data: getAllAreaResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetByIDDoctor({ id }) {
  try {
    let getByIDDoctorResponse = await axios.Get({
      url: `${adminUrl}/siloam-doctor/${id}`
    });

    if (getByIDDoctorResponse.statusCode !== 200) {
      return { error: getByIDDoctorResponse.message };
    }

    return { data: getByIDDoctorResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdateDoctor({ id, isActive }) {
  try {
    let putUpdateDoctorResponse = await axios.Put({
      url: `${adminUrl}/siloam-doctor/${id}/is-active`,
      data: { isActive }
    });

    if (putUpdateDoctorResponse.statusCode !== 200) {
      return { error: putUpdateDoctorResponse.message };
    }

    return { data: putUpdateDoctorResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
