import {
  getUser,
  getUserById,
  approveUser,
  deleteUser,
} from "../services/RegistFormService";
import { observable, action, decorate } from "mobx";
import React from "react";
import commonStore from "./CommonStore";

class RegistFormStore {
  dataArray = [];
  isLoading = false;
  pageSize = 10;
  currentPage = 0;
  keyword = "";
  editedData = {};
  valueTransformer = {
    isActive: (data) => {
      if (data.status !== "Pending") {
        return <span className="active-span">APPROVED</span>;
      } else {
        return <span className="pending-span">PENDING</span>;
      }
    },
  };
  columns = [
    {
      name: "codeID",
      title: "Unique Code Used",
    },
    {
      name: "name",
      title: "Name",
    },
    {
      name: "birthDate",
      title: "Date of Birth",
    },
    {
      name: "phoneNumber",
      title: "Phone Number",
    },
    {
      name: "email",
      title: "Email",
    },
    {
      name: "idNumber",
      title: "ID Number",
    },
    {
      name: "status",
      title: "Status",
      valueTransformer: this.valueTransformer["isActive"],
    },
  ];

  columnWidths = [
    {
      columnName: "codeID",
      width: 200,
    },
    {
      columnName: "name",
      width: 200,
    },
    {
      columnName: "birthDate",
      width: 200,
    },
    {
      columnName: "phoneNumber",
      width: 200,
    },
    {
      columnName: "email",
      width: 200,
    },
    {
      columnName: "idNumber",
      width: 200,
    },
    {
      columnName: "status",
      width: 150,
    },
  ];

  disabledColumn = [
    {
      columnName: "name",
      sortingEnabled: false,
    },
    {
      columnName: "birthDate",
      sortingEnabled: false,
    },
    {
      columnName: "phoneNumber",
      sortingEnabled: false,
    },
    {
      columnName: "email",
      sortingEnabled: false,
    },
    {
      columnName: "idNumber",
      sortingEnabled: false,
    },
    {
      columnName: "status",
      sortingEnabled: false,
    },
  ];

  sorting = [{ columnName: "codeID", direction: "asc" }];

  statusArray = [
    { value: "Approved", name: "Approved" },
    { value: "Pending", name: "Pending" },
  ];

  setEditedData = (updatedData) => {
    this.editedData = {
      ...this.editedData,
      ...updatedData,
    };
  };

  clearData = () => {
    this.editedData = {};
  };

  setSorting = (sorting) => {
    this.sorting = sorting;
  };

  setPageLimit = (value) => {
    this.pageSize = value;
  };

  setCurrentPage = (currentPage) => {
    this.currentPage = currentPage;
    this.getUser();
  };

  setSearchQuery = (searchValue) => {
    this.currentPage = 0;
    this.keyword = searchValue;
  };

  getUser = async () => {
    this.isLoading = true;
    let { data, error } = await getUser({
      status: this.editedData.status || "",
      keyword: this.keyword || "",
      offset: Number(this.currentPage) || "",
      limit: this.pageSize,
    });
    this.isLoading = false;
    if (data) {
      this.dataArray =
        data.diabisaUser.map((item) => {
          return {
            id: item.id || "",
            codeID: item.diabisaInvitationCode.code || "",
            name: item.name || "",
            birthDate: item.dob || "",
            phoneNumber: item.phoneNumber || "",
            email: item.email || "",
            idNumber: item.NIK || "",
            status: item.status || "",
          };
        }) || [];
      this.editedData.totalCount = data.count;
    } else {
      if (error) {
        commonStore.showErrorMessage("An error has occurred");
      } else {
        commonStore.showErrorMessage(error);
      }
    }
  };

  getUserById = async ({ id }) => {
    this.isLoading = true;
    let { data, error } = await getUserById({
      data: { id },
    });
    this.isLoading = false;
    if (data) {
      this.editedData.id = data.id || "";
      this.editedData.codeID = data.diabisaInvitationCodeId || "";
      this.editedData.name = data.name || "";
      this.editedData.birthDate = data.dob || "";
      this.editedData.phoneNumber = data.phoneNumber || "";
      this.editedData.email = data.email || "";
      this.editedData.idNumber = data.NIK || "";
      this.editedData.status = data.status || "";
      this.editedData.imageUrl = data.NIKImage || "";
    } else {
      if (error) {
        commonStore.showErrorMessage("An error has occurred");
      } else {
        commonStore.showErrorMessage(error);
      }
    }
  };

  approveUser = async () => {
    this.isLoading = true;
    let { data, error } = await approveUser({
      data: {
        id: this.editedData.id || "",
      },
    });
    this.isLoading = false;
    if (data) {
      commonStore.showSuccessMessage(
        "DiaBISA Registration Form Approved successfully!"
      );
      setTimeout(() => {
        window.location.href = "/regist-form";
      }, 500);
    } else {
      if (error) {
        commonStore.showErrorMessage("An error has occurred");
      } else {
        commonStore.showErrorMessage(error);
      }
    }
  };

  deleteUser = async () => {
    this.isLoading = true;
    let { data, error } = await deleteUser({
      data: {
        id: this.editedData.id || "",
      },
    });
    this.isLoading = false;
    if (data) {
      commonStore.showSuccessMessage(
        "DiaBISA Registration Form deleted successfully!"
      );
      setTimeout(() => {
        window.location.href = "/regist-form";
      }, 500);
    } else {
      if (error) {
        commonStore.showErrorMessage("An error has occurred");
      } else {
        commonStore.showErrorMessage(error);
      }
    }
  };
}

decorate(RegistFormStore, {
  isLoading: observable,
  editedData: observable,
  keyword: observable,
  dataArray: observable,
  pageSize: observable,
  currentPage: observable,
  sorting: observable,

  setEditedData: action,
  setSorting: action,
});

export default new RegistFormStore();
