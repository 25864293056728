import commonStore from "./CommonStore";
import { loginWithEmailAndPassword } from "../services";
import { setCookie, getCookie, asyncGetCookie, deleteCookiesByName } from "../helper/cookie";
import { observable, decorate, action } from "mobx";
import { reactLocalStorage } from "reactjs-localstorage";
import { validateEditedData } from "../helper/functions";
import oneSignalStore from "./OneSignalStore";

const Joi = require("joi-browser");
Joi.objectId = require("joi-objectid")(Joi);

class AuthStore {
  currentToken = null;
  currentUser = null;
  currentRole = null;
  authLoading = false;
  editedData = {};
  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  constructor() {
    const currentUserString = reactLocalStorage.get("siloamCurrentUser");
    if (currentUserString) {
      this.currentUser = JSON.parse(currentUserString);
    }
    const currentTokenString = getCookie("siloam_current_token");
    if (currentTokenString) {
      this.currentToken = currentTokenString;
    }
  }

  signInWithEmailAndPassword = async (email, password, history) => {
    this.authLoading = true;
    let { data, error } = await loginWithEmailAndPassword({
      email,
      password
    });

    if (data) {
      this.authLoading = false;
      this.currentUser = data.admin;
      this.currentToken = data.token;
      this.currentRole = data.admin.role;
      reactLocalStorage.setObject("siloamCurrentUser", data.admin);
      setCookie("siloam_current_token", data.token, 86400000);
      setCookie("user_name", data.admin.name, 86400000);
      setCookie("user_role", data.admin.role, 86400000);

      if (data.admin.role === "diabeticExpert") {
        await oneSignalStore.getSubscribe(history);
      } else {
        history.push("/dashboard");
      }
    }

    if (error) {
      this.authLoading = false;
      commonStore.showErrorMessage(error);
    }
  };

  getRole = async () => {
    const role = await asyncGetCookie("user_role");
    if (role) {
      this.currentcRole = role;
    }
  }

  get isAuthenticated() {
    return !!this.currentUser && !!this.currentToken;
  }

  signOut = async history => {
    await oneSignalStore.getUnSubscribe();
    reactLocalStorage.clear();
    deleteCookiesByName("siloam_current_token");
    deleteCookiesByName("user_name");
    deleteCookiesByName("user_role");
    this.currentUser = null;
    this.currentToken = null;
    window.location.href = '/login';
  };

  get joiFormErrors() {
    if (this.editedData) {
      return validateEditedData({
        editedData: this.editedData,
        joiSchema: {
          email: Joi.string()
            .trim()
            .required()
            .email(),
          password: Joi.string()
            .trim()
            .required()
        }
      });
    }
    return { error: true };
  }
}
decorate(AuthStore, {
  editedData: observable,
  authLoading: observable,
  currentcRole: observable,

  signInWithEmailAndPassword: action
});

export default new AuthStore();
