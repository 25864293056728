import { observable, decorate } from "mobx";
import {
  GetBloodGlucoseAlerts,
  UpdateBloodGlucoseAlert,
  GetBloodGlucoseAlertMessage,
  CreateBloodGlucoseAlertMessage,
  UpdateBloodGlucoseAlertById,
  DeleteBloodGlucoseAlertMessage,
} from "../services/BloodGlucoseAbnormalitiesService";
import commonStore from "./CommonStore";
import { validateEditedData } from '../helper/functions';

const Joi = require("joi-browser");
Joi.objectId = require("joi-objectid")(Joi);

class BloodGlucoseAbnormalitiesStore {
  editedData = {
    alertMessages: []
  };
  dataArray = [];
  loading = false;

  setEditedData = (updatedData) => {
    this.editedData = {
      ...this.editedData,
      ...updatedData,
    };
  };

  getAllBloodGlucoseAlerts = async ({ type, order }) => {
    this.loading = true;

    let { data, error } = await GetBloodGlucoseAlerts({ type, order });

    if (data) {
      for (let i = 0; i < data.bloodGlucoseAlertConfig.length; i += 1) {
        const curr = data.bloodGlucoseAlertConfig[i];

        if (curr.type === "hypo") {
          this.editedData.idHypo = curr.id;
          this.editedData.hypoValue = curr.value;
          this.editedData.isActiveHypo = curr.isActive;
        } else if (curr.type === "hyper") {
          if (curr.order === 1) {
            this.editedData.idHyperExtremelyHigh = curr.id;
            this.editedData.hyperExtremelyHighValue = curr.value;
            this.editedData.isActiveHyperExtremelyHigh = curr.isActive;
          } else if (curr.order === 2) {
            this.editedData.idHyperVeryHigh = curr.id;
            this.editedData.hyperVeryHighValue = curr.value;
            this.editedData.isActiveHyperVeryHigh = curr.isActive;
          } else if (curr.order === 3) {
            this.editedData.idHyperHigh = curr.id;
            this.editedData.hyperHighValue = curr.value;
            this.editedData.isActiveHyperHigh = curr.isActive;
          } else if (curr.order === 4) {
            this.editedData.idHyperModeratelyHigh = curr.id;
            this.editedData.hyperModeratelyHigh = curr.value;
            this.editedData.isActiveHyperModeratelyHigh = curr.isActive;
          }
        }
      }
      this.setEditedData({
        alertType: data.bloodGlucoseAlertConfig[0].alertType,
        days: data.bloodGlucoseAlertConfig[0].days,
        configId: data.bloodGlucoseAlertConfig[0].id,
      });
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };

  updateBloodGlucoseAlert = async () => {
    this.loading = true;

    const updateArray = [];

    const tempObjectHypo = {
      id: this.editedData.idHypo,
      value: this.editedData.hypoValue,
      isActive: this.editedData.isActiveHypo,
    };

    const tempObjectHyperExtreme = {
      id: this.editedData.idHyperExtremelyHigh,
      value: this.editedData.hyperExtremelyHighValue,
      isActive: this.editedData.isActiveHyperExtremelyHigh,
    };

    const tempObjectHyperVeryHigh = {
      id: this.editedData.idHyperVeryHigh,
      value: this.editedData.hyperVeryHighValue,
      isActive: this.editedData.isActiveHyperVeryHigh,
    };

    const tempObjectHyperHigh = {
      id: this.editedData.idHyperHigh,
      value: this.editedData.hyperHighValue,
      isActive: this.editedData.isActiveHyperHigh,
    };

    const tempObjectHyperModeratelyHigh = {
      id: this.editedData.idHyperModeratelyHigh,
      value: this.editedData.hyperModeratelyHigh,
      isActive: this.editedData.isActiveHyperModeratelyHigh,
    };

    updateArray.push(
      tempObjectHypo,
      tempObjectHyperExtreme,
      tempObjectHyperVeryHigh,
      tempObjectHyperHigh,
      tempObjectHyperModeratelyHigh
    );

    let { data, error } = await UpdateBloodGlucoseAlert(updateArray);

    if (data) {
      this.loading = false;
      commonStore.showSuccessMessage("Success");
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };

  getBloodGlucoseAlertMessage = async (type) => {
    let { data, error } = await GetBloodGlucoseAlertMessage(type);

    this.loading = false;

    if (data) {
      this.setEditedData({ alertMessages: data.bloodGlucoseAlertMessage });
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };

  updateBloodGlucoseAlertById = async (value) => {
    this.loading = true;

    let finalData = {
      alertType: value.alertType,
      id: value.configId,
      days: value.days ? value.days : 0,
    };

    let { data, error } = await UpdateBloodGlucoseAlertById({
      data: finalData,
      id: value.configId,
    });

    if (data) {
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };

  createBloodGlucoseAlertMessage = async (value) => {
    let finalArray = value.map((item) => {
      return {
        id: item.id,
        type: item.type,
        titleEn: item.titleEn,
        titleId: item.titleId,
        textEn: item.textEn,
        textId: item.textId,
        diabeticEducatorTextEn: item.diabeticEducatorTextEn,
        diabeticEducatorTextId: item.diabeticEducatorTextId,
        order: item.order,
        isActive: item.isActive,
        notifyDiabeticEducator: item.notifyDiabeticEducator,
      };
    });

    let { data, error } = await CreateBloodGlucoseAlertMessage(finalArray);

    this.loading = false;

    if (data) {
      commonStore.showSuccessMessage("Alert Updated");
      await this.getAllBloodGlucoseAlerts({ type: this.editedData.type, order: this.editedData.typeOrder });
      await this.getBloodGlucoseAlertMessage(this.editedData.rawType);
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };

  deleteBloodGlucoseAlertMessage = async (id) => {
    this.loading = true;

    let { data, error } = await DeleteBloodGlucoseAlertMessage(id);
    
    if (data) {
      commonStore.showSuccessMessage("Alert Deleted");
      this.getBloodGlucoseAlertMessage(this.editedData.rawType);
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  }

  joiAlertFormErrors = (idx) => {
    if (this.editedData.alertMessages.length !== 0) {
      return validateEditedData({
        editedData: this.editedData.alertMessages[idx],
        joiSchema: {
          titleEn: Joi.string()
            .empty(null)
            .label('English Wording Title')
            .required(),
          titleId: Joi.string()
            .empty(null)
            .label('Indonesian Wording Title')
            .required(),
          textEn: Joi.string()
            .empty(null)
            .label('English Wording')
            .required(),
          textId: Joi.string()
            .empty(null)
            .label('Indonesian Wording')
            .required(),
          diabeticEducatorTextId: Joi.string()
            .empty(null)
            .label('Diabetic Educator Alert Wording')
            .required()
        }
      });
    }
    return { error: true };
  }
}

decorate(BloodGlucoseAbnormalitiesStore, {
  editedData: observable,
  dataArray: observable,
  loading: observable,
});
export default new BloodGlucoseAbnormalitiesStore();
