/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  InputGroup,
  Row
} from "reactstrap";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import imgBanner from "../../../assets/img/cms_login.svg";
import { Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
import LoadingCircular from "../../../components/LoadingCircular";

class Login extends Component {
  state = {
    showPassword: false
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.props.authStore.setEditedData({
      [name]: value
    });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  login = async () => {
    const { authStore, history } = this.props;
    const { email, password } = authStore.editedData;
    authStore.signInWithEmailAndPassword(email, password, history);
  };

  onKeyPressed = async event => {
    const { authStore, history } = this.props;
    const { email, password } = authStore.editedData;
    if (event.key === "Enter") {
      authStore.signInWithEmailAndPassword(email, password, history);
    }
  };

  componentDidMount = async () => {
    this.props.authStore.setEditedData({
      email: "",
      password: ""
    });
  };

  render() {
    const { editedData, authLoading } = this.props.authStore;
    if (this.props.authStore.isAuthenticated) {
      // this.props.commonStore.showErrorMessage("Please login first");
      return <Redirect to={"/dashboard"} />;
    }
    return (
      <div
        style={{
          backgroundColor: "white",
          height: "100%",
          display: "flex",
          alignItems: "center"
        }}
      >
        <Container>
          <Row>
            <Col className="col-lg-8 col-md-7 col-sm-0">
              <Row className="justify-content-center">
                <Col lg="12">
                  <img
                    src={imgBanner}
                    responsive="true"
                    height="480"
                    alt=""
                    className="banner"
                  />
                </Col>
                <Col lg="12">
                  <h1
                    style={{
                      fontSize: "32px",
                      fontWeight: "bold",
                      textAlign: "center"
                    }}
                  >
                    DIABETIC APP
                  </h1>
                </Col>
                <Col lg="12">
                  <h4
                    style={{
                      fontSize: "14px",
                      color: "#B3B5C5",
                      fontFamily: "Google Sans",
                      textAlign: "center"
                    }}
                  >
                    by Siloam
                  </h4>
                </Col>
              </Row>
            </Col>
            <Col md="5" sm="12" lg="4">
              <CardGroup>
                <Card className="p-4" style={{ border: "none" }}>
                  <CardBody>
                    <Form>
                      <h1
                        className="text-center dark-blue"
                        style={{ fontSize: "24px" }}
                      >
                        Login
                      </h1>
                      <InputGroup className="mb-5">
                        <TextField
                          id="outlined-name"
                          label="Email"
                          name="email"
                          InputLabelProps={{ shrink: true }}
                          className="w-100"
                          value={editedData.email ? editedData.email : ""}
                          onChange={this.handleChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </InputGroup>
                      <InputGroup className="mb-5">
                        <TextField
                          id="outlined-adornment-password"
                          className="w-100"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          type={this.state.showPassword ? "text" : "password"}
                          label="Password"
                          onKeyPress={this.onKeyPressed}
                          name="password"
                          value={editedData.password ? editedData.password : ""}
                          onChange={this.handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                >
                                  {this.state.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </InputGroup>
                      <Row className="justify-content-center">
                        <Col xs="6">
                          <Button
                            block
                            color="warning"
                            className="btn-pill"
                            onClick={this.login}
                          >
                            Login
                          </Button>
                          <a
                            style={{ marginTop: 16, display: "inline-block" }}
                            className="text-justify"
                            href=""
                          >
                            Forgot Password
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg="12"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {authLoading === true ? <LoadingCircular /> : ""}
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default inject("authStore", "oneSignalStore")(observer(Login));
