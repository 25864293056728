/* eslint-disable jsx-a11y/alt-text */
import {
    GetAllSiloamHospital,
    GetAllSiloamDoctor,
    GetDoctorById,
    GetDoctorInfoById,
    GetDoctorScheduleById,
    UpdateDoctorById
} from "../services/SiloamDoctorService"
import { setCookie, asyncGetCookie } from "../helper/cookie";
import React from "react";
import { observable, action, decorate } from "mobx";
import commonStore from "./CommonStore";
import swal from 'sweetalert2';

class SiloamDoctorStore {
    valueTransformer = {
        editCondition: data => {
            return <span onClick={() => this.setToggleModal("isOpenConditionEdit", data.index)}><img style={{ cursor: "pointer", height: "20px", width: "20px" }} alt="" src={require("../assets/img/edit.png")} /></span>;
        },
        deleteCondition: data => {
            return <span onClick={() => this.setToggleModal("isOpenDeleteCondition", data.index)}><img style={{ cursor: "pointer" }} alt="" src={require("../assets/img/delete.svg")} /></span>;
        },
        editAchievement: data => {
            return <span onClick={() => this.setToggleModal("isOpenAchievementEdit", data.index)}><img style={{ cursor: "pointer", height: "20px", width: "20px" }} alt="" src={require("../assets/img/edit.png")} /></span>;
        },
        deleteAchievement: data => {
            return <span onClick={() => this.setToggleModal("isOpenDeleteAchievement", data.index)}><img src={require("../assets/img/delete.svg")} /></span>;
        },
        editEducation: data => {
            return <span onClick={() => this.setToggleModal("isOpenEducationEdit", data.index)}><img style={{ cursor: "pointer", height: "20px", width: "20px" }} alt="" src={require("../assets/img/edit.png")} /></span>;
        },
        deleteEducation: data => {
            return <span onClick={() => this.setToggleModal("isOpenDeleteEducation", data.index)}><img src={require("../assets/img/delete.svg")} /></span>;
        },
    };
    valueTransformerPublished = {
        is_validated: data => {
            if (data.is_validated === true) {
                return <strong style={{ color: "green" }}>YES</strong>;
            } else {
                return <strong style={{ color: "red" }}>NO</strong>;
            }

        }
    };
    valueTransformerWaitingForReview = {
        is_update: data => {
            if (data.is_update === true) {
                return <strong style={{ color: "green" }}>YES</strong>;
            } else {
                return <strong style={{ color: "red" }}>NO</strong>;
            }

        }
    };
    editedData = {
        hospitalArray: [],
        doctorArray: [],
        searchQuery: "",
        columns: [
            {
                name: "name",
                title: "Doctor Name"
            },
            {
                name: "specialization_name",
                title: "Specialist"
            },
            {
                name: "is_validated",
                title: "Published",
                valueTransformer: this.valueTransformerPublished["is_validated"]
            },
            {
                name: "is_update",
                title: "Waiting for review",
                valueTransformer: this.valueTransformerWaitingForReview["is_update"]
            }
        ],
        columnWidths: [
            { columnName: "name", width: 300 },
            { columnName: "specialization_name", width: 400 },
            { columnName: "is_validated", width: 200 },
            { columnName: "is_update", width: 200 }
        ],
    };
    editedDataDetail = {
        conditionColumns: [
            {
                name: "condition",
                title: "Conditions Treated & Clinical Interest (Indonesia)"
            },
            {
                name: "condition_eng",
                title: "Conditions Treated & Clinical Interest (English)"
            },
            {
                name: "editCondition",
                title: "Edit",
                valueTransformer: this.valueTransformer["editCondition"]
            },
            {
                name: "deleteCondition",
                title: "Action",
                valueTransformer: this.valueTransformer["deleteCondition"]
            }
        ],
        conditionColumnWidths: [
            { columnName: "condition", width: 400 },
            { columnName: "condition_eng", width: 400 },
            { columnName: "editCondition", width: 100 },
            { columnName: "deleteCondition", width: 100 },
        ],
        achievementColumns: [
            {
                name: "year",
                title: "Year"
            },
            {
                name: "achievement",
                title: "Achievement"
            },
            {
                name: "editAchievement",
                title: "Edit",
                valueTransformer: this.valueTransformer["editAchievement"]
            },
            {
                name: "deleteAchievement",
                title: "Action",
                valueTransformer: this.valueTransformer["deleteAchievement"]
            }
        ],
        achievementColumnWidths: [
            { columnName: "year", width: 200 },
            { columnName: "achievement", width: 600 },
            { columnName: "editAchievement", width: 100 },
            { columnName: "deleteAchievement", width: 100 },
        ],
        educationColumns: [
            {
                name: "year",
                title: "Year"
            },
            {
                name: "education",
                title: "Education"
            },
            {
                name: "editEducation",
                title: "Edit",
                valueTransformer: this.valueTransformer["editEducation"]
            },
            {
                name: "deleteEducation",
                title: "Action",
                valueTransformer: this.valueTransformer["deleteEducation"]
            },
        ],
        educationColumnWidths: [
            { columnName: "year", width: 200 },
            { columnName: "education", width: 600 },
            { columnName: "editEducation", width: 100 },
            { columnName: "deleteEducation", width: 100 },
        ],
        availabilityColumns: [
            {
                name: "dayText",
                title: "Day"
            },
            {
                name: "scheduleTime",
                title: "Time"
            },
            {
                name: "hospital_name",
                title: "Hospital"
            },
        ],
        availabilityColumnWidths: [
            { columnName: "dayText", width: 200 },
            { columnName: "scheduleTime", width: 400 },
            { columnName: "hospital_name", width: 400 },
        ],
        enumIsValidate: [
            { value: "true", name: "Published" },
            { value: "false", name: "Waiting For Review" },
        ],
        condition: "",
        condition_eng: "",
        educationYear: "",
        inputtedEducation: "",
        achievementYear: "",
        inputtedAchievement: "",
        availability: [],
        isValidate: false,

        tempImgUrl: null,
        imgLoading: false,
        currentTab: 0,
        isImageUpdate: false,
        hospitalAlias: "",

    };
    condition = [];
    condition_eng = [];
    achievement = [];
    education = [];
    isOpenCondition = false;
    isOpenConditionEdit = false;
    isOpenEducation = false;
    isOpenEducationEdit = false;
    isOpenAchievement = false;
    isOpenAchievementEdit = false;
    isOpenDeleteEducation = false;
    isOpenDeleteAchievement = false;
    isOpenSaveDoctorProfile = false;
    isOpenPublishDoctorProfile = false;
    filterIsValidate = "true";
    index = null;
    hospital = "";
    doctor = "";
    searchQuery = "";
    currentPage = 0;
    pageSize = 10;
    loading = false;
    imageChanged = false;
    idDoctorInfo = null;
    disabledButton = false;
    flagInputSearch = false;


    hospitalSelected = "";
    doctorSelected = "";
    selectedOptionD = null;
    selectedOptionH = null;

    setEditedData = updatedData => {
        this.editedData = {
            ...this.editedData,
            ...updatedData
        };
    };


    getAllDoctorChange = async (doctorId) => {
        this.loading = true;
        this.searchQuery = "";
        let { data, error } = await GetAllSiloamDoctor(this.hospital, null);

        if (data) {
            this.loading = false;
            this.editedData.doctorArray = data
        }
        else {
            this.loading = false;

            if (error.message) {
                const errorMessage = error.data.message;
                commonStore.showErrorMessage(errorMessage);
            } else {
                commonStore.showErrorMessage("Something Went Wrong");
            }
        }
    };


    setFlagInputSearch = newValue => {
        this.flagInputSearch = newValue;
    }
    setHospitalOnMount = (newValue) => {
        this.hospital = newValue;
    }

    setDisabledButton = (newValue) => {
        this.disabledButton = newValue;
    }
    setModalFalse = () => {
        this.isOpenSaveDoctorProfile = false;
        this.isOpenPublishDoctorProfile = false;
    }
    setEditedDataDetail = updatedData => {
        this.editedDataDetail = {
            ...this.editedDataDetail,
            ...updatedData
        };
    };

    setLoading = () => {
        this.loading = true;
    }
    setImageChanged = newValue => {
        this.imageChanged = newValue;
    }

    setToggleModal = (type, index) => {
        if (index !== null) {
            this.editedDataDetail.condition = this.condition[index] ? this.condition[index].condition : "";
            this.editedDataDetail.condition_eng = this.condition[index] ? this.condition[index].condition_eng : "";
            this.editedDataDetail.educationYear = this.education[index] ? this.education[index].year : "";
            this.editedDataDetail.inputtedEducation = this.education[index] ? this.education[index].education : "";
            this.editedDataDetail.achievementYear = this.achievement[index] ? this.achievement[index].year : "";
            this.editedDataDetail.inputtedAchievement = this.achievement[index] ? this.achievement[index].achievement : "";
        } else {
            this.editedDataDetail.condition = "";
            this.editedDataDetail.condition_eng = "";
            this.editedDataDetail.educationYear = "";
            this.editedDataDetail.inputtedEducation = "";
            this.editedDataDetail.achievementYear = "";
            this.editedDataDetail.inputtedAchievement = "";
            this.index = null;
        }
        if (type === "isOpenCondition") {
            this.isOpenCondition = !this.isOpenCondition;
        }
        else if (type === "isOpenConditionEdit") {
            this.isOpenConditionEdit = !this.isOpenConditionEdit;
            this.index = index;
        }
        if (type === "isOpenEducation") {
            this.isOpenEducation = !this.isOpenEducation;
        }
        else if (type === "isOpenEducationEdit") {
            this.isOpenEducationEdit = !this.isOpenEducationEdit;
            this.index = index;
        }
        else if (type === "isOpenAchievement") {
            this.isOpenAchievement = !this.isOpenAchievement;
        }
        else if (type === "isOpenAchievementEdit") {
            this.isOpenAchievementEdit = !this.isOpenAchievementEdit;
            this.index = index;
        }
        else if (type === "isOpenDeleteAchievement") {
            this.isOpenDeleteAchievement = !this.isOpenDeleteAchievement;
            this.index = index;
        }
        else if (type === "isOpenDeleteEducation") {
            this.isOpenDeleteEducation = !this.isOpenDeleteEducation;
            this.index = index;
        }
        else if (type === "isOpenDeleteCondition") {
            this.isOpenDeleteCondition = !this.isOpenDeleteCondition;
            this.index = index;
        }
        else if (type === "isOpenSaveDoctorProfile") {
            this.isOpenSaveDoctorProfile = !this.isOpenSaveDoctorProfile;
        }
        else if (type === "isOpenPublishDoctorProfile") {
            this.isOpenPublishDoctorProfile = !this.isOpenPublishDoctorProfile;
        }
    };

    setHospital = (hospital) => {
        this.hospital = hospital;
        let hospitalName = "";
        let lengthDatas = this.editedData.hospitalArray.length;
        for (let i = 0; i < lengthDatas; i++) {
            if (this.editedData.hospitalArray[i].value === this.hospital) {
                hospitalName = this.editedData.hospitalArray[i].alias;
            }
        }
        setCookie("hospitalAlias", hospitalName, 86400000);
    };

    setFilterIsValidate = value => {
        this.filterIsValidate = value;

        if (value === "true") {
            this.editedDataDetail.tempImgUrl = this.editedData.doctorArray[0] ? this.editedData.doctorArray[0].image_url : "";
        }
    }

    setCurrentTab = value => {
        this.editedDataDetail.currentTab = value;
    }

    clearSearch = () => {
        this.searchQuery = "";
        this.getAllDoctor();
    };

    // setHospital = hospitalId => {
    //     this.hospital = hospitalId;
    //     this.getAllDoctorChange();
    // };

    setHospitalSelected = hospitalSelected => {
        this.hospitalSelected = hospitalSelected;
    };

    setDoctorSelected = doctorSelected => {
        this.doctorSelected = doctorSelected;
    };

    setDoctor = doctorId => {
        this.doctor = doctorId;
    };

    setSearch = value => {
        this.searchQuery = value;
    };

    setDoctorSelectedOptionD = newData => {
        this.selectedOptionD = newData;
    }

    setHospitalSelectedOptionH = newData => {
        this.selectedOptionH = newData;
    }

    getAllHospital = async () => {
        this.loading = true;

        let { data, error } = await GetAllSiloamHospital();
        let currentHospital = null;

        currentHospital = localStorage.getItem("currentHospital");

        if (data) {
            this.loading = false;

            if (data.length > 0) {
                if (currentHospital) {
                    this.hospital = currentHospital;
                    let hospitalName = "";
                    let lengthDatas = this.editedData.hospitalArray.length;
                    for (let i = 0; i < lengthDatas; i++) {
                        if (this.editedData.hospitalArray[i].hospital_id === this.hospital.value) {
                            hospitalName = this.editedData.hospitalArray[i].alias;
                        }
                    }
                    setCookie("hospitalAlias", hospitalName, 86400000);
                } else {
                    this.hospital = data[0].hospital_id;
                    setCookie("hospitalAlias", data[0].alias, 86400000);
                }

            }

            this.editedData.hospitalArray = data.map(obj => ({
                value: obj.hospital_id,
                label: obj.name,
                alias: obj.alias
            }));

            this.getAllDoctor();
        } else {
            this.loading = false;

            if (error.message) {
                const errorMessage = error.data.message;
                commonStore.showErrorMessage(errorMessage);
            } else {
                commonStore.showErrorMessage("Something Went Wrong");
            }
        }
    };

    getAllDoctor = async () => {
        this.loading = true;
        this.tempImgUrl = null;

        let { data, error } = await GetAllSiloamDoctor(this.hospital, this.searchQuery);
        if (data) {
            this.loading = false;
            this.editedData.doctorArray = data;
        }
        else {
            this.loading = false;

            if (error.message) {
                const errorMessage = error.data.message;
                commonStore.showErrorMessage(errorMessage);
            } else {
                commonStore.showErrorMessage("Something Went Wrong");
            }
        }
    };

    getDoctorInfoById = async (doctorId, hospitalId) => {
        this.loading = true;
        this.editedDataDetail.condition = "";
        this.achievement = [];
        this.education = [];
        this.condition = [];

        let { doctor } = await GetDoctorById(doctorId, this.filterIsValidate);

        if (doctor) {
            this.editedData.doctorArray = doctor;
            this.editedDataDetail.hospitalId = hospitalId;
        }

        let { data, error } = await GetDoctorInfoById(doctorId, this.filterIsValidate);

        if (data) {
            this.loading = false;
            this.editedDataDetail.tempImgUrl = null;
            this.editedDataDetail.isValidate = data.isValidate;

            let imageUrl = null;
            if (data.imageUrl) {
                if (data.imageUrl.includes("http")) {
                    imageUrl = data.imageUrl;
                }
                else {
                    imageUrl = 'data:image/jpg;base64,' + data.imageUrl;
                }

            }


            this.editedDataDetail.tempImgUrl = imageUrl;
            this.idDoctorInfo = data.id;
            const tempAchievementArray = [];
            const achievementArray = data.achievement ? JSON.parse(data.achievement) : [];

            if (achievementArray) {
                achievementArray.sort((a, b) => (a.year > b.year) ? 1 : -1);
                for (let i = 0; i < achievementArray.length; i++) {
                    const tempObject = {
                        ...achievementArray[i],
                        deleteAchievement: "",
                        index: i
                    };

                    tempAchievementArray.push(tempObject);
                }
            }

            this.achievement = tempAchievementArray;

            const tempEducationArray = [];
            const educationArray = data.education ? JSON.parse(data.education) : [];

            if (educationArray) {
                educationArray.sort((a, b) => (a.year > b.year) ? 1 : -1);
                for (let i = 0; i < educationArray.length; i++) {
                    const tempObject = {
                        ...educationArray[i],
                        deleteEducation: "",
                        index: i
                    };

                    tempEducationArray.push(tempObject);
                }
            }

            this.education = tempEducationArray;

            const tempConditionArray = [];
            const conditionArray = data.condition ? data.condition.split(";") : [];
            const conditionArrayEn = data.condition_en ? data.condition_en.split(";") : [];
            if (conditionArray) {
                //conditionArray.sort((a, b) => (a.condition > b.condition) ? 1 : -1);
                for (let i = 0; i < conditionArray.length; i++) {
                    const tempObject = {
                        condition: conditionArray[i],
                        condition_eng: conditionArrayEn[i],
                        deleteCondition: "",
                        index: i
                    };

                    tempConditionArray.push(tempObject);
                }
            }

            this.condition = tempConditionArray;
        }
        else {
            this.loading = false;
            if (error.message) {
                if (error.message === "Request failed with status code 500") {
                } else {
                    const errorMessage = error.message;
                    commonStore.showErrorMessage(errorMessage);
                }
            } else {
                commonStore.showErrorMessage("Something Went Wrong");
            }
        }
    };

    getDoctorSchedule = async (doctorId) => {
        this.loading = true;
        let { data, error } = await GetDoctorScheduleById(doctorId, this.editedDataDetail.hospitalId);

        if (data) {
            this.loading = false;

            this.editedDataDetail.availability = data.map(row => {
                let dayText = "";
                let scheduleTime = "";

                if (row.day === 1) {
                    dayText = "Monday";
                }
                else if (row.day === 2) {
                    dayText = "Tuesday";
                }
                else if (row.day === 3) {
                    dayText = "Wednesday";
                }
                else if (row.day === 4) {
                    dayText = "Thursday";
                }
                else if (row.day === 5) {
                    dayText = "Friday";
                }
                else if (row.day === 6) {
                    dayText = "Saturday";
                }
                else if (row.day === 7) {
                    dayText = "Sunday";
                }

                scheduleTime = row.from_time.substring(0, row.from_time.length - 3) + " - " + row.to_time.substring(0, row.to_time.length - 3);

                return {
                    ...row,
                    dayText,
                    scheduleTime
                };
            });
        }
        else {
            this.loading = false;
            if (error.status === "OK") {
                this.editedDataDetail.availability = error.data.map(row => {
                    let dayText = "";
                    let scheduleTime = "";

                    if (row.day === 1) {
                        dayText = "Monday";
                    }
                    else if (row.day === 2) {
                        dayText = "Tuesday";
                    }
                    else if (row.day === 3) {
                        dayText = "Wednesday";
                    }
                    else if (row.day === 4) {
                        dayText = "Thursday";
                    }
                    else if (row.day === 5) {
                        dayText = "Friday";
                    }
                    else if (row.day === 6) {
                        dayText = "Saturday";
                    }
                    else if (row.day === 7) {
                        dayText = "Sunday";
                    }

                    scheduleTime = row.from_time.substring(0, row.from_time.length - 3) + " - " + row.to_time.substring(0, row.to_time.length - 3);

                    return {
                        ...row,
                        dayText,
                        scheduleTime
                    };
                });
            }
            else if (error.message) {
                const errorMessage = error.data.message;
                commonStore.showErrorMessage(errorMessage);
            } else {
                commonStore.showErrorMessage("Something Went Wrong");
            }
        }
    };

    saveCondition = () => {
        const tempArray = [];

        for (let i = 0; i < this.condition.length; i++) {
            tempArray.push(this.condition[i]);
        }

        const tempObject = {
            condition: this.editedDataDetail.condition,
            condition_eng: this.editedDataDetail.condition_eng,
            deleteCondition: "",
            index: tempArray.length,
        }
        tempArray.push(tempObject);

        for (let i = 0; i < tempArray.length; i++) {
            tempArray[i].index = i;
        }

        this.condition = tempArray;
        this.index = null;
    };

    updateCondition = () => {
        const tempArray = [];
        for (let i = 0; i < this.condition.length; i++) {
            tempArray.push(this.condition[i]);
        }

        for (let i = 0; i < this.condition.length; i++) {
            if (i === this.index) {
                tempArray[this.index].condition = this.editedDataDetail.condition;
                tempArray[this.index].condition_eng = this.editedDataDetail.condition_eng;
            }
        }

        for (let i = 0; i < tempArray.length; i++) {
            tempArray[i].index = i;
        }

        this.condition = tempArray;

        this.index = null;
    }

    saveAchievement = () => {
        const tempArray = [];
        if (this.editedDataDetail.achievementYear.length > 4 || this.editedDataDetail.achievementYear.length < 4 || this.editedDataDetail.achievementYear.includes(".")) {
            swal("Failed!", "Please check year Achievement", "error");
        } else {
            for (let i = 0; i < this.achievement.length; i++) {
                tempArray.push(this.achievement[i]);
            }

            const tempObject = {
                year: this.editedDataDetail.achievementYear,
                achievement: this.editedDataDetail.inputtedAchievement,
                deleteAchievement: "",
                index: tempArray.length,
            }

            tempArray.push(tempObject);
            tempArray.sort((a, b) => (a.year > b.year) ? 1 : -1);

            for (let i = 0; i < tempArray.length; i++) {
                tempArray[i].index = i;
            }
            this.achievement = tempArray;
        }
        this.index = null;
    };

    updateAchievement = () => {
        const tempArray = [];
        for (let i = 0; i < this.achievement.length; i++) {
            tempArray.push(this.achievement[i]);
        }
        if (this.editedDataDetail.achievementYear.length > 4 || this.editedDataDetail.achievementYear.length < 4 || this.editedDataDetail.achievementYear.includes(".")) {
            swal("Failed!", "Please check year Achievement", "error");
        } else {
            for (let i = 0; i < this.achievement.length; i++) {
                if (i === this.index) {
                    tempArray[this.index].year = this.editedDataDetail.achievementYear;
                    tempArray[this.index].achievement = this.editedDataDetail.inputtedAchievement;
                }
            }
            tempArray.sort((a, b) => (a.year > b.year) ? 1 : -1);

            for (let i = 0; i < tempArray.length; i++) {
                tempArray[i].index = i;
            }

            this.achievement = tempArray;
        }
        this.index = null;
    }

    saveEducation = () => {
        const tempArray = [];
        if (this.editedDataDetail.educationYear.length > 4 || this.editedDataDetail.educationYear.length < 4 || this.editedDataDetail.educationYear.includes(".")) {
            swal("Failed!", "Please check year Education ", "error");
        } else {
            for (let i = 0; i < this.education.length; i++) {
                tempArray.push(this.education[i]);
            }

            const tempObject = {
                year: this.editedDataDetail.educationYear,
                education: this.editedDataDetail.inputtedEducation,
                deleteEducation: "",
                index: this.education.length,
            }

            tempArray.push(tempObject);
            tempArray.sort((a, b) => (a.year > b.year) ? 1 : -1);
            for (let i = 0; i < tempArray.length; i++) {
                tempArray[i].index = i;
            }
            this.education = tempArray;
        }

        this.index = null;
    };

    updateEducation = () => {
        const tempArray = [];
        for (let i = 0; i < this.education.length; i++) {
            tempArray.push(this.education[i]);
        }
        if (this.editedDataDetail.educationYear.length > 4 || this.editedDataDetail.educationYear.length < 4 || this.editedDataDetail.educationYear.includes(".")) {
            swal("Failed!", "Please check year Education", "error");
        } else {
            for (let i = 0; i < this.education.length; i++) {
                if (i === this.index) {
                    tempArray[this.index].year = this.editedDataDetail.educationYear;
                    tempArray[this.index].education = this.editedDataDetail.inputtedEducation;
                }
            }

            tempArray.sort((a, b) => (a.year > b.year) ? 1 : -1);
            for (let i = 0; i < tempArray.length; i++) {
                tempArray[i].index = i;
            }

            this.education = tempArray;
        }
        this.index = null;
    }

    save = async (doctorId, history, publish, id) => {
        this.loading = true;
        let idInfo = null;
        let temp = this.condition;
        let conditionTemp = "";
        let conditionTempEn = "";
        for (let i = 0; i < temp.length; i++) {
            if (i !== temp.length - 1) {
                conditionTemp = conditionTemp + temp[i].condition + ";";
                conditionTempEn = conditionTempEn + temp[i].condition_eng + ";";
            }
            else {
                conditionTemp = conditionTemp + temp[i].condition;
                conditionTempEn = conditionTempEn + temp[i].condition_eng;
            }
        }
        const inputtedData = {
            condition: conditionTemp,
            condition_en: conditionTempEn,
            achievement: JSON.stringify(this.achievement),
            education: JSON.stringify(this.education),
            isValidate: this.editedDataDetail.isValidate.toString(),
            imageUrl: this.editedDataDetail.tempImgUrl,
            isImageUpdate: this.imageChanged,
            hospitalAlias: await asyncGetCookie("hospitalAlias")
        };
        if (this.editedDataDetail.tempImgUrl === null || this.editedDataDetail.tempImgUrl === "") {
            commonStore.showErrorMessage("Image cannot be empty");
            this.loading = false;
            this.disabledButton = false;
            return;
        }
        if ((!inputtedData.condition || inputtedData.condition === "")) {
            commonStore.showErrorMessage("Condition cannot be empty");
            this.loading = false;
            this.disabledButton = false;
            return;
        }
        else if (this.education.length < 1) {
            commonStore.showErrorMessage("Education cannot be empty");
            this.loading = false;
            this.disabledButton = false;
            return;
        }

        if (publish === 1) {
            idInfo = id;
        }

        let { data, error } = await UpdateDoctorById(doctorId, inputtedData, idInfo);

        if (data) {
            commonStore.showSuccessMessage("Success");
            this.disabledButton = false;
            this.loading = false;
            history.push("/siloam-doctors");
        }
        else {
            this.loading = false;
            this.disabledButton = false;
            if (error.message) {
                const errorMessage = error.data.message;
                commonStore.showErrorMessage(errorMessage);
            } else {
                commonStore.showErrorMessage("Something Went Wrong");
            }
        }
    };

    removeCondition = () => {
        let tempArray = [];
        for (let i = 0; i < this.condition.length; i++) {
            tempArray.push(this.condition[i]);
        }
        tempArray.splice(this.index, 1);
        for (let i = 0; i < tempArray.length; i++) {
            tempArray[i].index = i;
        }
        this.condition = tempArray;
    };

    removeAchievement = () => {
        this.achievement.splice(this.index, 1);

        let tempArray = [];
        for (let i = 0; i < this.achievement.length; i++) {
            const tempObject = {
                ...this.achievement[i],
                deleteAchievement: "",
                index: i
            };

            tempArray.push(tempObject);
        }

        this.achievement = tempArray;
    };

    removeEducation = () => {
        this.education.splice(this.index, 1);

        let tempArray = [];
        for (let i = 0; i < this.education.length; i++) {
            const tempObject = {
                ...this.education[i],
                deleteEducation: "",
                index: i
            };

            tempArray.push(tempObject);
        }

        this.education = tempArray;
    };

    removeImage = () => {
        this.editedDataDetail.tempImgUrl = null;
    };
}

decorate(SiloamDoctorStore, {
    editedData: observable,
    editedDataDetail: observable,
    hospital: observable,
    doctor: observable,
    searchQuery: observable,
    loading: observable,
    currentPage: observable,
    pageSize: observable,
    totalCount: observable,
    isOpenCondition: observable,
    isOpenConditionEdit: observable,
    isOpenEducation: observable,
    isOpenEducationEdit: observable,
    isOpenAchievement: observable,
    isOpenAchievementEdit: observable,
    isOpenSaveDoctorProfile: observable,
    isOpenPublishDoctorProfile: observable,
    condition: observable,
    condition_eng: observable,
    achievement: observable,
    education: observable,
    isOpenDeleteEducation: observable,
    isOpenDeleteAchievement: observable,
    isOpenDeleteCondition: observable,
    index: observable,
    filterIsValidate: observable,
    imageChanged: observable,
    idDoctorInfo: observable,
    change: observable,
    selectedOptionD: observable,
    selectedOptionH: observable,
    hospitalSelected: observable,
    doctorSelected: observable,
    disabledButton: observable,
    flagInputSearch: observable,

    setEditedData: action,
    setHospital: action,
    setToggleModal: action,
    saveAchievement: action,
    saveEducation: action,
    setImageChanged: action,
    setHospitalOnMount: action
});

export default new SiloamDoctorStore();