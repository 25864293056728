import {
  GetAllWeightByID,
  GetWeightByID,
  PutUpdateWeight
} from "../services/WeightService";
import { observable, decorate } from "mobx";
import swal from "sweetalert2";
import moment from "moment";
import commonStore from "./CommonStore";

class WeightStore {
  editedData = {};
  dataArray = [];
  weightMinTarget = "";
  weightMaxTarget = "";
  searchQuery = "";
  startDate = "";
  endDate = "";
  pageSize = 10;
  currentPage = 0;
  totalCount = 0;
  loading = false;
  columns = [
    { name: "weightRecordID", title: "Record ID" },
    { name: "date", title: "Date & Time" },
    { name: "weight", title: "Weight (Kg)" }
  ];
  columnWidths = [
    { columnName: "weightRecordID", width: 300 },
    { columnName: "date", width: 300 },
    { columnName: "weight", width: 300 }
  ];
  disabledColumn = [
    { columnName: "date", sortingEnabled: false },
    { columnName: "weight", sortingEnabled: false }
  ];
  sorting = [{ columnName: "weightRecordID", direction: "asc" }];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setLoading = loading => {
    this.loading = loading;
  };

  setStartDate = value => {
    this.currentPage = 0;
    this.startDate = value;
  };

  setEndDate = value => {
    this.currentPage = 0;
    this.endDate = value;
  };

  setSorting = updatedSorting => {
    // if (this.order === "DESC") {
    //   this.order = "ASC";
    // } else {
    //   this.order = "DESC";
    // }
    this.sorting = updatedSorting;
  };

  setSearchQuery = searchValue => {
    this.currentPage = 0;
    this.searchQuery = searchValue;
  };

  setCurrentPage = currentPage => {
    this.currentPage = currentPage;
    this.getAllBloodGlucoseByID();
  };

  getAllWeightByID = async id => {
    this.authLoading = true;
    this.loading = true;
    let { data, error } = await GetAllWeightByID(
      this.editedData.userId,
      this.searchQuery,
      this.startDate ? moment(this.startDate).format("YYYY-MM-DDThh:mm") : "",
      this.endDate ? moment(this.endDate).format("YYYY-MM-DDThh:mm") : "",
      this.currentPage,
      this.pageSize,
      this.sorting[0].direction
    );

    if (data) {
      this.loading = false;
      this.totalCount = data.count;
      this.dataArray = data.data.weightRecord.map(data => {
        const weightRecordID = data.weightRecordID
          ? Number(data.weightRecordID)
          : "";
        const date = moment(data.date ? data.date : "").format(
          "DD MMM YYYY hh:mm:ss"
        );
        const weight = data.weight.toFixed(1) ? data.weight.toFixed(1) : "";

        return {
          ...data,
          weightRecordID,
          date,
          weight
        };
      });
      this.weightMaxTarget = data.data.target.weightMaxTarget
        ? data.data.target.weightMaxTarget.toFixed(1)
        : "";
      this.weightMinTarget = data.data.target.weightMinTarget
        ? data.data.target.weightMinTarget.toFixed(1)
        : "";
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getWeightByID = async id => {
    this.authLoading = true;

    let { data, error } = await GetWeightByID(id);

    if (data) {
      this.setEditedData({
        tempImgUrl: data.imageUrl,
        imageUrl: data.imageUrl,
        date: moment(data.date).format("DD MMM YYYY hh:mm:ss"),
        weight: data.weight,
        notes: data.notes,
        userUserID: data.userUserID
      });
      return { data };
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  putUpdateWeight = async (weight, notes, imageUrl, date, userId, id) => {
    this.loading = true;

    let { data, error } = await PutUpdateWeight(
      weight,
      notes,
      imageUrl,
      date,
      userId,
      id
    );
    this.loading = false;
    if (data) {
      swal({
        title: "Success",
        text: "Weight Success Updated",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = "/patients/" + userId + "/healthtracker";
        return { data };
      });
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };
}

decorate(WeightStore, {
  editedData: observable,
  dataArray: observable,
  weightMaxTarget: observable,
  weightMinTarget: observable,
  startDate: observable,
  endDate: observable,
  totalCount: observable,
  searchQuery: observable,
  currentPage: observable,
  loading: observable
});

export default new WeightStore();
