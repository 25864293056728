import React from "react";
import {
  getGlobalSettings,
  getGlobalSettingById,
  createGlobalSetting,
  updateGlobalSettings,
} from "../services/GlobalSettingService";
import { observable, decorate } from "mobx";
import commonStore from "./CommonStore";

class GlobalSettingStore {
  valueTransformer = {
    isActive: (data) => {
      if (data.isActive === true) {
        return <div>Active</div>;
      } else {
        return <div>Inactive</div>;
      }
    },
  };

  editedData = {
    updateStatus: "",
  };
  dataArray = [];
  loading = false;
  searchQuery = "";
  isActive = "all";
  loading = false;
  pageSize = 10;
  currentPage = 0;
  totalCount = 0;
  columns = [
    { name: "id", title: "Page Management ID" },
    { name: "version", title: "Version" },
    { name: "updateStatus", title: "Update Status" },
    {
      name: "isActive",
      title: "Is Active",
      valueTransformer: this.valueTransformer["isActive"],
    },
    { name: "deviceType", title: "Device Type" },
  ];
  columnWidths = [
    { columnName: "id", width: 250 },
    { columnName: "version", width: 250 },
    { columnName: "updateStatus", width: 250 },
    { columnName: "isActive", width: 250 },
    { columnName: "deviceType", width: 250 },
  ];
  isActiveEnum = [
    { value: "all", name: "All" },
    { value: "true", name: "Active" },
    { value: "false", name: "Inactive" },
  ];

  setEditedData = (updatedData) => {
    this.editedData = {
      ...this.editedData,
      ...updatedData,
    };
  };

  setSearchQuery = (searchValue) => {
    this.currentPage = 0;
    this.searchQuery = searchValue;
  };

  setCurrentPage = (currentPage) => {
    this.currentPage = currentPage;
    this.getGlobalSettings();
  };

  setIsActive = (value) => {
    this.isActive = value;
  };

  clearEditedData = () => {
    this.editedData = {};
  };

  getGlobalSettings = async () => {
    this.loading = true;

    const { data, error } = await getGlobalSettings(
      this.searchQuery,
      this.isActive === "all" ? "" : this.isActive,
      this.currentPage,
      this.pageSize
    );

    if (data) {
      this.loading = false;
      this.totalCount = data.count;
      this.dataArray = data.globalSetting;
    } else if (error) {
      this.loading = false;
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  getGlobalSettingById = async (globalSettingId) => {
    this.loading = true;

    const { data, error } = await getGlobalSettingById(globalSettingId);

    if (data) {
      this.loading = false;
      this.editedData = data;
    } else if (error) {
      this.loading = false;
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  createGlobalSetting = async (history) => {
    this.loading = true;

    const { data, error } = await createGlobalSetting(this.editedData);

    if (data) {
      this.loading = false;
      history.push("/global-setting");
    } else if (error) {
      this.loading = false;
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  updateGlobalSettings = async (history) => {
    this.editedData.isLoading = true;

    const { data, error } = await updateGlobalSettings(this.editedData);

    if (data) {
      this.editedData.isLoading = false;
      history.push("/global-setting");
    } else if (error) {
      this.editedData.isLoading = false;
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };
}

decorate(GlobalSettingStore, {
  editedData: observable,
  dataArray: observable,
  isActive: observable,
  searchQuery: observable,
  currentPage: observable,
  totalCount: observable,
  loading: observable,
});
export default new GlobalSettingStore();
