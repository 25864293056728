import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllPrescriptions(
  id,
  startDate,
  endDate,
  search,
  offset,
  limit,
  order
) {
  try {
    let getAllPrescriptions = await axios.Get({
      url: `${adminUrl}/prescriptions?userId=${id}&startDate=${startDate}&endDate=${endDate}&search=${search ||
        ""}&offset=${offset}&limit=${limit}&order=${order.toUpperCase()}`
    });
    if (getAllPrescriptions.statusCode !== 200) {
      return { error: getAllPrescriptions.message };
    }

    return { data: getAllPrescriptions.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetPrescriptionsById(id) {
  try {
    let getPrescriptionsById = await axios.Get({
      url: `${adminUrl}/prescriptions/${id}`
    });
    if (getPrescriptionsById.statusCode !== 200) {
      return { error: getPrescriptionsById.message };
    }

    return { data: getPrescriptionsById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutDeletePrescription(id) {
  try {
    let putDeletePrescription = await axios.Put({
      url: `${adminUrl}/prescriptions/${id}/delete`
    });

    if (putDeletePrescription.statusCode !== 200) {
      return { error: putDeletePrescription.message };
    }

    return { data: putDeletePrescription.data };
  } catch (e) {
    return { error: e };
  }
}
