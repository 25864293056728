import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllExerciseRecord(
  id,
  startDate,
  endDate,
  search,
  offset,
  limit,
  order
) {
  try {
    let getAllExerciseRecord = await axios.Get({
      url: `${adminUrl}/exercise-records?userId=${id}&startDate=${startDate ||
        ""}&endDate=${endDate}&search=${search ||
        ""}&offset=${offset}&limit=${limit}&order=${order.toUpperCase()}`
    });
    if (getAllExerciseRecord.statusCode !== 200) {
      return { error: getAllExerciseRecord.message };
    }

    return { data: getAllExerciseRecord.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdateExerciseRecord(
  exerciseRecordID,
  totalCalories,
  exerciseId,
  elapsedTime,
  date,
  userId,
  id
) {
  try {
    let putUpdateExerciseRecord = await axios.Put({
      url: `${adminUrl}/exercise-records/${id}`,
      data: {
        exerciseRecordID,
        totalCalories,
        exerciseId,
        elapsedTime,
        date,
        userId
      }
    });

    if (putUpdateExerciseRecord.statusCode !== 200) {
      return { error: putUpdateExerciseRecord.message };
    }

    return { data: putUpdateExerciseRecord.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutDeleteExerciseRecord(id) {
  try {
    let putDeleteExerciseRecord = await axios.Put({
      url: `${adminUrl}/exercise-records/${id}/delete`
    });

    if (putDeleteExerciseRecord.statusCode !== 200) {
      return { error: putDeleteExerciseRecord.message };
    }

    return { data: putDeleteExerciseRecord.data };
  } catch (e) {
    return { error: e };
  }
}

export async function GetExerciseRecordById(id) {
  try {
    let getExerciseRecordById = await axios.Get({
      url: `${adminUrl}/exercise-records/${id}`
    });
    if (getExerciseRecordById.statusCode !== 200) {
      return { error: getExerciseRecordById.message };
    }

    return { data: getExerciseRecordById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
