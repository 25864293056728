import {
  getFormQuestionByFormId, getFormQuestionById, createFormQuestion,
  updateFormQuestion, deleteFormQuestionById
} from "../services/FormQuestionService";
import { observable, decorate } from "mobx";
import commonStore from "./CommonStore";

class FormQuestionStore {
  editedData = {
    fillId: "",
    fillEn: "",
  };
  dataArray = [];
  loading = false;
  pageSize = 10;
  currentPage = 0;
  totalCount = 0;
  columns = [
    { name: "id", title: "Question ID" },
    { name: "titleEn", title: "Form Questions" },
  ];
  columnWidths = [
    { columnName: "id", width: 250 },
    { columnName: "titleEn", width: 450 },
  ];
  typeEnum = [
    { value: "yesNo", name: "Yes No" },
    { value: "yesNoWithFill", name: "Yes No With Fill" },
  ];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  clearEditedData = () => {
    this.editedData = {
      fillId: "",
      fillEn: "",
    };
  }

  setCurrentPage = currentPage => {
    this.currentPage = currentPage;
    this.getFormQuestionByFormId();
  };

  setFillToEmpty = () => {
    this.editedData.fillId = "";
    this.editedData.fillEn = "";
  };

  getFormQuestionByFormId = async (formId) => {
    this.loading = true;

    let { data, error } = await getFormQuestionByFormId(formId, this.currentPage, this.pageSize);

    if (data) {
      this.loading = false;
      this.dataArray = data.formQuestionNew;
      this.totalCount = data.count;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getFormQuestionById = async (formQuestionId) => {
    this.loading = true;

    let { data, error } = await getFormQuestionById(formQuestionId);

    if (data) {
      this.loading = false;
      this.editedData = {
        ...data,
        groupNumber: data.groupNumber.toString(),
      };
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  createFormQuestion = async (formId, history) => {
    this.loading = true;

    this.editedData.formId = formId;

    let { data, error } = await createFormQuestion(this.editedData);

    if (data) {
      this.loading = false;
      history.push(`/health-declaration-forms/${formId}`);
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  updateFormQuestion = async (formQuestionId, formId, history) => {
    this.loading = true;

    let { data, error } = await updateFormQuestion(formQuestionId, this.editedData);

    if (data) {
      this.loading = false;
      history.push(`/health-declaration-forms/${formId}`);
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  deleteFormQuestionById = async (formQuestionId, formId, history) => {
    this.loading = true;

    let { data, error } = await deleteFormQuestionById(formQuestionId);

    if (data) {
      this.loading = false;
      history.push(`/health-declaration-forms/${formId}`);
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };
}

decorate(FormQuestionStore, {
  editedData: observable,
  dataArray: observable,
  loading: observable,
  pageSize: observable,
  currentPage: observable,
  totalCount: observable,
});

export default new FormQuestionStore();
