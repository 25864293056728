export default {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
    },
    {
      name: "Patient Management",
      url: "/patients",
    },
    {
      name: "User Management",
      children: [
        {
          name: "Admin",
          url: "/admins",
        },
        // {
        //   name: "Doctor",
        //   url: "/doctors",
        // },
        {
          name: "Diabetic Educator",
          url: "/diabetic-educator",
        },
      ],
    },
    {
      name: "Master Data",
      children: [
        {
          name: "Meal List",
          url: "/meals",
        },
        {
          name: "Medicine List",
          url: "/medicines",
        },
        {
          name: "Symptom List",
          url: "/symptom",
        },
        {
          name: "Exercise List",
          url: "/exercises",
        },
        {
          name: "Hospital",
          url: "/hospitals",
        },
        {
          name: "Doctor",
          url: "/siloam-doctors",
        },
        {
          name: "Interest",
          url: "/interest",
        },
        {
          name: "Habit",
          url: "/habit",
        },
        {
          name: "Mini Game",
          url: "/mini-game",
        },
        {
          name: "Unique Code",
          url: "/unique-code",
        },
        {
          name: "Page",
          url: "/page",
        },
        {
          name: "Page Management",
          url: "/page-management",
        },
      ],
    },
    {
      name: "Blood Glucose",
      children: [
        {
          name: "Abnormalities",
          url: "/abnormalities",
        },
        {
          name: "Target Limit",
          url: "/target-limit",
        },
        {
          name: "Target Default",
          url: "/target-default",
        },
        {
          name: "Alert Management",
          url: "/alert-management",
        },
      ],
    },
    {
      name: "Drug Reminder",
      url: "/drug-reminder",
    },
    {
      name: "DiaBisa Input Limit",
      url: "/blood-glucose-limit",
    },
    {
      name: "DisBisa Regist Form",
      url: "/regist-form",
    },
    {
      name: "Education",
      url: "/educations",
    },
    {
      name: "Health Declaration Form",
      children: [
        {
          name: "Forms",
          url: "/health-declaration-forms",
        },
        {
          name: "Form Result",
          url: "/user-form",
        },
      ],
    },
    {
      name: "Broadcast",
      url: "/notifications",
    },
    {
      name: "Banner",
      url: "/banner",
    },
    {
      name: "Global Settings",
      url: "/global-setting",
    },
    {
      name: "Maintenance Mode",
      url: "/maintenance",
    },
  ],
};
