import {
  GetAllMedicine,
  GetByIDMedicine,
  PostMedicine,
  PutUpdateMedicine,
  PutDeleteMedicine
} from "../services/MedicineService";
import { observable, action, decorate } from "mobx";
import commonStore from "./CommonStore";

class MedicineStore {
  //OBSERVABLE
  dataArray = [];
  editedData = {
    isLoading: false,
    currentPage: 0,
    totalCount: 0,
    searchQuery: "",
    name: "",
    isAntiDiabetic: "true",
    pageSize: 10
  };

  columns = [
    { name: "medicineID", title: "Medicine ID" },
    { name: "name", title: "Medicine Name" }
  ];

  columnWidths = [
    { columnName: "medicineID", width: 200 },
    { columnName: "name", width: 200 }
  ];

  disabledColumn = [{ columnName: "name", sortingEnabled: false }];

  antiDiabeticEnum = [
    { value: "true", name: "Yes" },
    { value: "false", name: "No" }
  ];
  sorting = [{ columnName: "medicineID", direction: "asc" }];

  //ACTION
  clearEditedData = () => {
    this.editedData = {};
  };

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setPageLimit = value => {
    this.editedData.pageSize = value;
  };

  setSearchQuery = searchValue => {
    this.editedData.currentPage = 0;
    this.editedData.searchQuery = searchValue;
  };

  setCurrentPage = currentPage => {
    this.editedData.currentPage = currentPage;
    this.getAllMedicine();
  };

  setSorting = sorting => {
    this.sorting = sorting;
  };

  validateEmptyField() {
    if (this.editedData.name === "" || this.editedData.isAntiDiabetic === "") {
      commonStore.showErrorMessage("All fields must be filled!");
      return false;
    } else return true;
  }

  getAllMedicine = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await GetAllMedicine({
      offset: Number(this.editedData.currentPage),
      limit: this.editedData.pageSize,
      search: String(this.editedData.searchQuery),
      order: this.sorting[0].direction
    });

    if (data) {
      this.editedData.isLoading = false;

      this.editedData.totalCount = Number(data.count);
      this.dataArray = data.medicine.map(data => {
        return {
          ...data,
          medicineID: Number(data.medicineID)
        };
      });
    } else if (error) {
      this.editedData.isLoading = false;

      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  getByIDMedicine = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await GetByIDMedicine({
      id: String(this.editedData.medicineID)
    });

    this.editedData.isLoading = false;

    if (data) {
      this.setEditedData({
        name: String(data.name),
        isAntiDiabetic: String(data.isAntiDiabetic),
        medicineID: String(data.medicineID)
      });
      return { data };
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };

  postMedicine = async () => {
    this.editedData.isLoading = true;

    if (this.validateEmptyField()) {
      let { data, error } = await PostMedicine({
        name: String(this.editedData.name),
        isAntiDiabetic: String(this.editedData.isAntiDiabetic)
      });

      this.editedData.isLoading = false;

      if (data) {
        commonStore.showSuccessMessage("Medicine added successfully!");
        setTimeout(() => {
          window.location.href = "/medicines";
        }, 500);
      } else if (error) {
        if (error.data) {
          commonStore.showErrorMessage(error.data.message);
        } else {
          commonStore.showErrorMessage("Something went wrong.");
        }
      }
    }
  };

  putUpdateMedicine = async () => {
    this.editedData.isLoading = true;

    if (this.validateEmptyField()) {
      let { data, error } = await PutUpdateMedicine({
        name: String(this.editedData.name),
        isAntiDiabetic: String(this.editedData.isAntiDiabetic),
        id: String(this.editedData.medicineID)
      });

      this.editedData.isLoading = false;

      if (data) {
        commonStore.showSuccessMessage("Medicine updated successfully!");
        setTimeout(() => {
          window.location.href = "/medicines";
        }, 500);
      } else if (error) {
        if (error.data) {
          commonStore.showErrorMessage(error.data.message);
        } else {
          commonStore.showErrorMessage("Something went wrong.");
        }
      }
    }
  };

  putDeleteMedicine = async () => {
    this.editedData.isLoading = true;

    let { data, error } = await PutDeleteMedicine({
      id: String(this.editedData.medicineID)
    });

    this.editedData.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Medicine deleted successfully!");
      setTimeout(() => {
        window.location.href = "/medicines";
      }, 500);
    } else if (error) {
      if (error.data) {
        commonStore.showErrorMessage(error.data.message);
      } else {
        commonStore.showErrorMessage("Something went wrong.");
      }
    }
  };
}

decorate(MedicineStore, {
  //OBSERVABLE
  editedData: observable,
  dataArray: observable,
  pageSize: observable,

  //ACTION
  postMedicine: action,
  putUpdateMedicine: action,
  setEditedData: action
});

export default new MedicineStore();
