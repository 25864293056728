import axios from '../helper/Axios.js'
import {adminUrl} from '../config/config.js';

export async function loginWithEmailAndPassword({email, password}){
    try {
        let loginResponse = await axios.Post({
            url: `${adminUrl}/auth/admin/login`,
            data: {email, password, source: 'diabisa'},
        });

        if(loginResponse.statusCode !== 200){
            return {error: loginResponse.message};
        }

        return {data: loginResponse.data};
    } catch (e) {
        return {error: e};
    }
};
