import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetDrugReminders({ offset, limit }) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/drug-reminders?offset=${offset}&limit=${limit}`,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetDrugRemindersById(id) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/drug-reminders/id`,
      data: { id }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function CreateDrugRemindersById(data) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/drug-reminders`,
      data: data
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function UpdateDrugRemindersById(data) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/drug-reminders`,
      data: data
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function DeleteDrugRemindersById(id) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/drug-reminders/delete`,
      data: { id }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

