import { GetDashboardInfo } from "../services/DashboardService";
import { observable, decorate } from "mobx";
import commonStore from "./CommonStore";

class DashboardStore {
  editedData = {};
  testEnum = [
    { name: "test", value: "test" },
    { name: "test", value: "test" },
    { name: "test", value: "test" },
    { name: "test", value: "test" }
  ];
  column = [{ name: "name", title: "" }, { name: "count", title: "" }];
  columnWidths = [
    { columnName: "name", width: 100 },
    { columnName: "count", width: 100 }
  ];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  getDashboardInfo = async () => {
    let { data, error } = await GetDashboardInfo();

    if (data) {
      this.setEditedData({
        premiumUser: data.premiumUser,
        regularUser: data.regularUser,
        totalUser: data.totalUser,
        bloodHyper: data.bloodHyper,
        bloodHypo: data.bloodHypo,
      });
    } else {
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };
}
decorate(DashboardStore, {
  editedData: observable,
});
export default new DashboardStore();
