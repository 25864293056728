import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllDietRecord(
  id,
  category,
  startDate,
  endDate,
  search,
  offset,
  limit,
  order
) {
  try {
    let getAllDietRecord = await axios.Get({
      url: `${adminUrl}/diet-records?userId=${id}&category=${category}&startDate=${startDate}&endDate=${endDate}&search=${search ||
        ""}&offset=${offset}&limit=${limit}&order=${order.toUpperCase()}`
    });
    if (getAllDietRecord.statusCode !== 200) {
      return { error: getAllDietRecord.message };
    }

    return { data: getAllDietRecord.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdateDietRecord(
  dietRecordID,
  totalCalories,
  mealId,
  servingSize,
  forDay,
  category,
  userId,
  id
) {
  try {
    let putUpdateDietRecord = await axios.Put({
      url: `${adminUrl}/diet-records/${id}`,
      data: {
        dietRecordID,
        totalCalories,
        mealId,
        servingSize,
        forDay,
        category,
        userId
      }
    });
    if (putUpdateDietRecord.statusCode !== 200) {
      return { error: putUpdateDietRecord.message };
    }

    return { data: putUpdateDietRecord.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutDeleteDietRecord(id) {
  try {
    let putDeleteDietRecord = await axios.Put({
      url: `${adminUrl}/diet-records/${id}/delete`
    });

    if (putDeleteDietRecord.statusCode !== 200) {
      return { error: putDeleteDietRecord.message };
    }

    return { data: putDeleteDietRecord.data };
  } catch (e) {
    return { error: e };
  }
}

export async function GetDietRecordById(id) {
  try {
    let getDietRecordById = await axios.Get({
      url: `${adminUrl}/diet-records/${id}`
    });
    if (getDietRecordById.statusCode !== 200) {
      return { error: getDietRecordById.message };
    }

    return { data: getDietRecordById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
