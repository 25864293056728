import axios from "../helper/Axios.js";
import { adminUrl, adminSiloamUrl } from "../config/config.js";

export async function GetAllTag(lang, offset, limit, search) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/tags?lang=${lang || ""}&offset=${offset || ""}&limit=${limit || ""}&search=${search || ""}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetAllSpecialist() {
  try {
    let response = await axios.Get({
      url: `${adminSiloamUrl}/generals/specialities`
    });

    if (response.status !== "OK") {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetAllSpecializationTag(tagId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/specialization-tag?tagId=${tagId || ''}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function CreateSpecializationTag(tagId, specializationId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/specialization-tag`,
      data: { tagId, specializationId }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}