import React from "react";

const Dashboard = React.lazy(() => import("./views/Dashboard"));
const Admin = React.lazy(() => import("./views/UsersManagement/Admin/Admin"));
const Users = React.lazy(() => import("./views/UsersManagement/Users"));
const CreateAdmin = React.lazy(() =>
  import("./views/UsersManagement/Admin/CreateAdmin")
);
const UpdateAdmin = React.lazy(() =>
  import("./views/UsersManagement/Admin/CreateAdmin")
);
const ExerciseList = React.lazy(() => import("./views/Exercise/ExerciseList"));
const ExerciseUpdate = React.lazy(() =>
  import("./views/Exercise/ExerciseUpdate")
);
const SymptomList = React.lazy(() => import("./views/Symptom/SymptomList"));
const SymptomUpdate = React.lazy(() => import("./views/Symptom/SymptomUpdate"));
const MedicineList = React.lazy(() => import("./views/Medicine/MedicineList"));
const MedicineUpdate = React.lazy(() =>
  import("./views/Medicine/MedicineUpdate")
);
const MealList = React.lazy(() => import("./views/Meal/MealList"));
const MealUpdate = React.lazy(() => import("./views/Meal/MealUpdate"));
const DietPlanList = React.lazy(() => import("./views/DietPlan/DietPlanList"));
const DietPlanUpdate = React.lazy(() =>
  import("./views/DietPlan/DietPlanUpdate")
);
const DietPlanTabs = React.lazy(() => import("./views/DietPlan/DietPlanTabs"));
const DietPlanMealUpdate = React.lazy(() =>
  import("./views/DietPlan/DietPlanMealUpdate")
);
// const DoctorList = React.lazy(() =>
//   import("./views/UsersManagement/Doctor/DoctorList")
// );
// const DoctorUpdateUM = React.lazy(() =>
//   import("./views/UsersManagement/Doctor/DoctorUpdate")
// );
// const DoctorTab = React.lazy(() =>
//   import("./views/UsersManagement/Doctor/DoctorTab")
// );
// const DoctorChangePassword = React.lazy(() =>
//   import("./views/UsersManagement/Doctor/DoctorChangePassword")
// );
// const DoctorUpdate = React.lazy(() =>
//   import("./views/Hospital/ListCategory/Doctor/DoctorUpdate")
// );
const SiloamDoctorsList = React.lazy(() =>
  import("./views/SiloamDoctors/SiloamDoctorsList")
);
const SiloamDoctorsDetail = React.lazy(() =>
  import("./views/SiloamDoctors/SiloamDoctorDetail")
);
const DiabeticExpertList = React.lazy(() =>
  import("./views/UsersManagement/DiabeticExpert/DiabeticExpertList")
);
const DiabeticExpertAdd = React.lazy(() =>
  import("./views/UsersManagement/DiabeticExpert/DiabeticExpertUpdate")
);
const PatientList = React.lazy(() => import("./views/Patient/PatientList"));
const PatientAdd = React.lazy(() => import("./views/Patient/PatientAdd"));
const PatientTab = React.lazy(() => import("./views/Patient/PatientTab"));
const BloodGlucoseUpdate = React.lazy(() =>
  import("./views/Patient/HealthTracker/BloodGlucose/BloodGlucoseUpdate")
);
const WeightUpdate = React.lazy(() =>
  import("./views/Patient/HealthTracker/Weight/WeightUpdate")
);
const DietUpdate = React.lazy(() =>
  import("./views/Patient/HealthTracker/Diet/DietPatientUpdate")
);
const MedicationUpdate = React.lazy(() =>
  import("./views/Patient/HealthTracker/Medication/MedicationUpdate")
);
const SymptomsPatientUpdate = React.lazy(() =>
  import("./views/Patient/HealthTracker/Symptoms/SymptomsPatientUpdate")
);
const ExercisePatientUpdate = React.lazy(() =>
  import("./views/Patient/HealthTracker/Exercise/ExercisePatientUpdate")
);
const PrescriptionUpdate = React.lazy(() =>
  import("./views/Patient/HealthTracker/Prescription/PrescriptionUpdate")
);
const PresMedicationTime = React.lazy(() =>
  import("./views/Patient/HealthTracker/Prescription/MedicationTimeList")
);
const EducationList = React.lazy(() =>
  import("./views/Education/EducationList")
);
const EducationUpdate = React.lazy(() =>
  import("./views/Education/EducationUpdate")
);
const Broadcast = React.lazy(() => import("./views/Broadcast/BroadcastList"));
const BroadcastUpdate = React.lazy(() =>
  import("./views/Broadcast/BroadcastUpdate")
);
const HospitalData = React.lazy(() => import("./views/Hospital/HospitalData"));
const AreaUpdate = React.lazy(() =>
  import("./views/Hospital/ListCategory/Area/AreaUpdate")
);
const HospitalUpdate = React.lazy(() =>
  import("./views/Hospital/ListCategory/Hospital/HospitalUpdate")
);
const SpecialistUpdate = React.lazy(() =>
  import("./views/Hospital/ListCategory/Specialist/SpecialistUpdate")
);
const AppointmentList = React.lazy(() =>
  import("./views/Appointment/AppointmentList")
);
const AppointmentUpdate = React.lazy(() =>
  import("./views/Appointment/AppointmentUpdate")
);
const BannerList = React.lazy(() => import("./views/Banner/BannerList"));
const BannerDetail = React.lazy(() => import("./views/Banner/BannerDetail"));
const GlobalSettingList = React.lazy(() =>
  import("./views/GlobalSetting/GlobalSettingList")
);
const GlobalSettingDetail = React.lazy(() =>
  import("./views/GlobalSetting/GlobalSettingDetail")
);
const InterestList = React.lazy(() => import("./views/Interest/InterestList"));
const InterestDetail = React.lazy(() =>
  import("./views/Interest/interestDetail")
);
const HabitList = React.lazy(() => import("./views/Habit/HabitList"));
const HabitDetail = React.lazy(() => import("./views/Habit/HabitDetail"));
const MiniGameList = React.lazy(() => import("./views/MiniGame/MiniGameList"));
const MiniGameListDetail = React.lazy(() =>
  import("./views/MiniGame/MiniGameDetail")
);
const MiniGameQuestion = React.lazy(() =>
  import("./views/MiniGame/MiniGameQuestion")
);
const UniqueCode = React.lazy(() => import("./views/UniqueCode/UniqueCode"));
const UniqueCodeUpdate = React.lazy(() =>
  import("./views/UniqueCode/UniqueCodeUpdate")
);
const RegistForm = React.lazy(() => import("./views/RegistForm/RegistForm"));
const RegistFormUpdate = React.lazy(() =>
  import("./views/RegistForm/RegistFormUpdate")
);
const HealthDeclarationFormList = React.lazy(() =>
  import("./views/HealthDeclarationForm/HealthDeclarationFormList")
);
const HealthDeclarationFormDetail = React.lazy(() =>
  import("./views/HealthDeclarationForm/HealthDeclarationFormDetail")
);
const FormQuestionDetail = React.lazy(() =>
  import("./views/HealthDeclarationForm/FormQuestionDetail")
);
const PageList = React.lazy(() => import("./views/Page/PageList"));
const PageDetail = React.lazy(() => import("./views/Page/PageDetail"));
const PageManagementList = React.lazy(() =>
  import("./views/PageManagement/PageManagementList")
);
const PageManagementDetail = React.lazy(() =>
  import("./views/PageManagement/PageManagementDetail")
);
const FormScoringDetail = React.lazy(() =>
  import("./views/HealthDeclarationForm/FormScoringDetail")
);
const MaintenanceDetail = React.lazy(() =>
  import("./views/Maintenance/MaintenanceDetail")
);
const UserFormList = React.lazy(() => import("./views/UserForm/UserFormList"));
const BloodGlucoseAbnormalities = React.lazy(() =>
  import("./views/BloodGlucose/BloodGlucoseAbnormalities")
);
const BloodGlucoseTargetLimit = React.lazy(() =>
  import("./views/BloodGlucose/BloodGlucoseTargetLimit")
);
const BloodGlucoseTargetDefaultValue = React.lazy(() =>
  import("./views/BloodGlucose/BloodGlucoseTargetDefaultValue")
);
const BloodGlucoseAlertManagement = React.lazy(() =>
  import("./views/BloodGlucose/BloodGlucoseAlertManagement")
);
const DrugReminderList = React.lazy(() =>
  import("./views/DrugReminder/DrugReminderList")
);
const DrugReminderUpdate = React.lazy(() =>
  import("./views/DrugReminder/DrugReminderUpdate")
);
const InputLimit = React.lazy(() =>
  import("./views/InputLimit/InputLimit")
);

const routes = [
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/users", name: "Users", component: Users },
  {
    path: "/admins/:id",
    name: "Detail",
    component: UpdateAdmin,
    exact: true,
  },
  { path: "/admins/new", name: "Add Admin", component: CreateAdmin },
  { path: "/admins", name: "Admin", component: Admin, exact: true },
  // { path: "/doctors/new", name: "Doctor Add", component: DoctorUpdateUM },
  // {
  //   path: "/doctors/:id/changepassword",
  //   name: "Change Password",
  //   component: DoctorChangePassword,
  // },
  // {
  //   path: "/doctors/:id/profile",
  //   name: "Doctor Profile",
  //   component: DoctorTab,
  // },
  // {
  //   path: "/doctors/:id/patients",
  //   name: "Doctor Patient",
  //   component: DoctorTab,
  // },
  // { path: "/doctors/:id", name: "Detail", component: DoctorTab },
  // { path: "/doctors", name: "Doctors", component: DoctorList },
  // {
  //   path: "/siloam-doctor/:id/:hospitalId",
  //   name: "Doctor Detail",
  //   component: DoctorUpdate,
  // },
  {
    path: "/siloam-doctors",
    name: "Siloam Doctors",
    component: SiloamDoctorsList,
    exact: true,
  },
  {
    path: "/siloam-doctors/:id/:hospitalId",
    name: "Siloam Doctors Detail",
    component: SiloamDoctorsDetail,
    exact: true,
  },
  {
    path: "/exercises/new",
    name: "Add New Exercise",
    component: ExerciseUpdate,
  },
  {
    path: "/exercises/:id",
    name: "Detail",
    component: ExerciseUpdate,
  },
  { path: "/exercises", name: "Exercises", component: ExerciseList },
  { path: "/symptom/new", name: "Add New Symptom", component: SymptomUpdate },
  { path: "/symptom/:id", name: "Detail", component: SymptomUpdate },
  { path: "/symptom", name: "Symptom list", component: SymptomList },
  {
    path: "/medicines/new",
    name: "Add New Medicine",
    component: MedicineUpdate,
  },
  {
    path: "/medicines/:id",
    name: "Detail",
    component: MedicineUpdate,
  },
  { path: "/medicines", name: "Medicine List", component: MedicineList },
  { path: "/meals/new", name: "Add New Meal", component: MealUpdate },
  { path: "/meals/:id", name: "Detail", component: MealUpdate },
  { path: "/meals", name: "Meal List", component: MealList },
  {
    path: "/dietplans/:id/meal/new",
    name: "Diet Plan Meal Add",
    component: DietPlanMealUpdate,
  },
  {
    path: "/dietplans/:id/meal/:mealid",
    name: "Diet Plan Meal Update",
    component: DietPlanMealUpdate,
  },
  {
    path: "/dietplans/:id/detail",
    name: "Diet Plan Detail",
    component: DietPlanTabs,
  },
  {
    path: "/dietplans/:id/meal",
    name: "Diet Plan Meal",
    component: DietPlanTabs,
  },
  { path: "/dietplans/new", name: "Diet Plan Add", component: DietPlanUpdate },
  {
    path: "/dietplans/:id",
    name: "Diet Plan Details",
    component: DietPlanTabs,
  },
  { path: "/dietplans", name: "Diet Plan", component: DietPlanList },
  { path: "/patients", exact: true, name: "Patients", component: PatientList },

  {
    path: "/patients/:id/healthtracker/bloodglucoses/:userId",
    name: "Blood Glucose Update",
    component: BloodGlucoseUpdate,
    exact: true,
  },
  {
    path: "/patients/:userId/healthtracker/weight/:id",
    name: "Weight Update",
    component: WeightUpdate,
    exact: true,
  },
  {
    path: "/patients/:userId/healthtracker/diet/:id",
    name: "Diet Update",
    component: DietUpdate,
    exact: true,
  },
  {
    path: "/patients/:userId/healthtracker/exercise/:id",
    name: "Exercise Update",
    component: ExercisePatientUpdate,
    exact: true,
  },
  {
    path: "/patients/:userId/healthtracker/medication/:id",
    name: "Medication Update",
    component: MedicationUpdate,
    exact: true,
  },
  {
    path: "/patients/:userId/healthtracker/symptoms/:id",
    name: "Symptoms Update",
    component: SymptomsPatientUpdate,
    exact: true,
  },
  {
    path: "/patients/:userId/healthtracker/prescription/:id",
    name: "Prescriptions Update",
    component: PrescriptionUpdate,
    exact: true,
  },
  {
    path: "/patients/:userId/healthtracker/prescription/:id/medicationTime",
    name: "Medication Time",
    component: PresMedicationTime,
    exact: true,
  },
  {
    path: "/patients/:id/userinfo",
    name: "Patient Info",
    component: PatientTab,
    exact: true,
  },
  {
    path: "/patients/:id/healthtracker",
    name: "Patient Health Tracker",
    component: PatientTab,
    exact: true,
  },
  {
    path: "/patients/new",
    name: "Patient Add",
    component: PatientAdd,
    exact: true,
  },
  {
    path: "/diabetic-educator",
    name: "Diabetic Educator",
    exact: true,
    component: DiabeticExpertList,
  },
  {
    path: "/diabetic-educator/new",
    name: "Add Diabetic Educator",
    exact: true,
    component: DiabeticExpertAdd,
  },
  {
    path: "/diabetic-educator/:id",
    name: "Detail",
    exact: true,
    component: DiabeticExpertAdd,
  },
  {
    path: "/educations/new",
    name: "Educations Add",
    component: EducationUpdate,
  },
  {
    path: "/educations/:id",
    name: "Educations Update",
    component: EducationUpdate,
  },
  { path: "/educations", name: "Educations", component: EducationList },
  {
    path: "/notifications",
    exact: true,
    name: "Broadcast",
    component: Broadcast,
  },
  {
    path: "/notifications/new",
    name: "Add Broadcast",
    component: BroadcastUpdate,
  },
  {
    path: "/notifications/:id",
    name: "Update Broadcast",
    component: BroadcastUpdate,
  },
  {
    path: "/hospitals/specialist/:id",
    name: "Detail",
    component: SpecialistUpdate,
  },
  {
    path: "/hospitals/hospital/:id",
    name: "Detail",
    component: HospitalUpdate,
  },
  { path: "/hospitals/area/:id", name: "Detail", component: AreaUpdate },
  {
    path: "/hospitals",
    name: "Hospital",
    component: HospitalData,
    exact: true,
  },
  {
    path: "/appointment",
    name: "Appointment",
    component: AppointmentList,
    exact: true,
  },
  {
    path: "/appointment/new",
    name: "Add Appointment",
    component: AppointmentUpdate,
  },
  {
    path: "/banner",
    name: "Banner",
    component: BannerList,
    exact: true,
  },
  {
    path: "/banner/detail",
    name: "Banner Detail",
    component: BannerDetail,
    exact: true,
  },
  {
    path: "/banner/detail/:id",
    name: "Update Banner",
    component: BannerDetail,
  },
  {
    path: "/global-setting",
    name: "Global Settings",
    component: GlobalSettingList,
    exact: true,
  },
  {
    path: "/global-setting/:id",
    name: "Global Settings Detail",
    component: GlobalSettingDetail,
    exact: true,
  },
  {
    path: "/global-setting/new",
    name: "Add New Global Settings",
    component: GlobalSettingDetail,
    exact: true,
  },
  {
    path: "/interest",
    name: "Interest",
    component: InterestList,
    exact: true,
  },
  {
    path: "/interest/:id",
    name: "Interest Detail",
    component: InterestDetail,
    exact: true,
  },
  {
    path: "/habit",
    name: "Habit",
    component: HabitList,
    exact: true,
  },
  {
    path: "/habit/:id",
    name: "Habit Detail",
    component: HabitDetail,
    exact: true,
  },
  {
    path: "/habit/new",
    name: "Habit Detail",
    component: HabitDetail,
    exact: true,
  },
  {
    path: "/mini-game",
    name: "Mini Game",
    component: MiniGameList,
    exact: true,
  },
  {
    path: "/mini-game/:id",
    name: "Mini Game Detail",
    component: MiniGameListDetail,
    exact: true,
  },
  {
    path: "/mini-game/new",
    name: "Mini Game Detail",
    component: MiniGameListDetail,
    exact: true,
  },
  {
    path: "/mini-game/:id/question/:questionId",
    name: "Mini Game Question",
    component: MiniGameQuestion,
    exact: true,
  },
  {
    path: "/mini-game/:id/question/new",
    name: "Mini Game Question",
    component: MiniGameQuestion,
    exact: true,
  },
  {
    path: "/unique-code",
    name: "Unique Code",
    component: UniqueCode,
    exact: true,
  },
  {
    path: "/unique-code/new",
    name: "Create Code",
    component: UniqueCodeUpdate,
  },
  { path: "/unique-code/:id", name: "Detail", component: UniqueCodeUpdate },
  {
    path: "/regist-form",
    name: "Regist Form",
    component: RegistForm,
    exact: true,
  },
  {
    path: "/regist-form/:id",
    name: "DiaBISA Regist Form",
    component: RegistFormUpdate,
  },
  {
    path: "/health-declaration-forms",
    name: "Health Declaration Form",
    component: HealthDeclarationFormList,
    exact: true,
  },
  {
    path: "/health-declaration-forms/:id",
    name: "Health Declaration Form Detail",
    component: HealthDeclarationFormDetail,
    exact: true,
  },
  {
    path: "/health-declaration-forms/new",
    name: "Add New Health Declaration Form",
    component: HealthDeclarationFormDetail,
    exact: true,
  },
  {
    path: "/health-declaration-forms/:id/form-questions/:formQuestionId",
    name: "Form Question Detail",
    component: FormQuestionDetail,
    exact: true,
  },
  {
    path: "/health-declaration-forms/:id/form-questions/new",
    name: "Add New Form Question",
    component: FormQuestionDetail,
    exact: true,
  },
  {
    path: "/user-form",
    name: "Form Result",
    component: UserFormList,
    exact: true,
  },
  {
    path: "/page",
    name: "Page",
    component: PageList,
    exact: true,
  },
  {
    path: "/page/:id",
    name: "Page Detail",
    component: PageDetail,
    exact: true,
  },
  {
    path: "/page/new",
    name: "Add New Page",
    component: PageDetail,
    exact: true,
  },
  {
    path: "/page-management",
    name: "Page Management",
    component: PageManagementList,
    exact: true,
  },
  {
    path: "/page-management/:pageManagementId",
    name: "Page Management Detail",
    component: PageManagementDetail,
  },
  {
    path: "/health-declaration-forms/:id/form-results/new",
    name: "Add New Form Result",
    component: FormScoringDetail,
    exact: true,
  },
  {
    path: "/health-declaration-forms/:id/form-results/:formScoringId",
    name: "Add New Form Result",
    component: FormScoringDetail,
    exact: true,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: MaintenanceDetail,
    exact: true,
  },
  {
    path: "/abnormalities",
    name: "Blood Glucose Abnormalities Definition",
    component: BloodGlucoseAbnormalities,
    exact: true,
  },
  {
    path: "/target-limit",
    name: "Target Limit",
    component: BloodGlucoseTargetLimit,
    exact: true,
  },
  {
    path: "/target-default",
    name: "Target Default",
    component: BloodGlucoseTargetDefaultValue,
    exact: true,
  },
  {
    path: "/alert-management",
    name: "Alert Management",
    component: BloodGlucoseAlertManagement,
    exact: true,
  },
  {
    path: "/drug-reminder",
    name: "Drug Reminder",
    component: DrugReminderList,
    exact: true,
  },
  {
    path: "/drug-reminder/new",
    name: "Create Drug Reminder",
    component: DrugReminderUpdate,
    exact: true,
  },
  {
    path: "/drug-reminder/detail/:id",
    name: "Drug Reminder Detail",
    component: DrugReminderUpdate,
    exact: true,
  },
  {
    path: "/blood-glucose-limit",
    name: "Input Limit",
    component: InputLimit,
    exact: true,
  },
];

export default routes;
