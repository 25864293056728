import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function CreateAdmin({ name, email, password, role }) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/admins`,
      data: { name, email, password, role }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetAllAdmin({ offset, limit, search, role, order }) {
  try {
    let getAllAdminResponse = await axios.Get({
      url: `${adminUrl}/admins?offset=${offset || ""}&limit=${limit ||
        ""}&order=${order.toUpperCase()}&search=${search || ""}&role=${role ||
        ""}&source=diabisa`
    });

    if (getAllAdminResponse.statusCode !== 200) {
      return { error: getAllAdminResponse.message };
    }

    return { data: getAllAdminResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetByIDAdmin({ id }) {
  try {
    let getByIDAdminResponse = await axios.Get({
      url: `${adminUrl}/admins/${id}`
    });

    if (getByIDAdminResponse.statusCode !== 200) {
      return { error: getByIDAdminResponse.message };
    }

    return { data: getByIDAdminResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdateAdmin({ name, email, id }) {
  try {
    let putUpdateAdminResponse = await axios.Put({
      url: `${adminUrl}/admins/${id}`,
      data: { name, email }
    });

    if (putUpdateAdminResponse.statusCode !== 200) {
      return { error: putUpdateAdminResponse.message };
    }

    return { data: putUpdateAdminResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutDeleteAdmin({ id }) {
  try {
    let putDeleteAdminResponse = await axios.Put({
      url: `${adminUrl}/admins/${id}/delete`
    });

    if (putDeleteAdminResponse.statusCode !== 200) {
      return { error: putDeleteAdminResponse.message };
    }

    return { data: putDeleteAdminResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdatePasswordAdmin({
  id,
  newPassword,
  confirmNewPassword
}) {
  try {
    let putUpdatePasswordAdminResponse = await axios.Put({
      url: `${adminUrl}/admins/${id}/password`,
      data: { newPassword, confirmNewPassword }
    });

    return { data: putUpdatePasswordAdminResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
