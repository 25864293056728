import axios from '../helper/Axios.js'
import { adminUrl } from '../config/config.js';

export async function GetAllDietPlan(){
  try{
      let getAllDietPlanResponse = await axios.Get({
          url: `${adminUrl}/diet-plans`,
      });
      if (getAllDietPlanResponse.statusCode !== 200) {
          return { error: getAllDietPlanResponse.message };
      }

        return { data: getAllDietPlanResponse.data };

    }catch(e)
    {
        return { error: e }
    }
}

export async function GetByIDDietPlan(id){
  try{
      let getByIDDietPlanResponse = await axios.Get({
          url: `${adminUrl}/diet-plans/${id}`,
      });
      if (getByIDDietPlanResponse.statusCode !== 200) {
          return { error: getByIDDietPlanResponse.message };
      }

        return { data: getByIDDietPlanResponse.data };

    }catch(e)
    {
        return { error: e }
    }
}

export async function PostDietPlan(name, description, calories, author, imageUrl , type, userId, numberOfDays){
    try{
        let postDietPlan = await axios.Post({
            url:`${adminUrl}/diet-plans`,
            data: { name, description, calories, author, imageUrl , type, userId, numberOfDays }
        });

        if(postDietPlan.statusCode!==200){
            return { error:postDietPlan.message };
        }

        return {data: postDietPlan.data};
    } catch(e)
    {
        return { error: e};
    }
}

export async function PutUpdateDietPlan(name, description, calories, author, imageUrl , type, userId, numberOfDays, id){
    try{
        let putUpdateDietPlan = await axios.Put({
            url:`${adminUrl}/diet-plans/${id}`,
            data: {name, description, calories, author, imageUrl , type, userId, numberOfDays}
        });

        if(putUpdateDietPlan.statusCode!==200){
            return { error:putUpdateDietPlan.message };
        }

        return {data: putUpdateDietPlan.data};
    } catch(e)
    {
        return { error: e};
    }
}

export async function PutDeleteDietPlan(id){
    try{
        let putDeleteDietPlan = await axios.Put({
            url:`${adminUrl}/diet-plans/delete/${id}`
        });

        if(putDeleteDietPlan.statusCode!==200){
            return { error:putDeleteDietPlan.message };
        }

        return {data: putDeleteDietPlan.data};
    } catch(e)
    {
        return { error: e};
    }
}

//-----------------customized----------------------//
export async function GetAllMealDietPlan(id){
  try{
      let getAllDietPlanResponse = await axios.Get({
          url: `${adminUrl}/meal-diet-plans?dietPlanId=`+id,
      });
      if (getAllDietPlanResponse.statusCode !== 200) {
          return { error: getAllDietPlanResponse.message };
      }

        return { data: getAllDietPlanResponse.data };

    }catch(e)
    {
        return { error: e }
    }
}

export async function GetByIDMealDietPlan(id){
  try{
      let getByIDMealDietPlan = await axios.Get({
          url: `${adminUrl}/meal-diet-plans/${id}`,
      });
      if (getByIDMealDietPlan.statusCode !== 200) {
          return { error: getByIDMealDietPlan.message };
      }

        return { data: getByIDMealDietPlan.data };

    }catch(e)
    {
        return { error: e }
    }
}

export async function PostMealDietPlan(type, day, mealId, dietPlanId, servingSize){
    try{
        let postMealDietPlan = await axios.Post({
            url:`${adminUrl}/meal-diet-plans`,
            data: { type, day, mealId, dietPlanId, servingSize }
        });

        if(postMealDietPlan.statusCode!==200){
            return { error:postMealDietPlan.message };
        }

        return {data: postMealDietPlan.data};
    } catch(e)
    {
        return { error: e};
    }
}

export async function PutUpdateMealDietPlan(type, day, mealId, dietPlanId, servingSize, id){
    try{
        let putUpdateMealDietPlan = await axios.Put({
            url:`${adminUrl}/meal-diet-plans/${id}`,
            data: { type, day, mealId, dietPlanId, servingSize, id }
        });

        if(putUpdateMealDietPlan.statusCode!==200){
            return { error:putUpdateMealDietPlan.message };
        }

        return {data: putUpdateMealDietPlan.data};
    } catch(e)
    {
        return { error: e};
    }
}

export async function PutDeleteMealDietPlan(id){
    try{
        let putDeleteDietPlan = await axios.Put({
            url:`${adminUrl}/meal-diet-plans/delete/${id}`
        });

        if(putDeleteDietPlan.statusCode!==200){
            return { error:putDeleteDietPlan.message };
        }

        return {data: putDeleteDietPlan.data};
    } catch(e)
    {
        return { error: e};
    }
}


//---------------------Duplicate Diet Plan------------------------
export async function PostDuplicateDietPlan(planId){
    try{
        let postDuplicateDietPlan = await axios.Post({
            url:`${adminUrl}/diet-plans/duplicate/${planId}`,
        });

        if(postDuplicateDietPlan.statusCode!==200){
            return { error:postDuplicateDietPlan.message };
        }

        return {data: postDuplicateDietPlan.data};
    } catch(e)
    {
        return { error: e};
    }
}
