import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetUserTags(userId) {
  try {
    let getUserTags = await axios.Get({
      url: `${adminUrl}/user-tags?userId=${userId || ''}`
    });

    if (getUserTags.statusCode !== 200) {
      return { error: getUserTags.message };
    }

    return { data: getUserTags.data };
  } catch (e) {
    return { error: e };
  }
}
