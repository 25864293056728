import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllUserDoctor(
  userUserID,
  startDate,
  endDate,
  search,
  offset,
  limit
) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/user-doctor?userId=${userUserID}&startDate=${startDate ||
        ""}&endDate=${endDate || ""}&search=${search ||
        ""}&offset=${offset}&limit=${limit}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetUserDoctorById(id) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/user-doctor/${id}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
