import { observable, decorate } from "mobx";
import {
  GetBloodGlucoseTargetDefaultValue,
  UpdateBloodGlucoseTargetDefaultValue,
} from "../services/BloodGlucoseTargetDefaultValueService";
import commonStore from "./CommonStore";

class BloodGlucoseTargetDefaultValueStore {
  editedData = {};
  loading = false;

  setEditedData = (updatedData) => {
    this.editedData = {
      ...this.editedData,
      ...updatedData,
    };
  };

  getBloodGlucoseTargetDefaultValue = async () => {
    this.loading = true;

    let { data, error } = await GetBloodGlucoseTargetDefaultValue();

    if (data) {
      this.loading = false;

      for (let i = 0; i < data.bloodGlucoseConfig.length; i += 1) {
        const curr = data.bloodGlucoseConfig[i];

        if (curr.bloodType === "bloodGlucoseBeforeBreakfast") {
          this.editedData.beforeBreakfastId = curr.id;
          this.editedData.beforeBreakfastMinValue = curr.minValue;
          this.editedData.beforeBreakfastMaxValue = curr.maxValue;
        } else if (curr.bloodType === "bloodGlucoseBeforeLunchDinner") {
          this.editedData.beforeLunchDinnerId = curr.id;
          this.editedData.beforeLunchDinnerMinValue = curr.minValue;
          this.editedData.beforeLunchDinnerMaxValue = curr.maxValue;
        } else if (curr.bloodType === "bloodGlucoseAfterMeal") {
          this.editedData.afterMealId = curr.id;
          this.editedData.afterMealMinValue = curr.minValue;
          this.editedData.afterMealMaxValue = curr.maxValue;
        } else if (curr.bloodType === "bloodGlucoseBedTime") {
          this.editedData.bedTimeId = curr.id;
          this.editedData.bedTimeMinValue = curr.minValue;
          this.editedData.bedTimeMaxValue = curr.maxValue;
        } else if (curr.bloodType === "bloodGlucoseHba1c") {
          this.editedData.hba1cId = curr.id;
          this.editedData.hba1cMinValue = curr.minValue;
          this.editedData.hba1cMaxValue = curr.maxValue;
        }
      }
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };

  updateBloodGlucoseTargetDefaultValue = async () => {
    this.loading = true;

    const updateArray = [];

    const tempBeforeBreakfast = {
      id: this.editedData.beforeBreakfastId,
      minValue: this.editedData.beforeBreakfastMinValue,
      maxValue: this.editedData.beforeBreakfastMaxValue,
    };

    const tempBeforeLunchDinner = {
      id: this.editedData.beforeLunchDinnerId,
      minValue: this.editedData.beforeLunchDinnerMinValue,
      maxValue: this.editedData.beforeLunchDinnerMaxValue,
    };

    const tempAfterMeal = {
      id: this.editedData.afterMealId,
      minValue: this.editedData.afterMealMinValue,
      maxValue: this.editedData.afterMealMaxValue,
    };

    const tempBedTime = {
      id: this.editedData.bedTimeId,
      minValue: this.editedData.bedTimeMinValue,
      maxValue: this.editedData.bedTimeMaxValue,
    };

    const tempHba1c = {
      id: this.editedData.hba1cId,
      minValue: this.editedData.hba1cMinValue,
      maxValue: this.editedData.hba1cMaxValue,
    };

    updateArray.push(
      tempBeforeBreakfast,
      tempBeforeLunchDinner,
      tempAfterMeal,
      tempBedTime,
      tempHba1c
    );

    let { data, error } = await UpdateBloodGlucoseTargetDefaultValue(
      updateArray
    );

    if (data) {
      this.loading = false;
      commonStore.showSuccessMessage("Success");
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };
}

decorate(BloodGlucoseTargetDefaultValueStore, {
  editedData: observable,
});
export default new BloodGlucoseTargetDefaultValueStore();
