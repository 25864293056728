import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllSymptomsRecord(
  id,
  startDate,
  endDate,
  search,
  offset,
  limit,
  order
) {
  try {
    let getAllSymptomsRecord = await axios.Get({
      url: `${adminUrl}/symptoms-records?userId=${id}&startDate=${startDate}&endDate=${endDate}&search=${search ||
        ""}&offset=${offset}&limit=${limit}&order=${order.toUpperCase()}`
    });
    if (getAllSymptomsRecord.statusCode !== 200) {
      return { error: getAllSymptomsRecord.message };
    }

    return { data: getAllSymptomsRecord.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetSymptomsRecordById(id) {
  try {
    let getSymptomsRecordById = await axios.Get({
      url: `${adminUrl}/symptoms-records/${id}`
    });
    if (getSymptomsRecordById.statusCode !== 200) {
      return { error: getSymptomsRecordById.message };
    }

    return { data: getSymptomsRecordById.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdateSymptomsRecord(
  symptomsId,
  description,
  date,
  id
) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/symptoms-records/${id}`,
      data: { symptomsId, description, date }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutDeleteSymptomsRecord(id) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/symptoms-records/${id}/delete`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e };
  }
}
