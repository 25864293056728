import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetUsers() {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/users`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e };
  }
}

export async function getUserById(userId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/users/${userId}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data.message };
  }
}

export async function updateUserStatus({ userId, limit }) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/users-status/${userId}`,
      data: { limit }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data.message };
  }
}

export async function updateUserInfo({
  userId,
  name,
  HKID,
  phoneNumber,
  alternativePhoneNumber,
  province,
  city,
  district,
  dateOfBirth,
  contractEndDate,
  employer,
  ECAddress,
  description
}) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/users/id/${userId}`,
      data: {
        name,
        HKID,
        phoneNumber,
        alternativePhoneNumber,
        province,
        city,
        district,
        dateOfBirth,
        contractEndDate,
        employer,
        ECAddress,
        description
      }
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data.message };
  }
}

export async function updateUserContractImageFront({ userId, imageUrl }) {
  try {
    let response = await axios.Patch({
      url: `${adminUrl}/users/contract-image-front`,
      data: { userId, imageUrl }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data.message };
  }
}

export async function updateUserContractImageBack({ userId, imageUrl }) {
  try {
    let response = await axios.Patch({
      url: `${adminUrl}/users/contract-image-back`,
      data: { userId, imageUrl }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data.message };
  }
}

export async function updateUserHKIDImage({ userId, imageUrl }) {
  try {
    let response = await axios.Patch({
      url: `${adminUrl}/users/hkid-image`,
      data: { userId, imageUrl }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data.message };
  }
}

export async function updateUserHKIDWithImage({ userId, imageUrl }) {
  try {
    let response = await axios.Patch({
      url: `${adminUrl}/users/image-with-hkid`,
      data: { userId, imageUrl }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data.message };
  }
}

export async function updateUserSupportingDocument1({ userId, imageUrl }) {
  try {
    let response = await axios.Patch({
      url: `${adminUrl}/users/supporting-document-image-1`,
      data: { userId, imageUrl }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data.message };
  }
}

export async function updateUserSupportingDocument2({ userId, imageUrl }) {
  try {
    let response = await axios.Patch({
      url: `${adminUrl}/users/supporting-document-image-2`,
      data: { userId, imageUrl }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data.message };
  }
}

export async function updateUserSupportingDocument3({ userId, imageUrl }) {
  try {
    let response = await axios.Patch({
      url: `${adminUrl}/users/supporting-document-image-3`,
      data: { userId, imageUrl }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data.message };
  }
}

export async function updateUserDocumentApproval({ userId, documentType, status }) {
  try {
    let response = await axios.Patch({
      url: `${adminUrl}/users-documents/approval/${userId}`,
      data: { documentType, status }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data.message };
  }
}

export async function getUserBalanceHistories(userId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/balance-histories?user=${userId}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data.message };
  }
}

export async function getUserOrders(userId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/orders?user=${userId}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data.message };
  }
}
