import { GetGameScore } from "../services/MiniGameScoreService";
import { GetGameList } from "../services/MiniGameService";
import { observable, decorate } from "mobx";
import commonStore from "./CommonStore";

class MiniGameScoreStore {
  editedData = {
    imgLoading: false,
  };
  dataArray = [];
  gameListArray = [];
  gameListId = "";
  searchQuery = "";
  startDate = "";
  endDate = "";
  loading = false;
  pageSize = 10;
  currentPage = 0;
  totalCount = 0;
  columns = [
    { name: "id", title: "Record ID" },
    { name: "date", title: "Date" },
    { name: "name", title: "Mini Game Name" },
    { name: "score", title: "Score" },
  ];
  columnWidths = [
    { columnName: "id", width: 250 },
    { columnName: "date", width: 250 },
    { columnName: "name", width: 250 },
    { columnName: "score", width: 250 },
  ];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setSearchQuery = searchValue => {
    this.currentPage = 0;
    this.searchQuery = searchValue;
  };

  setStartDate = value => {
    this.currentPage = 0;
    this.startDate = value;
  };

  setEndDate = value => {
    this.currentPage = 0;
    this.endDate = value;
  };

  setCurrentPage = currentPage => {
    this.currentPage = currentPage;
    this.getGameList();
  };

  setGameListId = value => {
    this.gameListId = value;
  };

  clearEditedData = () => {
    this.editedData = {
      imgLoading: false,
    };

    this.startDate = "";
    this.endDate = "";
    this.searchQuery = "";
    this.gameListId = "";
  };

  getGameScore = async () => {
    this.loading = true;

    let { data, error } = await GetGameScore(
      this.gameListId,
      this.searchQuery,
      this.startDate,
      this.endDate,
      this.currentPage,
      this.pageSize,
    );

    if (data) {
      this.loading = false;
      this.totalCount = data.count;
      this.dataArray = data.score.map(s => ({
        ...s,
        name: s.gameList.name,
      }));
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getGameList = async () => {
    this.loading = true;

    let { data, error } = await GetGameList();

    if (data) {
      this.loading = false;
      this.gameListArray = data.gameList;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };
}

decorate(MiniGameScoreStore, {
  editedData: observable,
  dataArray: observable,
  gameListId: observable,
  startDate: observable,
  endDate: observable,
  searchQuery: observable,
  currentPage: observable,
  totalCount: observable,
  loading: observable,
});

export default new MiniGameScoreStore();
