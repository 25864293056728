import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function getUser({ status, keyword, offset, limit }) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/diabisa-users?search=${keyword || ""}&status=${
        status || ""
      }&offset=${offset || ""}&limit=${limit || ""}`,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getUserById({ data }) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/diabisa-users/id`,
      data,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function approveUser({ data }) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/diabisa-users/approve`,
      data,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function deleteUser({ data }) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/diabisa-users/delete`,
      data,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
