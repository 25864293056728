import { observable, decorate, action } from "mobx";
import {
  SyncArea,
  SyncHospital,
  SyncSpecialist,
  GetAllHospital
} from "../services/HospitalService";
import commonStore from "./CommonStore";

class HospitalStore {
  editedData = {
    hospitalDataCategory: "Area List",
    hospitalDataCategories: [
      "Area List",
      "Hospital List",
      "Specialist List"
    ]
  };
  dataArrayPackage = [];
  syncLoading = false;

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  syncAreaList = async () => {
    this.syncLoading = true;

    try {
      await SyncArea();
      commonStore.showSuccessMessage("Area List sync successful!");
      this.syncLoading = false;
    } catch (err) {
      this.syncLoading = false;
      commonStore.showErrorMessage(err);
    }
  };

  syncHospitalList = async () => {
    this.syncLoading = true;

    try {
      await SyncHospital();
      commonStore.showSuccessMessage("Hospital List sync successful!");
      this.syncLoading = false;
    } catch (err) {
      this.syncLoading = false;
      commonStore.showErrorMessage(err);
    }
  };

  syncSpecialistList = async () => {
    this.syncLoading = true;

    try {
      await SyncSpecialist();
      commonStore.showSuccessMessage("Specialist List sync successful!");
      this.syncLoading = false;
    } catch (err) {
      this.syncLoading = false;
      commonStore.showErrorMessage(err);
    }
  };

  getAllHospital = async () => {
    this.authLoading = true;

    let { data, error } = await GetAllHospital(this.editedData.isActive);

    if (data) {
      this.dataArrayPackage = data.hospitals.map(row => {
        const id = row.hospitalID ? row.hospitalID : "";
        const area_id = row.area_id ? row.area_id : "";
        const name = row.name ? row.name : "";

        return {
          ...row,
          id: id,
          area_id: area_id,
          name: name,
          data: data
        };
      });
      return {
        data
      };
    } else {
      return {
        error
      };
    }
  };
}

decorate(HospitalStore, {
  editedData: observable,
  dataArrayPackage: observable,
  syncLoading: observable,

  setEditedData: action,
  syncAreaList: action,
  syncHospitalList: action,
  syncSpecialistList: action,
  getAllHospital: action
});

export default new HospitalStore();
