import { getFormScoringByFormId, getFormScoringById } from "../services/FormScoringService";
import { observable, decorate } from "mobx";
import commonStore from "./CommonStore";

class FormScoringStore {
  editedData = {
    answer: [],
  };
  dataArray = [];
  loading = false;
  pageSize = 10;
  currentPage = 0;
  totalCount = 0;
  columns = [
    { name: "id", title: "Result ID" },
    { name: "color", title: "Result" },
  ];
  columnWidths = [
    { columnName: "id", width: 250 },
    { columnName: "color", width: 450 },
  ];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setCurrentPage = currentPage => {
    this.currentPage = currentPage;
    this.getFormQuestionByFormId();
  };

  clearEditedData = () => {
    this.editedData = {
      answer: [],
    };
  }

  getFormScoringByFormId = async (formId) => {
    this.loading = true;

    let { data, error } = await getFormScoringByFormId(formId);

    if (data) {
      this.loading = false;
      this.dataArray = data.formScoring;
      this.totalCount = data.count;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getFormScoringById = async (id) => {
    this.loading = true;

    let { data, error } = await getFormScoringById(id);

    if (data) {
      this.loading = false;
      this.editedData = data;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  }

  createFormScoring = async (formId, history, questionLength) => {
    this.loading = true;

    this.editedData.formId = formId;

    const tempAnser = [];
    for (let i = 0; i < questionLength - 1; i += 1) {
      if (this.editedData.answer.i === 'yes') {
        tempAnser.push(i)
      }
    }

    // let { data, error } = await createFormScoring(this.editedData);

    // if (data) {
    //   this.loading = false;
    //   history.push(`/health-declaration-forms/${formId}`);
    // } else {
    //   this.loading = false;
    //   if (error.data) {
    //     const errorMessage = error.data.message;
    //     commonStore.showErrorMessage(errorMessage);
    //   } else {
    //     commonStore.showErrorMessage("something went wrong");
    //   }
    // }
  }
}

decorate(FormScoringStore, {
  editedData: observable,
  dataArray: observable,
  loading: observable,
  pageSize: observable,
  currentPage: observable,
  totalCount: observable,
});

export default new FormScoringStore();
