import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function getMaintenance() {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/maintenances`,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function updateMaintenance(editedData) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/maintenances/${editedData.id}`,
      data: {
        ...editedData,
      },
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}