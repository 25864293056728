import {
    GetBodyMeasurements,
    GetBodyData,
    GetHeightRecord
} from "../services/BodyMeasurementService"
import { observable, action, decorate } from "mobx";

class BodyMeasurementStore {
    editedData = {
        height: "-",
        bmi: "-",
        neckCircumference: "-",
        waistCircumference: "-",
        upperHipCircumference: "-",
        lowerHipCircumference: "-",
        chestCircumference: "-"
    };
    loading = false;

    setEditedData = updatedData => {
        this.editedData = {
            ...this.editedData,
            ...updatedData
        };
    };

    getBodyMeasurementByUserId = async (userId) => {
        this.loading = true;

        let { data } = await GetBodyMeasurements(userId);

        if (data) {
            this.loading = false;
            if (data.bodyMeasurementRecord.length > 0) {
                const latestData = data.bodyMeasurementRecord[0];
                this.editedData.neckCircumference = latestData.neckCircumference;
                this.editedData.waistCircumference = latestData.waistCircumference;
                this.editedData.upperHipCircumference = latestData.upperHipCircumference;
                this.editedData.lowerHipCircumference = latestData.lowerHipCircumference;
                this.editedData.chestCircumference = latestData.chestCircumference;
            }
        }
    };

    getBodyDataByUserId = async (userId) => {
        this.loading = true;

        let { data } = await GetBodyData(userId);

        if (data) {
            this.loading = false;
            if (data.bodyData.length > 0) {
                const latestData = data.bodyData[0];
                this.editedData.bmi = latestData.bmi;
            }
        }
    }

    getHeightRecordByUserId = async (userId) => {
        this.loading = true;

        let { data } = await GetHeightRecord(userId);

        if (data) {
            this.loading = false;
            if (data.heightRecord.length > 0) {
                const latestData = data.heightRecord[0];
                this.editedData.height = latestData.height;
            }
        }
    }
};

decorate(BodyMeasurementStore, {
    editedData: observable,
    loading: observable,
    setEditedData: action,
});

export default new BodyMeasurementStore();