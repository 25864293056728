import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function SyncArea() {
  try {
    let syncAreaResponse = await axios.Post({
      url: `${adminUrl}/areas`
    });
    if (syncAreaResponse.statusCode !== 200) {
      return { error: syncAreaResponse.message };
    }
  } catch (e) {
    return { error: e };
  }
}

export async function SyncHospital() {
  try {
    let syncHospitalResponse = await axios.Post({
      url: `${adminUrl}/hospitals`
    });
    if (syncHospitalResponse.statusCode !== 200) {
      return { error: syncHospitalResponse.message };
    }
  } catch (e) {
    return { error: e };
  }
}

export async function SyncSpecialist() {
  try {
    let syncSpecialistResponse = await axios.Post({
      url: `${adminUrl}/specialities`
    });
    if (syncSpecialistResponse.statusCode !== 200) {
      return { error: syncSpecialistResponse.message };
    }
  } catch (e) {
    return { error: e };
  }
}

export async function GetAllHospital(isActive) {
  try {
    let getAllHospital = await axios.Get({
      url: `${adminUrl}/hospitals?isActive=${isActive}`
    });

    if (getAllHospital.statusCode !== 200) {
      return { error: getAllHospital.message };
    }

    return { data: getAllHospital.data };
  } catch (e) {
    return { error: e };
  }
}
