import { ExportToCsv } from 'export-to-csv';
import Moment from "moment";
import { getUserForms, exportUserForm } from "../services/UserFormService";
import { observable, decorate } from "mobx";
import commonStore from "./CommonStore";

class UserFormStore {
  editedData = {};
  dataArray = [];
  loading = false;
  pageSize = 10;
  currentPage = 0;
  columns = [
    { name: "contactId", title: "ID" },
    { name: "name", title: "Name" },
    { name: "email", title: "Email" },
    { name: "status", title: "Status" },
    { name: "hospitalName", title: "Hospital" },
    { name: "submittedDate", title: "Submitted Date" },
  ];
  columnWidths = [
    { columnName: "contactId", width: 250 },
    { columnName: "name", width: 250 },
    { columnName: "email", width: 250 },
    { columnName: "status", width: 250 },
    { columnName: "hospitalName", width: 250 },
    { columnName: "submittedDate", width: 250 },
  ];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setCurrentPage = currentPage => {
    this.currentPage = currentPage;
    this.getUserForms();
  };

  getUserForms = async () => {
    this.loading = true;

    let { data, error } = await getUserForms(1, this.currentPage, this.pageSize);

    if (data) {
      this.loading = false;
      this.totalCount = data.count;
      this.dataArray = data.resultArray;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  exportToCSVNew = async (formId, startDate, endDate) => {
    this.loading = true;

    let { data, error } = await exportUserForm(formId, startDate, endDate);

    if (data) {
      this.loading = false;

      const timeStamp = Moment().format('YYYY-MM-DD');

      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        filename: `Report_covid-${timeStamp}`,
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };

      const csvExporter = new ExportToCsv(options);

      if(data.resultArrayNew){
        csvExporter.generateCsv(data.resultArrayNew);
      }
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };
}

decorate(UserFormStore, {
  editedData: observable,
  dataArray: observable,
  loading: observable,
});

export default new UserFormStore();
