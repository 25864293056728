import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetBloodGlucoseLimit() {
  try {
    const getBloodGlucoseTargetLimits = await axios.Get({
      url: `${adminUrl}/blood-glucose-configs?type=inputLimit`,
    });

    if (getBloodGlucoseTargetLimits.statusCode !== 200) {
      return { error: getBloodGlucoseTargetLimits.message };
    }

    return { data: getBloodGlucoseTargetLimits.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function UpdateBloodGlucoseLimit(data) {
  try {
    const updateBloodGlucoseTargetLimits = await axios.Put({
      url: `${adminUrl}/blood-glucose-configs/update/input-limit`,
      data: data
    });

    if (updateBloodGlucoseTargetLimits.statusCode !== 200) {
      return { error: updateBloodGlucoseTargetLimits.message };
    }

    return { data: updateBloodGlucoseTargetLimits.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}