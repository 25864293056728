import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function getCode({ hospitalID, isActive, keyword, offset, limit }) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/diabisa-invitation-codes?search=${
        keyword || ""
      }&hospitalID=${hospitalID || ""}&isActive=${isActive || ""}&offset=${offset || ""}&limit=${limit || ""}`,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getCodeById({ data }) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/diabisa-invitation-codes/id`,
      data,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function createCode({ data }) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/diabisa-invitation-codes`,
      data,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function updateCode({ data }) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/diabisa-invitation-codes/update`,
      data,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
