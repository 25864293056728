import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllMeal({ offset, limit, search, order }) {
  try {
    let getAllMealResponse = await axios.Get({
      url: `${adminUrl}/meals?offset=${offset || ""}&limit=${limit ||
        ""}&search=${search || ""}&order=${order.toUpperCase()}`
    });

    if (getAllMealResponse.statusCode !== 200) {
      return { error: getAllMealResponse.message };
    }

    return { data: getAllMealResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function GetByIDMeal({ id }) {
  try {
    let getByIDMealResponse = await axios.Get({
      url: `${adminUrl}/meals/${id}`
    });

    if (getByIDMealResponse.statusCode !== 200) {
      return { error: getByIDMealResponse.message };
    }

    return { data: getByIDMealResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PostMeal({
  name,
  calories,
  servingSize,
  gram,
  carb,
  protein,
  fat
}) {
  try {
    let postMealResponse = await axios.Post({
      url: `${adminUrl}/meals`,
      data: { name, calories, servingSize, gram, carb, protein, fat }
    });

    if (postMealResponse.statusCode !== 200) {
      return { error: postMealResponse.message };
    }

    return { data: postMealResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutUpdateMeal({
  name,
  calories,
  servingSize,
  gram,
  carb,
  protein,
  fat,
  id
}) {
  try {
    let putUpdateMealResponse = await axios.Put({
      url: `${adminUrl}/meals/${id}`,
      data: { name, calories, servingSize, gram, carb, protein, fat }
    });

    if (putUpdateMealResponse.statusCode !== 200) {
      return { error: putUpdateMealResponse.message };
    }

    return { data: putUpdateMealResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}

export async function PutDeleteMeal({ id }) {
  try {
    let putDeleteMealResponse = await axios.Put({
      url: `${adminUrl}/meals/${id}/delete`
    });

    if (putDeleteMealResponse.statusCode !== 200) {
      return { error: putDeleteMealResponse.message };
    }

    return { data: putDeleteMealResponse.data };
  } catch (e) {
    if (e.response) {
      return { error: e.response };
    } else {
      return { error: e };
    }
  }
}
