import React from "react";
import {
  GetPageManagements,
  GetPageManagementNews,
  GetPageManagementById,
  GetPageManagementNewsById,
  UpdatePageManagement,
  UpdatePageManagementNews,
  UpdatePageManagementIsActive,
} from "../services/PageManagementService";
import { GetPages } from "../services/PageService";
// import Switch from "@material-ui/core/Switch";
import { observable, decorate } from "mobx";
import commonStore from "./CommonStore";

class PageManagementStore {
  valueTransformer = {
    // isActive: (data) => {
    //   return (
    //     <div onClick={(e) => this.setIsActiveSwitch(data.id)}>
    //       <Switch
    //         checked={data.isActive}
    //         name="isFree"
    //         value={data.isActive}
    //         inputProps={{ "aria-label": "secondary checkbox" }}
    //       />
    //     </div>
    //   );
    // },
    isActive: (data) => {
      if (data.isActive === true) {
        return <span className="active-span">ACTIVE</span>;
      } else if (data.isActive === false) {
        return <span className="nonactive-span">INACTIVE</span>;
      }
    },
  };

  editedData = {};
  dataArray = [];
  searchQuery = "";
  isActive = "all";
  type = "old";
  tempType = "old";
  device = "android";
  loading = false;
  pageSize = 10;
  currentPage = 0;
  totalCount = 0;
  columns = [
    { name: "id", title: "Page Management ID" },
    { name: "name", title: "Name" },
    {
      name: "isActive",
      title: "Is Active",
      valueTransformer: this.valueTransformer["isActive"],
    },
    { name: "deviceType", title: "Device Type" },
    { name: "drag", title: "Drag" },
  ];
  columnWidths = [
    { columnName: "id", width: 250 },
    { columnName: "name", width: 250 },
    { columnName: "isActive", width: 250 },
    { columnName: "deviceType", width: 250 },
    { columnName: "drag", width: 250 },
  ];
  isActiveEnum = [
    { value: "all", name: "All" },
    { value: "true", name: "Active" },
    { value: "false", name: "Inactive" },
  ];
  typeEnum = [
    { value: "old", name: "Old" },
    { value: "new", name: "New" },
  ];
  deviceTypeEnum = [
    { value: "android", name: "Android" },
    { value: "ios", name: "iOS" },
  ];
  isActiveArray = [
    { value: "true", name: "Active" },
    { value: "false", name: "Inactive" },
  ];

  setEditedData = (updatedData) => {
    this.editedData = {
      ...this.editedData,
      ...updatedData,
    };
  };

  setSearchQuery = (searchValue) => {
    this.currentPage = 0;
    this.searchQuery = searchValue;
  };

  setCurrentPage = (currentPage) => {
    this.currentPage = currentPage;

    if (this.type === "old") {
      this.getPageManagement();
    } else {
      this.getPageManagementNew();
    }
  };

  setIsActive = (value) => {
    this.isActive = value;
  };

  setType = (value) => {
    this.type = value;

    if (this.type === "old") {
      this.getPageManagement();
    } else {
      this.getPageManagementNew();
    }
  };

  setDevice = (value) => {
    this.device = value;
    this.getPageManagementNew();
  };

  setTempType = (value) => {
    this.tempType = value;
  };

  setIsActiveSwitch = async (pageManagementId) => {
    let { error } = await UpdatePageManagementIsActive(pageManagementId);

    if (!error) {
      this.getPageManagement();
    }
  };

  arrangeArrayOrder = async (newOrder) => {
    this.dataArray = newOrder;

    let arr = this.dataArray;

    for (let i = 0; i < arr.length; i += 1) {
      arr[i].orderNumber = i + 1;
    }

    this.loading = true;

    if (this.type === "old") {
      await this.updatePageManagementOrder(arr);
    } else if (this.type === "new") {
      await this.updatePageManagementNewOrder(arr);
    }

    this.loading = false;
  };

  clearEditedData = () => {
    this.editedData = {};
  };

  getPages = async () => {
    this.loading = true;

    let { data, error } = await GetPages("", "true");

    if (data) {
      this.loading = false;
      this.dataArray = data.page;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getPageManagement = async () => {
    this.loading = true;

    let { data, error } = await GetPageManagements(
      this.searchQuery,
      this.isActive === "all" ? "" : this.isActive,
      this.currentPage,
      this.pageSize
    );

    if (data) {
      this.loading = false;
      this.totalCount = data.count;
      this.dataArray = data.pageManagements;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getPageManagementById = async (pageManagementId) => {
    this.loading = true;

    let { data, error } = await GetPageManagementById(pageManagementId);

    if (data) {
      this.loading = false;
      this.editedData = data;
      this.editedData.isActive = data.isActive === true ? "true" : "false";
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  updatePageManagement = async (history) => {
    this.loading = true;

    let { data, error } = await UpdatePageManagement(this.editedData);

    if (data) {
      this.loading = false;
      history.push("/page-management");
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  updatePageManagementOrder = async (array) => {
    for (let i = 0; i < array.length; i += 1) {
      let { data, error } = await UpdatePageManagement(array[i]);

      if (!data) {
        if (error.data) {
          const errorMessage = error.data.message;
          commonStore.showErrorMessage(errorMessage);
        } else {
          commonStore.showErrorMessage("something went wrong");
        }
        break;
      }
    }
  };

  getPageManagementNew = async () => {
    this.loading = true;

    let { data, error } = await GetPageManagementNews(
      this.searchQuery,
      this.isActive === "all" ? "" : this.isActive,
      this.device,
      this.currentPage,
      this.pageSize
    );

    if (data) {
      this.loading = false;
      this.totalCount = data.count;
      this.dataArray = data.pageManagements;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  getPageManagementNewById = async (pageManagementId) => {
    this.loading = true;

    let { data, error } = await GetPageManagementNewsById(pageManagementId);

    if (data) {
      this.loading = false;
      this.editedData = data;
      this.editedData.isActive = data.isActive === true ? "true" : "false";
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  updatePageManagementNew = async (history) => {
    this.loading = true;

    let { data, error } = await UpdatePageManagementNews(this.editedData);

    if (data) {
      this.loading = false;
      history.push("/page-management");
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };

  updatePageManagementNewOrder = async (array) => {
    for (let i = 0; i < array.length; i += 1) {
      let { data, error } = await UpdatePageManagementNews(array[i]);

      if (!data) {
        if (error.data) {
          const errorMessage = error.data.message;
          commonStore.showErrorMessage(errorMessage);
        } else {
          commonStore.showErrorMessage("something went wrong");
        }
        break;
      }
    }
  };

  updatePageManagementIsActive = async (pageManagementId) => {
    this.loading = true;

    let { data, error } = await UpdatePageManagementIsActive(pageManagementId);

    if (data) {
      this.loading = false;
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("something went wrong");
      }
    }
  };
}

decorate(PageManagementStore, {
  editedData: observable,
  dataArray: observable,
  type: observable,
  tempType: observable,
  isActive: observable,
  searchQuery: observable,
  currentPage: observable,
  totalCount: observable,
  loading: observable,
});

export default new PageManagementStore();
