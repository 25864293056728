import { GetAllSteps } from "../services/StepService";
import { observable, decorate } from "mobx";
import moment from "moment";
import commonStore from "./CommonStore";

class StepStore {
    dataArray = [];
    searchQuery = "";
    startDate = "";
    endDate = "";
    loading = false;
    pageSize = 10;
    currentPage = 0;
    totalCount = 0;
    columns = [
        { name: "id", title: "Record ID" },
        { name: "date", title: "Date" },
        { name: "step", title: "Steps" },
    ];
    columnWidths = [
        { columnName: "id", width: 250 },
        { columnName: "date", width: 250 },
        { columnName: "step", width: 250 },
    ];

    setStartDate = value => {
        this.currentPage = 0;
        this.startDate = value;
    };

    setEndDate = value => {
        this.currentPage = 0;
        this.endDate = value;
    };

    setTypeFilterValue = value => {
        this.currentPage = 0;
        this.typeFilterValue = value;
    };

    setSearchQuery = searchValue => {
        this.currentPage = 0;
        this.searchQuery = searchValue;
    };

    setCurrentPage = currentPage => {
        this.currentPage = currentPage;
        this.getAllStep();
    };

    getAllStep = async userId => {
        this.loading = true;

        let { data, error } = await GetAllSteps(
            userId,
            this.startDate ? moment(this.startDate).format("YYYY-MM-DDThh:mm") : "",
            this.endDate ? moment(this.endDate).format("YYYY-MM-DDThh:mm") : "",
            this.searchQuery,
            this.currentPage,
            this.pageSize,
        );

        if (data) {
            this.loading = false;
            this.totalCount = data.count;
            this.dataArray = data.steps;
        } else {
            this.loading = false;
            if (error.data) {
                const errorMessage = error.data.message;
                commonStore.showErrorMessage(errorMessage);
            } else {
                commonStore.showErrorMessage("something went wrong");
            }
        }
    };
}

decorate(StepStore, {
    dataArray: observable,
    searchQuery: observable,
    startDate: observable,
    endDate: observable,
    currentPage: observable,
    totalCount: observable,
    loading: observable
});

export default new StepStore();
