import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function GetAllHabitRecord(id, type, isAchieved, startDate, endDate, search, offset, limit) {
    try {
        let getAllHabitRecord = await axios.Get({
            url: `${adminUrl}/habit-records?userId=${id}&type=${type || ''}&isAchieved=${isAchieved || ''}&startDate=${startDate ||
                ''}&endDate=${endDate || ''}&search=${search ||
                ''}&offset=${offset}&limit=${limit}`
        });

        if (getAllHabitRecord.statusCode !== 200) {
            return { error: getAllHabitRecord.message };
        }

        return { data: getAllHabitRecord.data };
    } catch (e) {
        if (e.response) {
            return { error: e.response };
        } else {
            return { error: e };
        }
    }
}
