import { GetSubscribe, GetUnsubscribe } from "../services/OneSignalService";
import { decorate, action } from "mobx";

class OneSignalStore {
  getSubscribe = async history => {
    this.authLoading = true;

    var OneSignal = window.OneSignal || [];

    let deviceId;
    await OneSignal.getUserId(id => {
      deviceId = id;
    });

    const { data, error } = await GetSubscribe(deviceId, this.getDeviceType());

    if (data) {
      history.push("/patients");
      this.authLoading = false;
    } else if (error) {
      this.authLoading = false;
    }
  };

  getUnSubscribe = async () => {
    this.authLoading = true;

    var OneSignal = window.OneSignal || [];

    let deviceId;
    await OneSignal.getUserId(id => {
      deviceId = id;
    });

    const { data, error } = await GetUnsubscribe(
      deviceId,
      this.getDeviceType()
    );

    if (data) {
      this.authLoading = false;
    } else if (error) {
      this.authLoading = false;
    }
  };

  getDeviceType = () => {
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = "" + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;
    var deviceType = 0;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) !== -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
      deviceType = 6;
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
      deviceType = 5;
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) !== -1)
        fullVersion = nAgt.substring(verOffset + 8);
      deviceType = 7;
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
      deviceType = 8;
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
      (nameOffset = nAgt.lastIndexOf(" ") + 1) <
      (verOffset = nAgt.lastIndexOf("/"))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) !== -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) !== -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt("" + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = "" + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    return deviceType;

    // document.write(''
    //  +'Browser name  = '+browserName+'<br>'
    //  +'Full version  = '+fullVersion+'<br>'
    //  +'Major version = '+majorVersion+'<br>'
    //  +'navigator.appName = '+navigator.appName+'<br>'
    //  +'navigator.userAgent = '+navigator.userAgent+'<br>'
    // )
  };
}

decorate(OneSignalStore, {
  getSubscribe: action
});
export default new OneSignalStore();
