import tempAxios from 'axios';
import axios from "../helper/Axios.js";
import { getCookie } from "../helper/cookie";
import { adminSiloamUrl, adminUrl } from "../config/config.js";

export async function GetAllSiloamHospital() {
    try {
        let getAllHospital = await axios.Get({
            url: `${adminSiloamUrl}/hospitals`
        });

        if (getAllHospital.status !== "OK") {
            return { error: getAllHospital.message };
        }

        return { data: getAllHospital.data };
    } catch (e) {
        if (e.response) {
            return { error: e.response };
        } else {
            return { error: e };
        }
    }
}

export async function GetAllSiloamDoctor(hospitalId, name) {
    try {
        if (name === " ")
            name = null;
        else if (name === "  ")
            name = null;

        let getAllSiloamDoctor = await axios.Get({
            url: `${adminSiloamUrl}/doctors/info/${hospitalId}${name ? name = `?name=${name}` : ""}`
        });

        if (getAllSiloamDoctor.status !== "OK") {
            return { error: getAllSiloamDoctor.message };
        }

        return { data: getAllSiloamDoctor.data };
    } catch (e) {
        if (e.response) {
            return { error: e.response };
        } else {
            return { error: e };
        }
    }
}

export async function GetDoctorById(doctorId) {
    try {
        let doctor = await axios.Get({
            url: `${adminSiloamUrl}/mobile/doctors?doctorId=${doctorId}`
        });

        if (doctor.status !== "OK") {
            return { error: doctor.message };
        }

        return { doctor: doctor.data };
    } catch (e) {
        if (e.response) {
            return { errorDoctor: e.response };
        } else {
            return { errorDoctor: e };
        }
    }
}

export async function GetDoctorInfoById(doctorId, isValidate) {
    try {
        let doctorInfo = await axios.Get({
            url: `${adminUrl}/doctor-info/internal/${doctorId}?isValidate=${isValidate ? isValidate : ""}`
        });

        if (doctorInfo.statusCode !== 200) {
            return { error: doctorInfo.message };
        }

        return { data: doctorInfo.data };
    } catch (e) {
        if (e.response) {
            return { error: e.response };
        } else {
            return { error: e };
        }
    }
}

export async function GetDoctorScheduleById(doctorId, hospitalId) {
    try {
        let schedule = await axios.Get({
            url: `${adminSiloamUrl}/schedules/hospital-id/${hospitalId}/doctor-id/${doctorId}`
        });

        if (schedule.status !== "OK") {
            return { error: schedule.message };
        }

        return { data: schedule.data };
    } catch (e) {
        if (e.response) {
            return { error: e.response };
        } else {
            return { error: e };
        }
    }
}

export async function UpdateDoctorById(doctorId, data, idInfo) {
    try {
        let isValidate;
        if (data.isValidate === "true") {
            isValidate = true;
        }
        else {
            isValidate = false;
        }
        let doctor;
        if (idInfo === null) {
            // doctor = await axios.Put({
            //     url: `${adminSiloamUrl}/doctors/info/${doctorId}`,
            //     data: {
            //         condition: data.condition, condition_en: data.condition_en, achievement: data.achievement, education: data.education,
            //         isValidate: isValidate, base64: data.imageUrl, isImageUpdate: data.isImageUpdate, hospitalAlias: data.hospitalAlias
            //     }
            // });

            doctor = await tempAxios({
              method: 'put',
              url: `${adminSiloamUrl}/doctors/info/${doctorId}`,
              data: {
                condition: data.condition, condition_en: data.condition_en, achievement: data.achievement, education: data.education,
                isValidate: isValidate, base64: data.imageUrl, isImageUpdate: data.isImageUpdate, hospitalAlias: data.hospitalAlias
              },
              headers: {
                "Content-Type": "application/json",
                token: getCookie("siloam_current_token"),
              }
            });
        } else {
            // doctor = await axios.Put({
            //     url: `${adminSiloamUrl}/doctors/info/${doctorId}`,
            //     data: {
            //         condition: data.condition, condition_en: data.condition_en, achievement: data.achievement, education: data.education,
            //         isValidate: isValidate, base64: data.imageUrl, isImageUpdate: data.isImageUpdate, hospitalAlias: data.hospitalAlias, id: idInfo
            //     }
            // });

            doctor = await tempAxios({
              method: 'put',
              url: `${adminSiloamUrl}/doctors/info/${doctorId}`,
              data: {
                condition: data.condition, condition_en: data.condition_en, achievement: data.achievement, education: data.education,
                isValidate: isValidate, base64: data.imageUrl, isImageUpdate: data.isImageUpdate, hospitalAlias: data.hospitalAlias, id: idInfo
              },
              headers: {
                "Content-Type": "application/json",
                token: getCookie("siloam_current_token"),
              },
            });
        }


        // if (doctor.status !== "OK") {
        //     return { error: doctor.message };
        // }

        if (doctor.data.status !== "OK") {
          return { error: doctor.message };
        }

        return { data: doctor.data.data };
    } catch (e) {
        if (e.response) {
            return { error: e.response };
        } else {
            return { error: e };
        }
    }
}