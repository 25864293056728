import {
  GetAllDietRecord,
  GetDietRecordById,
  PutUpdateDietRecord,
  PutDeleteDietRecord
} from "../services/DietRecordService";
import { observable, decorate, action } from "mobx";
import moment from "moment";
import React from "react";
import commonStore from "./CommonStore";
import swal from "sweetalert2";

class DietRecordStore {
  editedData = {};
  dataArray = [];
  dietTarget = 0;
  categoryValue = "";
  pageSize = 10;
  currentPage = 0;
  totalCount = 0;
  startDate = "";
  endDate = "";
  loading = false;
  valueTransformer = {
    category: data => {
      if (data.category === "breakfast") {
        return <span>Breakfast</span>;
      } else if (data.category === "lunch") {
        return <span>Lunch</span>;
      } else if (data.category === "dinner") {
        return <span>Dinner</span>;
      } else {
        return <span>Snack</span>;
      }
    }
  };
  categoryEnum = [
    { name: "Lunch", value: "lunch" },
    { name: "Dinner", value: "dinner" },
    { name: "Breakfast", value: "breakfast" },
    { name: "Snack", value: "snack" }
  ];
  columns = [
    { name: "dietRecordID", title: "Record ID" },
    { name: "forDay", title: "Date & Time" },
    { name: "mealName", title: "Meal Name" },
    { name: "totalCalories", title: "Calories (Cal)" },
    {
      name: "category",
      title: "Category",
      valueTransformer: this.valueTransformer["category"]
    }
  ];
  columnWidths = [
    { columnName: "dietRecordID", width: 200 },
    { columnName: "forDay", width: 200 },
    { columnName: "mealName", width: 200 },
    { columnName: "totalCalories", width: 200 },
    { columnName: "category", width: 200 }
  ];
  disabledColumn = [
    { columnName: "forDay", sortingEnabled: false },
    { columnName: "mealName", sortingEnabled: false },
    { columnName: "totalCalories", sortingEnabled: false },
    { columnName: "category", sortingEnabled: false }
  ];
  sorting = [{ columnName: "dietRecordID", direction: "asc" }];

  setCategoryValue = value => {
    this.categoryValue = value;
  };

  setStartDate = value => {
    this.currentPage = 0;
    this.startDate = value;
  };

  setEndDate = value => {
    this.currentPage = 0;
    this.endDate = value;
  };

  setSorting = updatedSorting => {
    // if (this.order === "DESC") {
    //   this.order = "ASC";
    // } else {
    //   this.order = "DESC";
    // }
    this.sorting = updatedSorting;
  };

  setSearchQuery = searchValue => {
    this.currentPage = 0;
    this.searchQuery = searchValue;
  };

  setCurrentPage = currentPage => {
    this.currentPage = currentPage;
    this.getAllDietRecord();
  };

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  getAllDietRecord = async id => {
    this.authLoading = true;
    this.loading = true;
    let { data, error } = await GetAllDietRecord(
      this.editedData.userId,
      this.categoryValue,
      this.startDate ? moment(this.startDate).format("YYYY-MM-DDThh:mm") : "",
      this.endDate ? moment(this.endDate).format("YYYY-MM-DDThh:mm") : "",
      this.searchQuery,
      this.currentPage,
      this.pageSize,
      this.sorting[0].direction
    );

    if (data) {
      this.loading = false;
      this.totalCount = data.count;
      this.dataArray = data.data.dietRecord.map(data => {
        const dietRecordID = data.dietRecordID ? Number(data.dietRecordID) : "";
        const forDay = moment(data.forDay ? data.forDay : "").format(
          "DD MMM YYYY hh:mm:ss"
        );
        const mealId = data.meal.mealID ? data.meal.mealID : "";
        const mealName = data.meal.name ? data.meal.name : data.mealName;
        const servingSize = data.servingSize ? data.servingSize : "";
        const totalCalories = data.totalCalories ? data.totalCalories : "";
        const category = data.category ? data.category : "";

        return {
          ...data,
          dietRecordID,
          forDay,
          mealId,
          mealName,
          servingSize,
          totalCalories,
          category
        };
      });
      this.dietTarget = data.data.target.dietTarget
        ? data.data.target.dietTarget
        : "";
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };

  getDietRecordById = async id => {
    this.authLoading = true;
    this.loading = true;
    let { data, error } = await GetDietRecordById(id);

    if (data) {
      this.setEditedData({
        dietID: data.dietRecordID,
        forDay: moment(data.forDay).format("DD MMM YYYY hh:mm:ss"),
        mealId: data.meal.mealID,
        servingSize: data.servingSize,
        category: data.category,
        totalCalories: data.totalCalories
      });
      this.loading = false;
      return { data };
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };

  putUpdateDietRecord = async (
    dietRecordID,
    totalCalories,
    mealMealID,
    servingSize,
    forDay,
    category,
    userId,
    id
  ) => {
    this.loading = true;
    let { data, error } = await PutUpdateDietRecord(
      dietRecordID,
      totalCalories,
      mealMealID,
      servingSize,
      forDay,
      category,
      userId,
      id
    );
    if (data) {
      this.loading = false;
      swal({
        title: "Success",
        text: "Diet Success Updated",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = `/patients/${userId}/healthtracker`;
        return { data };
      });
    } else {
      this.loading = false;
      if (error.data) {
        const errorMessage = error.data.message;
        commonStore.showErrorMessage(errorMessage);
      } else {
        commonStore.showErrorMessage("Something Went Wrong");
      }
    }
  };

  putDeleteDietRecord = async (id, userId) => {
    let { data, error } = await PutDeleteDietRecord(id);
    if (data) {
      swal({
        title: "Success",
        text: "Diet Record Success Deleted",
        icon: "success",
        button: "OK"
      }).then(value => {
        window.location.href = `/patients/${userId}/healthtracker`;
        return { data };
      });
    } else {
      return { error };
    }
  };
}
decorate(DietRecordStore, {
  editedData: observable,
  dataArray: observable,
  dietTarget: observable,
  categoryValue: observable,
  startDate: observable,
  endDate: observable,
  searchQuery: observable,
  loading: observable,

  setCategoryValue: action,
  getAllDietRecord: action,
  getDietRecordById: action
});

export default new DietRecordStore();
